import styled from "styled-components";

const UnreadIndicator = styled.hr`
  border-bottom: 1px solid ${(props) => props.theme.colors.error};
  color: ${(props) => props.theme.colors.error};
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 50%;
  right: 0;
  z-index: 10;
  margin: 0;
  margin-top: ${(props) => props.theme.spaces.base};
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.spaces.xs};

  &::before {
    content: "New Message";
    line-height: 1.5;
  }
`;
UnreadIndicator.displayName = "UnreadIndicator";

export default UnreadIndicator;
