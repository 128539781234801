import { useEffect, useState } from "react";
import { generateVideoThumbnailViaUrl } from "@rajesh896/video-thumbnails-generator";
import PropTypes from "prop-types";
import { matrixRoomPropType } from "propTypes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Box,
  Icon,
  Loader,
  LoaderContainer,
  Text,
} from "../../../../components";
import {
  localStorageValues,
  messageTypes,
  routes,
} from "../../../../constants";
import { useMatrix } from "../../../../network/MatrixContext";
import { checkChatRunningEnvironment } from "../../../../utils/displayUtils";
import { getFileDownloadURL } from "../../../../utils/matrixUtils";
import { VideoGeneric } from "../../timeline/event/Messages";
import useAttachments from "../../timeline/useAttachments";
import useFetchAttachments from "../../timeline/useFetchAttachments";
import useParentPropagation from "./common/useParentPropagation";

const EDGE = 50;
const thumbnailSizes = {
  BASE: `${EDGE}px`,
  VIDEO_HEIGHT: `${EDGE * 0.6}px`,
};

const propTypes = {
  title: PropTypes.string.isRequired,
  room: matrixRoomPropType,
};

const truncateFileName = (fileName, maxLength = 25) => {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const extension = fileName.slice(fileName.lastIndexOf("."));
  const slicedString = fileName.slice(0, maxLength - extension.length);

  return `${slicedString}(...)${extension}`;
};

const ImageThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  background: ${(props) => props.theme.colors.textTertiary};
  height: ${thumbnailSizes.VIDEO_HEIGHT};
  width: ${thumbnailSizes.BASE};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  /* letterboxing */
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: ${(props) => props.theme.spaces.xxs};
    left: 0;
    background: ${(props) => props.theme.colors.text};
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
`;

const VideoThumbnail = ({ src }) => {
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const isIos = checkChatRunningEnvironment(localStorageValues.IS_IOS);
  useEffect(() => {
    if (!isIos && !thumbnailImage) {
      generateVideoThumbnailViaUrl(`${src}#t=0.314`, 1).then((videoThumbnail) =>
        setThumbnailImage(videoThumbnail)
      );
    }
  }, [src, isIos, thumbnailImage]);

  return (
    <Box
      position="relative"
      d="flex"
      w="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Overlay>
        <Icon name="arrowRightCircle" size="m" color="textInverted" />
      </Overlay>
      {isIos ? (
        <video width="100%" playsInline height={thumbnailSizes.VIDEO_HEIGHT}>
          <source src={`${src}#t=0.314`} type="video/mp4"></source>
        </video>
      ) : (
        <img
          src={thumbnailImage}
          alt="Video Loading..."
          height={thumbnailSizes.VIDEO_HEIGHT}
        />
      )}
    </Box>
  );
};

VideoThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
};

const AttachmentWrapper = ({ text, children, ...rest }) => {
  return (
    <Box d="flex" alignItems="center" gap="l" pV="s" {...rest}>
      <Box
        w={thumbnailSizes.BASE}
        h={thumbnailSizes.BASE}
        d="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
      <Text>{text}</Text>
    </Box>
  );
};

AttachmentWrapper.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};

const Attachments = ({ title, room }) => {
  const { client } = useMatrix();
  const { attachmentIds } = useAttachments({ room });
  const { attachments, status, error } = useFetchAttachments({
    room,
    attachmentIds,
  });
  useParentPropagation({ title: `${title} ${attachmentIds.length}` });
  const navigate = useNavigate();

  return status === "loading" ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : status === "error" ? (
    <Text color="error">{error.message}</Text>
  ) : (
    <Box p="m">
      {attachments?.map((message) => {
        const getViewUrl = () =>
          `${routes.MESSAGE}/${message.room_id}/${message.content.url.slice(
            6
          )}/isDrawer`;
        const fileDownloadURL = getFileDownloadURL(
          message.content.url,
          client.baseUrl
        );
        switch (message.content.msgtype) {
          case messageTypes.FILE:
            return (
              <AttachmentWrapper
                key={message.content.url}
                text={truncateFileName(message.content.info)}
                onClick={() => {
                  window.parent.postMessage(
                    {
                      action: "shareFile",
                      payload: fileDownloadURL,
                    },
                    "*"
                  );
                }}
                /* The native share functionality in iOS/Android (navigator.share) cannot
                be triggered from Cordova apps.
                The container app should have a plugin that enables sharing.
                Applies to all filetypes below.
                Currently, files open in the default browser and
                the images open in a separate view from where they can be
                downloaded via long tap > save/download image/video */
              >
                <Icon name="File" size="m" w="50px" />
              </AttachmentWrapper>
            );
          case messageTypes.IMAGE:
            return (
              <AttachmentWrapper
                key={message.content.url}
                text={truncateFileName(message.content.info)}
                onClick={() => {
                  navigate(`${getViewUrl()}/image`, {
                    state: {
                      imageInfo: message.content.info,
                    },
                  });
                }}
              >
                <ImageThumbnail
                  src={fileDownloadURL}
                  alt={message.content.info}
                />
              </AttachmentWrapper>
            );

          case messageTypes.VIDEO:
            return (
              <AttachmentWrapper
                key={message.content.url}
                text={truncateFileName(message.content.info)}
                onClick={() => navigate(`${getViewUrl()}/video`)}
              >
                {/* After figuring how to display a thumbnail for videos on iOS without auto-playing on events,
              the <VideoThumbnail> component can be used instead of the <VideoGeneric> */}
                {/* <VideoThumbnail src={fileDownloadURL} /> */}
                <VideoGeneric isDrawer />
              </AttachmentWrapper>
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};

Attachments.propTypes = propTypes;

export default Attachments;
