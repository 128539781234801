import identity from "lodash/identity";
import { useMutation } from "react-query";

import { useMatrix } from "./MatrixContext";

const useInviteMembers = ({ room, onSuccess = identity }) => {
  const { client } = useMatrix();
  const { mutate, mutateAsync, ...inviteMutation } = useMutation(
    ({ userIds }) => {
      return Promise.all(
        userIds.map((userId) => client.invite(room.roomId, userId))
      );
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
    }
  );

  return {
    inviteMembers: mutate,
    inviteMembersAsync: mutateAsync,
    ...inviteMutation,
  };
};

export default useInviteMembers;
