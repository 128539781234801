import { useMemo } from "react";

import { memberRoles, permissionLevels, permissionTypes } from "../constants";
import { useMatrix } from "./MatrixContext";

const isPowerful = (member) =>
  [memberRoles.ADMIN, memberRoles.OWNER].includes(member?.peopleRole);

const useRoomPermissions = ({ room }) => {
  const { client } = useMatrix();
  const clientID = client.getUserId();
  const me = room?.getMember(clientID);
  const myPower = me?.powerLevel;
  const joinedMembers = room.getMembersWithMembership("join");
  const { currentState } = room;
  const adminsBesidesMe = joinedMembers.filter(
    (member) => member.powerLevel >= 100 && member.userId !== clientID
  );
  // TODO - currentState.members holds all members of the room, including those who left
  // to get only the members who are still in the room, we use room.getMembersWithMembership("join")
  // as we do in the useMembers.js hook

  // const adminsBesidesMe = currentState
  //   .getMembersExcept(clientID)
  //   .filter((member) => member.powerLevel >= 100);

  const imAdmin = isPowerful(me);
  const adminsBesidesMeStringified = JSON.stringify(adminsBesidesMe);
  const {
    EVENTS,
    REDACT,
    INVITE,
    DELETE_ROOM,
    KICK,
    eventTypes: {
      "M.ROOM.NAME": renameRoomPowerLevel,
      "M.ROOM.TOPIC": changeTopicPowerLevel,
    },
  } = permissionTypes;

  const canSendRoomTopicEvent = currentState.maySendStateEvent(
    "m.room.topic",
    clientID
  );
  const canSendRoomNameEvent = currentState.maySendStateEvent(
    "m.room.name",
    clientID
  );

  const iCan = useMemo(
    () => ({
      invite: room?.canInvite(clientID) && myPower >= permissionLevels[INVITE],
      kick: myPower >= permissionLevels[KICK],
      redact: myPower >= permissionLevels[REDACT],
      changeTopic:
        canSendRoomTopicEvent &&
        myPower >= permissionLevels[EVENTS][changeTopicPowerLevel],
      renameRoom:
        canSendRoomNameEvent &&
        myPower >= permissionLevels[EVENTS][renameRoomPowerLevel],
      leaveRoom:
        (myPower >= 100 &&
          JSON.parse(adminsBesidesMeStringified)?.length > 0) ||
        myPower < 100,
      deleteRoom: myPower >= permissionLevels[DELETE_ROOM],
      changeRoles: myPower >= 50,
    }),
    // eslint-disable-next-line
    [
      room.canInvite,
      clientID,
      myPower,
      canSendRoomTopicEvent,
      canSendRoomNameEvent,
      adminsBesidesMeStringified,
      renameRoomPowerLevel,
      changeTopicPowerLevel,
      EVENTS,
      INVITE,
      KICK,
      REDACT,
      DELETE_ROOM,
    ]
  );

  const iAm = useMemo(
    () => ({ admin: imAdmin, thisPowerful: myPower }),
    [imAdmin, myPower]
  );

  const memberCan = useMemo(
    () => ({
      beRemoved: (member) =>
        member.userId !== clientID &&
        myPower >= 50 &&
        member.powerLevel < myPower,
    }),
    [clientID, myPower]
  );

  return { iCan, iAm, memberCan };
};

export default useRoomPermissions;
