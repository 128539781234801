import { useEffect, useState } from "react";
import endsWith from "lodash/endsWith";

import { localStorageValues } from "../../constants";
import { getOrgRoomNameEnding } from "../../utils/chat-tools";
import { getUserInfoFromDisplayName } from "../../utils/displayUtils";

const useUserInfo = ({ client, roomStore }) => {
  const [userInfo, setUserInfo] = useState({
    ...getUserInfoFromDisplayName(),
    orgRoomId: "",
  });

  useEffect(() => {
    if (!client || !roomStore) {
      return;
    }
    console.log("****************  useUserInfo useEffect **************");
    const { userId } = client.credentials;

    (async () => {
      console.log("start async --------");
      /** Because we don't currently have a way of storing user meta, we use
       * matrix user displayname to store all extra data.
       * The app integrating with chat might allow user to update their info.
       * Check if matrix user's displayName is the same as the one we have in
       * local storage for user info. If they differ log out the user and have
       * them log back in.
       */
      const profileInfo = await client.getProfileInfo(userId, "displayname");
      const localUserInfo = localStorage.getItem(localStorageValues.USER_INFO);
      if (localUserInfo && profileInfo.displayname !== localUserInfo) {
        console.log("logging user out - different info from displayname");
        client.stopClient();
        client.clearStores();
        localStorage.clear();
        window.location.reload();
      }

      // TODO: if there is no localUserInfo. Then we could get all the userInfo
      // from the profileInfo.displayname, assuming that the user was created
      // with a displayname that respects the defaultDisplayNameStructure.

      // set organisation room id on the context if we don't have one yet and if
      // there is an orgId
      if (!userInfo.orgRoomId && userInfo.orgId) {
        console.log("--- need to set orgRoomID ");
        const rooms = await client.publicRooms();
        const orgRoom = rooms?.chunk?.find((room) =>
          endsWith(room.name, getOrgRoomNameEnding(userInfo.orgId))
        );
        const orgRoomId = orgRoom?.room_id;
        // ok, we have the organisation room
        if (orgRoomId) {
          console.log("--- have orgID ", orgRoomId);
          // check if user is needs to be added to this room
          const userJoinedRooms = await client.getJoinedRooms();
          const inOrgRoom = userJoinedRooms.joined_rooms.some(
            (roomId) => roomId === orgRoom.room_id
          );
          if (!inOrgRoom) {
            console.log("--- add user to room", orgRoom.room_id);
            await client.joinRoom(orgRoomId);
            roomStore.addRoom({ roomId: orgRoomId.current });
          }

          setUserInfo((s) => ({ ...s, orgRoomId: orgRoomId }));
        } else {
          console.error(`org room for ${userInfo.orgId} doesn't exist`);
        }
      }
      console.log("--------- end async --------");
    })();
  }, [client, userInfo.orgRoomId, roomStore, userInfo.orgId]);

  return {
    userInfo,
    setUserInfo,
  };
};

export default useUserInfo;
