import PropTypes from "prop-types";

import useParentPropagation from "./common/useParentPropagation";

const propTypes = {
  title: PropTypes.string.isRequired,
};

const Appearance = ({ title }) => {
  useParentPropagation({ title });

  return <div>Appearance</div>;
};
Appearance.propTypes = propTypes;

export default Appearance;
