import PropTypes from "prop-types";

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const clientPropTypes = PropTypes.shape({});

// Using a custom structure that we construct in "useMembers"
export const roomMemberPropType = PropTypes.shape({
  name: PropTypes.string,
  rawDisplayName: PropTypes.string,
  userId: PropTypes.string,
  powerLevel: PropTypes.number,
});

export const eventPropType = PropTypes.shape({
  type: PropTypes.oneOf([
    "m.room.create",
    "m.room.topic",
    "m.room.message",
    "m.room.encrypted",
    "m.room.member",
    "m.sticker",
    "m.room.power_levels",
  ]),
  room_id: PropTypes.string,
  content: PropTypes.shape({
    body: PropTypes.any,
    msgtype: PropTypes.oneOf([
      "m.text",
      "m.image",
      "m.video",
      "m.audio",
      "m.file",
    ]),
  }),
  // The userId of the sender
  sender: PropTypes.string,
  // The userId of the sender
  user_id: PropTypes.string,
  // The unix timestamp in milliseconds
  origin_server_ts: PropTypes.number,
  event_id: PropTypes.string,
  // The ms passed since the event was created
  unsigned: PropTypes.shape({
    age: PropTypes.number,
  }),
  // The ms passed since the event was created
  age: PropTypes.number,
});

export const matrixEventPropType = PropTypes.shape({
  sender: roomMemberPropType,
  event: eventPropType,
  // There are other keys as well but ignoring for now as we aren't using them.
});

export const accountPropType = PropTypes.shape({
  avatar_url: PropTypes.string,
  display_name: PropTypes.string,
  user_id: PropTypes.string,
});

// A Matrix User instance. Can be returned by `client.getUser(userId)`.
export const matrixUserPropType = PropTypes.shape({
  userId: PropTypes.string,
  // ms timestamp
  modified: PropTypes.number,
  displayName: PropTypes.string,
  rawDisplayName: PropTypes.string,
  avatarUrl: PropTypes.string,
  presenceStatusMsg: PropTypes.string,
  presence: PropTypes.oneOf(["online", "offline"]),
  // seconds ago when it was last active
  lastActiveAgo: PropTypes.number,
  // ms timestamp
  lastPresenceTs: PropTypes.number,
  currentlyActive: PropTypes.bool,
  events: eventPropType,
});

export const matrixRoomPropType = PropTypes.shape({
  roomId: PropTypes.string,
  normalizedName: PropTypes.string,
  name: PropTypes.string,
  client: PropTypes.object,
  myUserId: PropTypes.string,
  receipts: PropTypes.any,
  notificationCounts: PropTypes.object,
  timelineNeedsRefresh: PropTypes.bool,
  summary: PropTypes.object,
  timeline: PropTypes.array,
  oldState: PropTypes.object,
  currentState: PropTypes.object,
  threads: PropTypes.any,
  lastThread: PropTypes.any,
  applyRedaction: PropTypes.func,
  getCreator: PropTypes.func,
  getVersion: PropTypes.func,
  getPendingEvents: PropTypes.func,
  removePendingEvent: PropTypes.func,
  hasPendingEvent: PropTypes.func,
  getPendingEvent: PropTypes.func,
  getMyMembership: PropTypes.func,
  getDMInviter: PropTypes.func,
  guessDMUserId: PropTypes.func,
  getAvatarFallbackMember: PropTypes.func,
  updateMyMembership: PropTypes.func,
  loadMembersFromServer: PropTypes.func,
  loadMembers: PropTypes.func,
  loadMembersIfNeeded: PropTypes.func,
  clearLoadedMembersIfNeeded: PropTypes.func,
  cleanupAfterLeaving: PropTypes.func,
  refreshLiveTimeline: PropTypes.func,
  resetLiveTimeline: PropTypes.func,
  fixUpLegacyTimelineFields: PropTypes.func,
  hasUnverifiedDevices: PropTypes.func,
  getTimelineSets: PropTypes.func,
  getUnfilteredTimelineSet: PropTypes.func,
  getTimelineForEvent: PropTypes.func,
  addTimeline: PropTypes.func,
  setTimelineNeedsRefresh: PropTypes.func,
  getTimelineNeedsRefresh: PropTypes.func,
  findEventById: PropTypes.func,
  getUnreadNotificationCount: PropTypes.func,
  setUnreadNotificationCount: PropTypes.func,
  setSummary: PropTypes.func,
  setBlacklistUnverifiedDevices: PropTypes.func,
  getBlacklistUnverifiedDevices: PropTypes.func,
  getAvatarUrl: PropTypes.func,
  getMxcAvatarUrl: PropTypes.func,
  getAliases: PropTypes.func,
  getAltAliases: PropTypes.func,
  addEventsToTimeline: PropTypes.func,
  getThread: PropTypes.func,
  getThreads: PropTypes.func,
  getMember: PropTypes.func,
  getMembers: PropTypes.func,
  getJoinedMembers: PropTypes.func,
  getJoinedMemberCount: PropTypes.func,
  getInvitedMemberCount: PropTypes.func,
  getInvitedAndJoinedMemberCount: PropTypes.func,
  getMembersWithMembership: PropTypes.func,
  getEncryptionTargetMembers: PropTypes.func,
  shouldEncryptForInvitedMembers: PropTypes.func,
  getDefaultRoomName: PropTypes.func,
  hasMembershipState: PropTypes.func,
  getOrCreateFilteredTimelineSet: PropTypes.func,
  getThreadListFilter: PropTypes.func,
  createThreadTimelineSet: PropTypes.func,
  fetchRoomThreads: PropTypes.func,
  onThreadNewReply: PropTypes.func,
  removeFilteredTimelineSet: PropTypes.func,
  eventShouldLiveIn: PropTypes.func,
  findThreadForEvent: PropTypes.func,
  addThreadedEvents: PropTypes.func,
  processThreadedEvents: PropTypes.func,
  createThread: PropTypes.func,
  processLiveEvent: PropTypes.func,
  addLiveEvent: PropTypes.func,
  addPendingEvent: PropTypes.func,
  savePendingEvents: PropTypes.func,
  aggregateNonLiveRelation: PropTypes.func,
  getEventForTxnId: PropTypes.func,
  handleRemoteEcho: PropTypes.func,
  updatePendingEvent: PropTypes.func,
  revertRedactionLocalEcho: PropTypes.func,
  addLiveEvents: PropTypes.func,
  partitionThreadedEvents: PropTypes.func,
  findThreadRoots: PropTypes.func,
  addEphemeralEvents: PropTypes.func,
  removeEvents: PropTypes.func,
  removeEvent: PropTypes.func,
  recalculate: PropTypes.func,
  getUsersReadUpTo: PropTypes.func,
  getReadReceiptForUserId: PropTypes.func,
  getEventReadUpTo: PropTypes.func,
  hasUserReadEvent: PropTypes.func,
  getReceiptsForEvent: PropTypes.func,
  addReceipt: PropTypes.func,
  addReceiptsToStructure: PropTypes.func,
  addLocalEchoReceipt: PropTypes.func,
  addTags: PropTypes.func,
  addAccountData: PropTypes.func,
  getAccountData: PropTypes.func,
  maySendMessage: PropTypes.func,
  canInvite: PropTypes.func,
  getJoinRule: PropTypes.func,
  getHistoryVisibility: PropTypes.func,
  getGuestAccess: PropTypes.func,
  getType: PropTypes.func,
  isSpaceRoom: PropTypes.func,
  isCallRoom: PropTypes.func,
  isElementVideoRoom: PropTypes.func,
  calculateRoomName: PropTypes.func,
});
