import identity from "lodash/identity";
import isUndefined from "lodash/isUndefined";
import { useMutation } from "react-query";

import { getRoomTopic } from "../utils/matrixUtils";
import { useMatrix } from "./MatrixContext";

const useUpdateRoom = ({ onSuccess = identity } = {}) => {
  const { client } = useMatrix();
  const { mutate, mutateAsync, ...updateMutation } = useMutation(
    ({ topic, name, roomId }) => {
      const room = client.getRoom(roomId);

      const updates = [];

      if (!isUndefined(name) && room.name !== name) {
        updates.push(client.setRoomName(roomId, name));
      }
      if (!isUndefined(topic) && getRoomTopic(room) !== topic) {
        updates.push(client.setRoomTopic(roomId, topic));
      }
      return Promise.all(updates);
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
    }
  );

  return {
    updateRoom: mutate,
    updateRoomAsync: mutateAsync,
    ...updateMutation,
  };
};

export default useUpdateRoom;
