import { Box } from "@airportlabs/smalt";
import styled, { css } from "styled-components";

export const ImagePreview = styled(Box)`
  width: 120px;
  height: 120px;
  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
    `}
  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
    `}

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

export const FullScreenImagePreview = styled(Box)`
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  background-color: black;
  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
    `} background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
