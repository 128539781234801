import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { routes } from "../constants";
import { useMatrix } from "../network/MatrixContext";
import { childrenPropType } from "../propTypes";
import CameraPage from "./CameraPage";
import LoginPage from "./LoginPage";
import PageNotFound from "./PageNotFound";
import RoomListPage from "./RoomListPage";
import RoomPage from "./RoomPage";
import ViewMediaPage from "./ViewMediaPage";

// https://www.robinwieruch.de/react-router-private-routes/
const ProtectedRoute = ({
  isAllowed,
  redirectPath = routes.LOGIN,
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
ProtectedRoute.propTypes = {
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string,
  children: childrenPropType,
};

const AppRoutes = () => {
  const { isAuthenticated } = useMatrix();

  useEffect(() => {
    // When chat is integrated in another app we want to prevent push
    // notifications while the user is using the chat.
    // In order to do this we send a recurring message to the parent app every
    // 4.5 minutes, telling it to prevent the notifications, by sending a value
    // for preventUntil that is in the future (now + 5 min so we are covered).
    // The parent app is responsible to prevent push notifications and to also
    // set preventUntil to value in the past when chat route is unmounted
    const preventUntil = new Date(new Date().getTime() + 5 * 60000);
    window.parent.postMessage(
      {
        action: "preventPushNotificationsChat",
        preventUntil: preventUntil,
      },
      "*"
    );
    // then send it every 4.5 min
    const interval = setInterval(() => {
      const preventUntil = new Date(new Date().getTime() + 5 * 60000);
      window.parent.postMessage(
        {
          action: "preventPushNotificationsChat",
          preventUntil: preventUntil,
        },
        "*"
      );
    }, 270000); // 4.5 * 60000

    return () => {
      clearInterval(interval);
      // parent app should handle reenabling the push notifications on parent
      // app route unmount phase. It's NOT effective to send another message from
      // here because parent app will stop listening to events in unmount phase.
    };
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            replace
            to={isAuthenticated ? routes.ROOMS : routes.LOGIN}
          />
        }
      />
      <Route
        path={routes.LOGIN}
        element={isAuthenticated ? <Navigate to="/" replace /> : <LoginPage />}
      />
      {/* Actual routes begin here. */}
      <Route element={<ProtectedRoute isAllowed={isAuthenticated} />}>
        <Route
          path={routes.ROOMS}
          element={
            <RoomListPage
              getNavigationRoute={({ roomId }) => `${routes.ROOMS}/${roomId}`}
            />
          }
        />
        <Route path={`${routes.ROOMS}/:roomId/*`} element={<RoomPage />} />
        <Route
          path={`${routes.MESSAGE}/:roomId/:serverName/:mediaId/:isAttachmentDrawer?/:mediaType/`}
          element={
            <ViewMediaPage
              getNavigationRoute={({
                roomId,
                serverName,
                mediaId,
                mediaType,
                isAttachmentDrawer,
              }) =>
                `${routes.MESSAGE}/${roomId}/${serverName}/${mediaId}/${
                  isAttachmentDrawer || ""
                }/${mediaType}`
              }
            />
          }
        />
        <Route
          path={`${routes.CAMERA}/:roomId`}
          element={
            <CameraPage
              getNavigationRoute={({ roomId }) => `${routes.MESSAGE}/${roomId}`}
            />
          }
        />
      </Route>
      <Route path="/404" element={<PageNotFound />} />
      <Route element={PageNotFound} />
    </Routes>
  );
};

export default AppRoutes;
