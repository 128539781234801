import { joinWithComplexSeparator } from "@airportlabs/js-tools";

import { Box, TimelineText } from "../../../../components";
import { matrixEventPropType } from "../../../../propTypes";

const propTypes = { mEvent: matrixEventPropType };

const NAMES_HIDDEN_THRESHOLD = 5;
const NAMES_SHOWN_LIMIT = 3;

const MembershipEvent = ({ mEvent }) => {
  // This event type is custom created in the "useEventsGroupedByDay" hook in the "Timeline" component.

  const membershipSummary = mEvent.membershipMeta
    .reduce((finalText, { members, text }) => {
      const limitedMembers =
        members.length > NAMES_HIDDEN_THRESHOLD
          ? members.slice(0, NAMES_SHOWN_LIMIT)
          : members;

      const limitedMembersText =
        members.length > NAMES_HIDDEN_THRESHOLD
          ? ` along with ${members.length - NAMES_SHOWN_LIMIT} others`
          : "";

      const membersEnumeration = joinWithComplexSeparator(
        limitedMembers,
        ", ",
        members.length === 2 ? " and " : ", and "
      );

      return `${finalText} ${membersEnumeration} ${text}${limitedMembersText}. `;
    }, "")
    .trim();

  return (
    <Box d="flex" mV="xs">
      <TimelineText>{membershipSummary}</TimelineText>
    </Box>
  );
};
MembershipEvent.propTypes = propTypes;

export default MembershipEvent;
