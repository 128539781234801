import { useMemo } from "react";
import groupBy from "lodash/groupBy";
import last from "lodash/last";
import transform from "lodash/transform";
import { DateTime } from "luxon";

import { eventTypes, membershipStates } from "../../../constants";
import { useMatrix } from "../../../network/MatrixContext";
import {
  isEventTarget,
  isMembershipEvent,
  isTopicChangeEvent,
  isUserInfoUpdateEvent,
} from "../../../utils/matrixUtils";

const getMembershipSequenceEvent = (membershipEventsSequence, myUserId) => {
  const sequenceSplitByMember = groupBy(
    membershipEventsSequence,
    (e) => e.target.userId
  );

  const membershipMeta = transform(
    sequenceSplitByMember,
    (membershipMeta, membershipEvents, memberId) => {
      // If there are multiple membership events, we only care about
      // the last event as that will determine wether it is a
      // "joined", a "rejoined", a "left" or a "joined and left".
      const relevantEvent =
        membershipEvents.length > 1
          ? last(membershipEvents)
          : membershipEvents[0];

      const imTarget = isEventTarget(myUserId, relevantEvent);
      // Getting the actual wording that can be displayed to the user
      const membershipStateText = isUserInfoUpdateEvent(relevantEvent)
        ? imTarget
          ? "updated your user info"
          : "updated their user info"
        : membershipStates[
            relevantEvent.event.content.membership
          ].getDescription({
            isLastInSequence: membershipEvents.length > 1,
          });
      const memberName = relevantEvent.event.content.displayname;

      if (last(membershipMeta)?.text === membershipStateText) {
        last(membershipMeta).members.push({ memberId, memberName });
      } else {
        membershipMeta.push({
          text: membershipStateText,
          members: [{ memberId, memberName }],
        });
      }
    },
    []
  );

  return {
    // Simulating a Matrix event for the purpose of passing the
    // "isMembershipEvent" check in the "Event" component.
    event: {
      type: eventTypes.room.MEMBER,
      event_id: membershipEventsSequence[0].event.event_id, // using this as the key prop on the "Event" component
    },
    membershipMeta,
  };
};

const getTopicChangeSequenceEvent = (topicSequence) => {
  return topicSequence[topicSequence.length - 1];
};

const sequenceEventsMap = [
  {
    isSequenceEvent: isMembershipEvent,
    getSequenceEvent: getMembershipSequenceEvent,
  },
  {
    isSequenceEvent: ({ mEvent, prevEvent }) => {
      if (!prevEvent) {
        return isTopicChangeEvent({ mEvent });
      }

      // For topic events, we only squash into a single event sequential topic
      // changes by the same user.
      return (
        mEvent?.event.sender === prevEvent.event.sender &&
        isTopicChangeEvent({ mEvent })
      );
    },
    getSequenceEvent: getTopicChangeSequenceEvent,
  },
];

const useEventsGroupedByDay = (events) => {
  const { client } = useMatrix();
  const myUserId = client.getUserId();
  const eventsGroupedByDay = useMemo(() => {
    if (!events.length) {
      return {};
    }

    const eventsByDay = groupBy(events, (e) =>
      DateTime.fromMillis(e.event.origin_server_ts).toLocaleString({
        year: "numeric",
        weekday: "long",
        month: "long",
        day: "2-digit",
      })
    );

    // Squash sequences of membership events into a single event.
    return transform(
      eventsByDay,
      (result, dayEvents, day) => {
        let sequence = [];
        result[day] = transform(
          dayEvents,
          (squashedDayEvents, mEvent, idx) => {
            const { isSequenceEvent, getSequenceEvent } =
              sequenceEventsMap.find(({ isSequenceEvent }) =>
                isSequenceEvent({ mEvent })
              ) || {};

            // Leave non-membership events alone
            if (!isSequenceEvent) {
              return squashedDayEvents.push(mEvent);
            }

            // Start constructing the sequence of membership events
            sequence.push(mEvent);

            // If the next event is a sequence event, don't process the sequence
            // yet.
            if (
              isSequenceEvent({
                mEvent: dayEvents[idx + 1],
                prevEvent: mEvent,
              })
            ) {
              return;
            }

            // We have encountered the last event in the sequence, so we'll want
            // to process the sequence into a single event that's going to be
            // displayed in the timeline.

            // Push the processed sequence as an event
            squashedDayEvents.push(getSequenceEvent(sequence, myUserId));

            sequence = [];
          },
          []
        );
      },
      {}
    );
  }, [events, myUserId]);

  return eventsGroupedByDay;
};

export default useEventsGroupedByDay;
