import * as React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import { hook } from "local_modules/automation-hooks/lib/cjs";
import PropTypes from "prop-types";

import { Box, Drawer, GhostButton, Loader, Text } from "../../components";
import CreateGroup from "./CreateGroup";

const CreateGroupDrawer = ({
  automationHook: baseAutomationHook,
  isOpen,
  onClose,
}) => {
  const [step, setStep] = React.useState(1);
  const [isSubmitPressed, setIsSubmitPressed] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const increaseStep = () => setStep((s) => s + 1);
  const decreaseStep = () => setStep((s) => (!s ? 0 : s - 1));
  const depressSubmit = () => setIsSubmitPressed(false);

  const automationHook = getHook(baseAutomationHook, "create-group");

  return (
    <Drawer.Wrapper isOpen={isOpen} onRequestClose={onClose}>
      <Drawer.Header
        onBack={step === 1 ? onClose : decreaseStep}
        backIcon={step === 1 ? "ChevronDown" : "ChevronLeft"}
        automationHook={automationHook}
      >
        {step === 1 ? (
          <>
            <Text fontSize="m">Create Group</Text>
            <Box d="flex" justifyContent="flex-end" w="50px" h="40px">
              <GhostButton
                onClick={increaseStep}
                {...hook(automationHook, "next")}
              >
                <Text fontSize="m">Next</Text>
              </GhostButton>
            </Box>
          </>
        ) : (
          <>
            <Text fontSize="m">Create Group</Text>
            <Box d="flex" justifyContent="flex-end" w="50px">
              {isSubmitting ? (
                <Box
                  // Using height because it needs to have the same height as
                  // the Create button
                  h="40px"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Loader {...hook(automationHook, "new-group-loader")} />
                </Box>
              ) : (
                <Box
                  // Using height because it needs to have the same height as
                  // the Loader
                  h="40px"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <GhostButton
                    onClick={() => setIsSubmitPressed(true)}
                    {...hook(automationHook, "create")}
                  >
                    <Text fontSize="m">Create</Text>
                  </GhostButton>
                </Box>
              )}
            </Box>
          </>
        )}
      </Drawer.Header>
      <Drawer.Content>
        <CreateGroup
          automationHook={automationHook}
          isSubmitPressed={isSubmitPressed}
          setIsSubmitting={setIsSubmitting}
          depressSubmit={depressSubmit}
          step={step}
        />
      </Drawer.Content>
    </Drawer.Wrapper>
  );
};
CreateGroupDrawer.propTypes = {
  automationHook: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateGroupDrawer;
