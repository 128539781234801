import styled, { css } from "styled-components";

import { Tag } from "../../../components";

const JumpToButton = styled(Tag).attrs({
  color: "textInverted",
})`
  position: sticky;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  ${(props) =>
    css`
      z-index: ${props.isVisible ? 30 : "-1"};
      background-color: ${props?.theme?.colors?.primary};
      &:before {
        background-color: ${props?.theme?.colors?.primary};
      }
    `}
`;

export default JumpToButton;
