import { useEffect, useState } from "react";

import isScrolled from "./isScrolled";
import { useMatrix } from "../../../network/MatrixContext";

const useUnreadIndicator = ({ room, timelineRef }) => {
  const { notificationStore } = useMatrix();
  const firstUnreadEvent = notificationStore.getRoomFirstUnreadEvent({ room });

  // Show the new indicator if there are unread events when first opening the
  // room.
  const [shouldShowUnreadIndicator, setShowUnreadIndicator] = useState(
    !!firstUnreadEvent
  );

  useEffect(() => {
    const timeline = timelineRef.current;
    const showUnreadIndicator = () => {
      setShowUnreadIndicator(true);
    };

    const showUnreadIndicatorIfScrolled = () => {
      if (!isScrolled(timeline)) {
        return;
      }

      setShowUnreadIndicator(true);
    };

    window.addEventListener("blur", showUnreadIndicator);
    timeline.addEventListener("scroll", showUnreadIndicatorIfScrolled);

    return () => {
      window.removeEventListener("blur", showUnreadIndicator);
      timeline.removeEventListener("scroll", showUnreadIndicatorIfScrolled);
    };
  }, [notificationStore, timelineRef]);

  return {
    shouldShowUnreadIndicator,
    shouldShowUnreadIndicatorFor: (event) =>
      event === firstUnreadEvent && shouldShowUnreadIndicator,
  };
};

export default useUnreadIndicator;
