import styled, { css } from "styled-components";

import { mixins } from "../components";

const Row = styled.li`
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
`;

const List = styled.ul`
  ${mixins.listBareMixin};

  ${Row} {
    ${(props) =>
      props.withSeparator &&
      mixins.separatorMixin({ pL: "none", pR: "none", ...props }, "bottom")};

    ${(props) =>
      props.withSeparator &&
      css`
        &:last-child {
          &::after {
            display: none;
          }
        }
      `};

    ${(props) => mixins.paddingMixin(props, false)}
    ${(props) => mixins.marginMixin(props, false)}
  }
`;
List.Row = Row;

export default List;
