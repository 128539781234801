// How many pixels from the top of the timeline should we start the pagination
export const PAGINATION_TRIGGER_PX = 200;
// How many events to load each time we paginate
export const PAGINATION_LIMIT = 30;
export const SCROLL_DETECTION_THRESHOLD = -30;
export const MAX_COMPOSER_ROWS = 4;

export const relativeRoutes = {
  SETTINGS: "meta",
  MEMBERS: "members",
  MEMBERS_ADD: "add",
  ATTACHMENTS: "attachments",
  APPEARANCE: "appearance",
};
export const topicCharacterLimit = 140;

export const routes = {
  LOGIN: "/login",
  ROOMS: "/rooms",
  MESSAGE: "/view",
  CAMERA: "/camera",
};

// https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/models/room.ts#L157-L172
export const roomEvents = {
  MY_MEMBERSHIP: "Room.myMembership",
  TAGS: "Room.tags",
  ACCOUNT_DATA: "Room.accountData",
  RECEIPT: "Room.receipt",
  NAME: "Room.name",
  REDACTION: "Room.redaction",
  REDACTION_CANCELLED: "Room.redactionCancelled",
  LOCAL_ECHO_UPDATED: "Room.localEchoUpdated",
  TIMELINE: "Room.timeline",
  TIMELINE_RESET: "Room.timelineReset",
  TIMELINE_REFRESH: "Room.TimelineRefresh",
  OLD_STATE_UPDATED: "Room.OldStateUpdated",
  CURRENT_STATE_UPDATED: "Room.CurrentStateUpdated",
  HISTORY_IMPORTED_WITHIN_TIMELINE: "Room.historyImportedWithinTimeline",
};

// https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/client.ts#L781-L790
export const clientEvents = {
  SYNC: "sync",
  EVENT: "event",
  TO_DEVICE_EVENT: "toDeviceEvent",
  ACCOUNT_DATA: "accountData",
  ROOM: "Room",
  DELETE_ROOM: "deleteRoom",
  SYNC_UNEXPECTED_ERROR: "sync.unexpectedError",
  CLIENT_WELL_KNOWN: "WellKnown.client",
};

// https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/%40types/event.ts#L99-L109
export const messageTypes = {
  TEXT: "m.text",
  EMOTE: "m.emote",
  NOTICE: "m.notice",
  IMAGE: "m.image",
  FILE: "m.file",
  AUDIO: "m.audio",
  LOCATION: "m.location",
  VIDEO: "m.video",
  KEY_VERIFICATION_REQUEST: "m.key.verification.request",
};

export const eventTypes = {
  room: {
    // State events
    // https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/%40types/event.ts#L20
    // CANONICAL_ALIAS: "m.room.canonical_alias",
    CREATE: "m.room.create",
    // JOIN_RULES: "m.room.join_rules",
    MEMBER: "m.room.member",
    // THIRD_PARTY_INVITE: "m.room.third_party_invite",
    POWER_LEVELS: "m.room.power_levels",
    // NAME: "m.room.name",
    TOPIC: "m.room.topic",
    // AVATAR: "m.room.avatar",
    // PINNED_EVENTS: "m.room.pinned_events",
    // ENCRYPTION: "m.room.encryption",
    // HISTORY_VISIBILITY: "m.room.history_visibility",
    // GUEST_ACCESS: "m.room.guest_access",
    // SERVER_ACL: "m.room.server_acl",
    // TOMBSTONE: "m.room.tombstone",
    // REDACTION: "m.room.redaction",
    // Timeline Events
    // https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/%40types/event.ts#L44
    MESSAGE: "m.room.message",
    // Ephemeral events
    // https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/%40types/event.ts#L70
    // TYPING: "m.room.typing",
    // RECEIPT: "m.receipt",
    // PRESENCE: "m.presence",
  },
};

// https://spec.matrix.org/v1.3/client-server-api/#mroommember
// Keys are values as set on mEvent.event.content.membership.
export const membershipStates = {
  // Whatever keys are set in this object will be considered supported membership events. @see matrixUtils.js -> isSupportedMembershipEvent
  join: {
    getDescription: ({ isLastInSequence } = {}) =>
      isLastInSequence ? "left and then rejoined" : "joined",
  },
  leave: {
    getDescription: ({ isLastInSequence } = {}) =>
      isLastInSequence ? "joined and then left" : "left",
  },
  // invite: {
  //   getDescription: ({ isLastInSequence } = {}) =>
  //     isLastInSequence ? "rejoined" : "joined",
  // },
  // ban: { getDescription: ({ isLastInSequence } = {}) => "banned" },
  // kick: { getDescription: ({ isLastInSequence } = {}) => "kicked out" },
};

export const memberRoles = {
  OWNER: "Owner",
  ADMIN: "Admin",
  MODERATOR: "Moderator",
  MEMBER: "Member",
};

export const confirmationModalType = {
  LEAVE_GROUP: "leave group",
  DELETE_GROUP: "delete group",
};

export const localStorageValues = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  USER_ID: "USER_ID",
  USER_INFO: "USER_INFO",
  APP_ID: "APP_ID",
  IS_IOS: "IS_IOS",
  IS_APP: "IS_APP",
};

export const permissionTypes = {
  INVITE: "invite",
  KICK: "kick",
  BAN: "ban",
  REDACT: "redact",
  STATE_DEFAULT: "state_default",
  EVENTS_DEFAULT: "events_default",
  USERS_DEFAULT: "users_default",
  EVENTS: "events",
  DELETE_ROOM: "delete_room",
  eventTypes: {
    "M.ROOM.MESSAGE": "m.room.message",
    "M.ROOM.NAME": "m.room.name",
    "M.ROOM.TOPIC": "m.room.topic",
    "M.ROOM.ATTACHMENTS": "m.room.attachments",
  },
};

export const permissionLevels = {
  [permissionTypes["KICK"]]: 50,
  [permissionTypes["BAN"]]: 50,
  [permissionTypes["INVITE"]]: 50,
  [permissionTypes["REDACT"]]: 50,
  [permissionTypes["STATE_DEFAULT"]]: 50,
  [permissionTypes["EVENTS_DEFAULT"]]: 0,
  [permissionTypes["USERS_DEFAULT"]]: 0,
  [permissionTypes["EVENTS"]]: {
    [permissionTypes.eventTypes["M.ROOM.MESSAGE"]]: 0,
    [permissionTypes.eventTypes["M.ROOM.NAME"]]: 50,
    [permissionTypes.eventTypes["M.ROOM.TOPIC"]]: 50,
    [permissionTypes.eventTypes["M.ROOM.ATTACHMENTS"]]: 0,
  },
  [permissionTypes["DELETE_ROOM"]]: 100,
};

export const maxFileUploadSize = 25; // MB. Keep in sync with nginx.
