import useMembers from "network/useMembers";

import { useMatrix } from "../../network/MatrixContext";
import useSearchUserDirectory from "../../network/useSearchUserDirectory";
import { getOrgRoomFilterFn } from "../../utils/displayUtils";

const useSearchDMCandidates = ({ searchTerm }) => {
  const { roomStore, userInfo } = useMatrix();
  const { room } = useMatrix({ roomId: userInfo.orgRoomId });
  const { members: orgRoomMembers } = useMembers({ room });

  const orgRoomFilterFn = getOrgRoomFilterFn({
    searchTerm,
    orgRoomMembers,
  });

  return useSearchUserDirectory({
    searchTerm,
    filterFn: (user) =>
      // Exclude users I have ongoing conversations with
      !roomStore.rooms.some((room) =>
        room.summaryHeroes?.includes(user.user_id)
      ) &&
      // Only the users from my organization if the search is not done by email or with the exact id searched
      orgRoomFilterFn(user),
  });
};

export default useSearchDMCandidates;
