import { useMatrix } from "network/MatrixContext";
import { useQuery } from "react-query";

const useFetchAttachments = ({ room, attachmentIds }) => {
  const { client } = useMatrix();

  const {
    status,
    error,
    data: attachments,
  } = useQuery(["loadAttachments", attachmentIds], async () => {
    const fetchPromises = attachmentIds.map((eventId) =>
      client
        .fetchRoomEvent(room?.roomId, eventId)
        .catch((error) =>
          console.error(
            `Could not find attachment with id -> ${eventId} \n ${error}`
          )
        )
    );
    const fetchedEvents = await Promise.all(fetchPromises);

    return fetchedEvents
      ?.filter(Boolean)
      ?.sort((a, b) => b.origin_server_ts - a.origin_server_ts);
  });

  return { attachments, status, error };
};

export default useFetchAttachments;
