import { getEnv } from "@airportlabs/js-tools";

import { localStorageValues } from "../../constants";
import { decodeMatrixIdToEmail } from "../../utils/chat-tools";

/**
 * Used for creating notification pushers on login for new users or for users
 * that login from a new parent app.
 *
 * This function assumes that the parent app sends an appId on the login response
 * and it is set (by ui) in the local storage.
 * This appId need to exist on APP_IDS_WHITELIST env var in order to be valid.
 * There also needs to be an [APP_ID]_PUSHER_URL in the env vars for each parent app.
 * The [APP_ID]_PUSHER_URL is the url that receives the notifications.
 * More info regarding the params we set on pushers can be found here:
 * https://spec.matrix.org/v1.6/client-server-api/#push-notifications
 *
 * Assuming these conditions are met, this function creates a matrix pusher that
 * sends notifications when an event is triggered.
 *
 * @param client MatrixClient
 * @param userId matrix user id
 * @return {Promise<void>}
 */
const setPushers = async (client, userId) => {
  const currentAppId = localStorage.getItem(localStorageValues.APP_ID);
  const appIdsWhitelist = getEnv("REACT_APP_IDS_WHITELIST");
  const decodedEmail = decodeMatrixIdToEmail(userId);

  if (!appIdsWhitelist.split(",").includes(currentAppId)) {
    return;
  }
  const currentAppUrl = getEnv(`REACT_APP_${currentAppId}_PUSHER_URL`);

  if (!currentAppUrl) {
    return;
  }
  const { pushers } = await client.getPushers();
  const newPusher = {
    app_display_name: currentAppId,
    app_id: currentAppId,
    kind: "http",
    lang: "en",
    // we set the user email for easier matching of the users on the parent app
    pushkey: decodedEmail,
    device_display_name: getEnv("REACT_APP_DEVICE_ID"),
    data: {
      url: currentAppUrl,
    },
  };

  if (pushers.length === 0) {
    await client.setPusher({
      ...newPusher,
      append: false,
    });
  } else {
    const currentAppPusher = pushers.find((p) => p.app_id === currentAppId);
    if (!currentAppPusher) {
      await client.setPusher({
        ...newPusher,
        append: true,
      });
    }
  }
};

export default setPushers;
