import * as React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { GhostButtonWithIcon, List } from "../../../../../../components";
import useRoomPermissions from "../../../../../../network/useRoomPermissions";
import { matrixRoomPropType } from "../../../../../../propTypes";

const propTypes = {
  room: matrixRoomPropType,
  member: PropTypes.object,
  setRoleChangeData: PropTypes.func,
  isMe: PropTypes.bool,
  kickMutation: PropTypes.func,
  closePopover: PropTypes.func,
  automationHook: PropTypes.string,
};

const ActionsList = ({
  room,
  member,
  setRoleChangeData,
  isMe,
  kickMutation,
  closePopover,
  automationHook,
}) => {
  const { iCan, iAm, memberCan } = useRoomPermissions({ room });

  return (
    <List pV="xs">
      <List.Row key="change-role">
        <GhostButtonWithIcon
          p="none"
          icon="User"
          disabled={!iCan?.changeRoles}
          onClick={() => {
            setRoleChangeData({
              member,
              room,
              myPower: iAm?.thisPowerful,
              isMe,
            });
            closePopover();
          }}
          automationHook={getHook(automationHook, "change-role")}
        >
          Change role
        </GhostButtonWithIcon>
      </List.Row>
      <List.Row key="delete-user">
        <GhostButtonWithIcon
          p="none"
          icon="Trash2"
          disabled={!memberCan?.beRemoved(member)}
          onClick={() => {
            kickMutation({ userId: member.userId });
            closePopover();
          }}
          automationHook={getHook(automationHook, "remove")}
        >
          Delete user
        </GhostButtonWithIcon>
      </List.Row>
    </List>
  );
};
ActionsList.propTypes = propTypes;

export default ActionsList;
