import React, { useCallback } from "react";
import { getHook } from "@airportlabs/automation-hooks";
import { ConfirmationModal } from "@airportlabs/smalt";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import useAttachments from "routes/roomPage/timeline/useAttachments";

import {
  Box,
  DrawerMenuItem,
  List,
  Section,
  Text,
  Toggle,
} from "../../../../components";
import {
  confirmationModalType,
  relativeRoutes,
  routes,
} from "../../../../constants";
import { useMatrix } from "../../../../network/MatrixContext";
import useMembers from "../../../../network/useMembers";
import useRoomPermissions from "../../../../network/useRoomPermissions";
import { matrixRoomPropType } from "../../../../propTypes";
import { isOrganisationRoom } from "../../../../utils/displayUtils";
import {
  getRoomAlias,
  isDMRoom,
  kickAllUsersBesidesAdminFromRoom,
} from "../../../../utils/matrixUtils";
import useLeaveMutation from "./common/useLeaveMutation";
import DMDetails from "./root/DMDetails";
import GroupDetailsForm from "./root/GroupDetailsForm";

const propTypes = {
  // "button" and "title" are set in the parent DrawerInner component
  button: PropTypes.shape({
    callback: PropTypes.func,
    label: PropTypes.string,
    automationHook: PropTypes.string,
  }),
  title: PropTypes.string,
  room: matrixRoomPropType,
  automationHook: PropTypes.string,
};

const Root = ({ title, button, room, automationHook }) => {
  const isDM = isDMRoom({ room });
  const { members } = useMembers({ room });

  const { iCan } = useRoomPermissions({ room });

  const { leaveMutation } = useLeaveMutation();
  const { client } = useMatrix();
  const navigate = useNavigate();
  const [confirmationModal, setConfirmationModal] = React.useState("");
  //TODO: remove isOrgRoom checks after making sure users never end up seeing an orgRoom
  const isOrgRoom = isOrganisationRoom({ room });
  const { attachmentIds } = useAttachments({ room });

  const leaveRoom = useCallback(
    () =>
      leaveMutation({
        roomId: room.roomId,
        afterLeavingCallback: () => navigate(routes.ROOMS),
      }),
    [leaveMutation, navigate, room.roomId]
  );

  return (
    <>
      {isDM && (
        <Section borderBottom pT="m" pB="base">
          <DMDetails
            room={room}
            automationHook={getHook(automationHook, "member-info")}
            title={title}
          />
        </Section>
      )}
      <Section borderBottom pV="base">
        {!isDM && (
          <GroupDetailsForm
            room={room}
            automationHook={getHook(automationHook, "edit-group")}
            title={title}
            button={button}
          />
        )}
        <Box mT={isDM ? "none" : "m"}>
          <List mV="xs">
            {!isDM && (
              <List.Row key={"members"}>
                <DrawerMenuItem
                  automationHook={getHook(
                    automationHook,
                    "go-to-group-members"
                  )}
                  iconName="TaskMenuUsers"
                  text="Members"
                  as={Link}
                  to={relativeRoutes.MEMBERS}
                  withChevron
                  count={members.length}
                />
              </List.Row>
            )}
            <List.Row key={"attachments"}>
              <DrawerMenuItem
                automationHook={getHook(
                  automationHook,
                  "go-to-group-attachments"
                )}
                as={Link}
                to={relativeRoutes.ATTACHMENTS}
                iconName="Paperclip"
                text="Attachments"
                withChevron
                count={attachmentIds?.length.toString()}
              />
            </List.Row>
            {/* Disable "Appearance" until implementation. */}
            {false && (
              <List.Row key={"appearance"}>
                <DrawerMenuItem
                  automationHook={getHook(
                    automationHook,
                    "go-to-group-appearance"
                  )}
                  as={Link}
                  iconName="FilterSlider"
                  to={relativeRoutes.APPEARANCE}
                  text="Appearance (not implemented)"
                  withChevron
                />
              </List.Row>
            )}
          </List>
        </Box>
      </Section>
      <Section borderBottom={!isDM} pV="base">
        <DrawerMenuItem
          automationHook={getHook(automationHook, "silent-notifications")}
          iconName="Bell"
          text="Mute Conversation"
          disabled
        >
          <Toggle disabled />
        </DrawerMenuItem>
      </Section>
      {!isDM && (
        <Section pV="base">
          <List mV="xs">
            <List.Row key={"leave"}>
              <DrawerMenuItem
                disabled={!iCan?.leaveRoom}
                automationHook={getHook(
                  automationHook,
                  "go-to-group-appearance"
                )}
                iconName="LogOut"
                withPointer
                onClick={() =>
                  iCan?.leaveRoom &&
                  setConfirmationModal(confirmationModalType.LEAVE_GROUP)
                }
                text="Leave Group"
                color="error"
              />
              {!iCan?.leaveRoom && (
                <Text color="textSecondary" fontSize="s">
                  You need to make someone an admin before you can leave.
                </Text>
              )}
            </List.Row>
            {iCan?.deleteRoom && (
              <List.Row key={"delete"}>
                <DrawerMenuItem
                  disabled={isOrgRoom}
                  iconName="Trash2"
                  text="Delete Group"
                  onClick={() =>
                    iCan?.deleteRoom &&
                    setConfirmationModal(confirmationModalType.DELETE_GROUP)
                  }
                  withPointer
                  color="error"
                  automationHook={getHook(automationHook, "delete-group")}
                />
                {isOrgRoom && (
                  <Text color="textSecondary" fontSize="s">
                    Organisation rooms cannot be deleted.
                  </Text>
                )}
              </List.Row>
            )}
          </List>
          {/* TODO: see if ConfirmationModal's design need to be changed  */}
          <ConfirmationModal
            isOpen={confirmationModal === confirmationModalType.LEAVE_GROUP}
            onCancel={() => setConfirmationModal("")}
            closeModal={() => setConfirmationModal("")}
            title={`Are you sure that you want to leave "${getRoomAlias(
              room
            )}" group?`}
            confirmButtonText="Leave"
            onConfirm={leaveRoom}
          />
          <ConfirmationModal
            isOpen={confirmationModal === confirmationModalType.DELETE_GROUP}
            onCancel={() => setConfirmationModal("")}
            closeModal={() => setConfirmationModal("")}
            title={`Are you sure that you want to delete "${getRoomAlias(
              room
            )}" group?`}
            confirmButtonText="Delete"
            onConfirm={async () => {
              //This will ensure that the admin is the last to leave the room
              await kickAllUsersBesidesAdminFromRoom({
                members,
                adminId: client.credentials.userId,
                client,
                room,
              });
              leaveRoom();
            }}
          />
        </Section>
      )}
    </>
  );
};
Root.propTypes = propTypes;

export default Root;
