// Based on:
// https://github.com/cinnyapp/cinny/blob/dev/src/client/state/RoomList.js

import * as React from "react";
import invariant from "invariant";
import sortBy from "lodash/sortBy";
import transform from "lodash/transform";

const roomTypes = {
  ROOMS: "rooms",
  DIRECTS: "directs",
  INVITE_DIRECTS: "inviteDirects",
  INVITE_ROOMS: "inviteRooms",
  // https://spec.matrix.org/v1.3/client-server-api/#mdirect
  M_DIRECTS: "mDirects",
};

const sortRoomsByAtoZ = (rooms) =>
  sortBy(
    rooms,
    (room) =>
      // Ignore "#" when sorting
      room &&
      (room.getCanonicalAlias() || room.name).replace(/#/g, "").toLowerCase()
  );

// TODO should ultimately store DM rooms / roomIds on a separate key from all
// rooms, or set a key isDMRoom on each room when we add it to the store
const initAndSortRooms = (rooms, storeKey, store) => ({
  ...store,
  [storeKey]: sortRoomsByAtoZ(rooms),
  __init: true,
});

const actions = {
  ADD_ROOM: "add-room",
  INIT_ROOMS: "init-rooms",
  UPDATE_ROOMS: "update-rooms",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INIT_ROOMS:
      return initAndSortRooms(action.rooms, action.roomType, state);
    case actions.UPDATE_ROOMS:
      return transform(
        state,
        (result, rooms, type) => {
          // OPTIMIZE: Maybe only update part of state that contains that room
          // This requires passing the updated room and then consuming state
          // sections in a way that does to trigger useless re-renders.
          result[type] = type.startsWith("__")
            ? rooms
            : rooms.map((room) => action.client.getRoom(room.roomId));
        },
        {}
      );
    case actions.ADD_ROOM:
      return {
        ...state,
        [action.roomType]: sortRoomsByAtoZ([
          ...state[action.roomType],
          action.room,
        ]),
      };
    default:
      return state;
  }
};

const initialState = {
  [roomTypes.ROOMS]: [],
  [roomTypes.DIRECTS]: [],
  [roomTypes.INVITE_DIRECTS]: [],
  [roomTypes.INVITE_ROOMS]: [],
  // https://spec.matrix.org/v1.3/client-server-api/#mdirect
  [roomTypes.M_DIRECTS]: [],
  // Indicates wether rooms have synced
  __init: false,
};

const useRoomStore = ({ client, lastSyncDate }) => {
  const [roomState, dispatch] = React.useReducer(reducer, initialState);

  const roomStore = React.useMemo(
    () => ({
      ...roomState,
      addRoom: ({ roomId }) => {
        invariant(roomId, "You must provide a `roomId` to `addRoom`.");

        if (!client) {
          return;
        }

        dispatch({
          type: actions.ADD_ROOM,
          roomType: roomTypes.ROOMS,
          room: client.getRoom(roomId),
        });
      },
      // Makes sure to validate input to the store methods
      // Add any other public store methods go here...
      // someMethod: ({ inputsDestructured, fromObject }) => {
      //   invariant(
      //     inputsDestructured || fromObject,
      //     "You must provide either `inputsDestructured` or `fromObject` to `someMethod`."
      //   );
      //   /* ... */
      // },
    }),
    [roomState, client]
  );

  React.useEffect(() => {
    if (!client || !lastSyncDate) {
      return;
    }

    dispatch({
      type: actions.INIT_ROOMS,
      rooms: client
        .getRooms()
        .filter((room) => room.getMyMembership() === "join"),
      roomType: roomTypes.ROOMS,
    });

    const removeEventListeners = setEventListeners(client, dispatch);

    return removeEventListeners;
  }, [client, lastSyncDate]);

  return { roomStore };
};

const setEventListeners = (client, dispatch) => {
  // http://matrix-org.github.io/matrix-js-sdk/9.0.1/module-client.html#~event:MatrixClient%2522accountData%2522
  const onAccountData = (event) => {
    console.log("accountData event", { event });
    if (event.getType() !== "m.direct") {
      return;
    }
  };
  client.on("accountData", onAccountData);

  const onRoomName = (room) => dispatch({ type: actions.UPDATE_ROOMS, client });
  client.on("Room.name", onRoomName);

  // http://matrix-org.github.io/matrix-js-sdk/9.0.1/module-client.html#~event:MatrixClient%2522RoomState.events%2522
  const onRoomStateEvents = (mEvent, state) => {
    // Possible event types (mEvent.getType() is how you get the event type):
    // - m.room.join_rules: https://spec.matrix.org/v1.3/client-server-api/#mroomjoin_rules
    // - m.room.topic: https://spec.matrix.org/v1.3/client-server-api/#mroomtopic
    // - m.room.avatar: https://spec.matrix.org/v1.3/client-server-api/#mroomavatar
    // - m.room.name: https://spec.matrix.org/v1.3/client-server-api/#mroomname
    // - m.room.canonical_alias: https://spec.matrix.org/v1.3/client-server-api/#mroomcanonical_alias
    // - see complete list on the matrix spec site
    dispatch({ type: actions.UPDATE_ROOMS, client });
  };
  client.on("RoomState.events", onRoomStateEvents);

  // https://github.com/matrix-org/matrix-js-sdk/blob/ae9bb6f27f20b93f21be0aec51b69f774779504c/src/models/room.ts#L158
  const onRoomMyMembership = (room, membership, prevMembership) => {
    // room => prevMembership = null | invite | join | leave | kick | ban | unban
    // room => membership = invite | join | leave | kick | ban | unban

    // if (membership === "invite") {
    //   client.joinRoom(room.roomId);
    // }

    // console.log({
    //   room,
    //   rooms: client
    //     .getRooms()
    //     .map((r) => ({ r, membership: r.getMyMembership() })),
    //   membership,
    // });
    const clientRooms = client.getRooms();
    // When room is first created by an user client.getRooms() does not contain yet the room, a force init for
    // the room is required if we want room to be immediately visible to all users
    const forceRoomInit =
      room.selfMembership === "join" &&
      !clientRooms.some((r) => r.roomId === room.roomId);

    dispatch({
      type: actions.INIT_ROOMS,
      client,
      roomType: roomTypes.ROOMS,
      rooms: [
        ...clientRooms.filter((room) => room.getMyMembership() === "join"),
        ...(forceRoomInit ? [room] : []),
      ],
    });
  };
  client.on("Room.myMembership", onRoomMyMembership);

  return () => {
    client.removeListener("accountData", onAccountData);
    client.removeListener("Room.name", onRoomName);
    client.removeListener("RoomState.events", onRoomStateEvents);
    client.removeListener("Room.myMembership", onRoomMyMembership);
  };
};

export default useRoomStore;
