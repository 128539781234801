import { useMutation } from "react-query";

import { useMatrix } from "../../../../../network/MatrixContext";
import { getRoomAlias } from "../../../../../utils/matrixUtils";

const useKickMutation = (room) => {
  const { client } = useMatrix();
  const roomAlias = getRoomAlias(room);

  const { mutate, ...kickMutationRest } = useMutation({
    mutationFn: ({ userId }) => {
      return client.kick(
        room.roomId,
        userId,
        `You were removed from group "${roomAlias}".`
      );
    },
    onSuccess: (data) => {
      console.log("User removed from the room", data);
    },
    onError: (err) => {
      console.log("Error removing user from the room =>", err);
    },
  });
  return {
    kickMutation: mutate,
    ...kickMutationRest,
  };
};

export default useKickMutation;
