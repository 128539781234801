import React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { GhostIconButton } from "../../../../components";
import { routes } from "../../../../constants";

const propTypes = { onClick: PropTypes.func, automationHook: PropTypes.string };

const BackButton = ({ onClick = identity, automationHook }) => {
  const navigate = useNavigate();

  return (
    <GhostIconButton
      iconSize="l"
      name="ChevronLeft"
      color="inherit"
      w="auto"
      mR="base"
      automationHook={getHook(automationHook, "back")}
      onClick={() => {
        onClick();
        navigate(routes.ROOMS);
      }}
    />
  );
};

BackButton.propTypes = propTypes;
export default BackButton;
