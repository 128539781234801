import { useCallback, useEffect, useRef, useState } from "react";
import { getHook, hook } from "@airportlabs/automation-hooks";
import { MsgType } from "matrix-js-sdk";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  Box,
  GhostIconButton,
  Input,
  Loader,
  PrimaryIconButton,
  Text,
} from "../../components";
import {
  localStorageValues,
  MAX_COMPOSER_ROWS,
  maxFileUploadSize,
} from "../../constants";
import { useMatrix } from "../../network/MatrixContext";
import { checkChatRunningEnvironment } from "../../utils/displayUtils";
import { getUsername } from "../../utils/matrixUtils";
import useAttachments from "./timeline/useAttachments";
import useTimeline from "./timeline/useTimeline";

const MESSAGE_FIELD = "text-message";

const propTypes = {
  automationHook: PropTypes.string,
  timelineRef: PropTypes.shape({ current: PropTypes.any }),
};

const Form = styled.form`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const getTypingMessage = (typers = []) => {
  switch (typers.length) {
    case 0:
      return "";
    case 1:
      return `${typers[0]} is typing...`;
    case 2:
      return `${typers[0]} and ${typers[1]} are typing...`;
    default:
      return `${typers[0]}, ${typers[1]} and others are typing...`;
  }
};

const MessageComposer = ({ automationHook, timelineRef }) => {
  const { client, room } = useMatrix({
    roomId: useParams().roomId,
  });
  const { typingMembersIds } = useTimeline({
    room,
    timelineRef,
  });

  const { handleSubmit, control, setValue, setFocus, formState } = useForm();
  const [sending, setSending] = useState("");
  const [typingMessage, setTypingMessage] = useState("");
  const { updateAttachmentIdsList } = useAttachments({ room });

  const fileInputRef = useRef();

  const sendFileData = useCallback(
    async (
      inputMethod,
      plainFiles = Array.from(fileInputRef.current.files)
    ) => {
      plainFiles.length && setSending(inputMethod);
      plainFiles.forEach(
        async (file) =>
          await client
            .uploadContent(file, {
              rawResponse: false,
              onlyContentUri: false,
            })
            .then(async (response) => {
              if (file.type.includes("image")) {
                // text and callback params seem to be mandatory in sendImageMessage, so we use empty values for them
                await client
                  .sendImageMessage(
                    room.roomId,
                    response.content_uri,
                    file.name,
                    "",
                    () => {}
                  )
                  .then((res) => updateAttachmentIdsList(res.event_id));
              } else if (file.type.includes("video")) {
                await client
                  .sendMessage(room.roomId, {
                    msgtype: MsgType.Video,
                    url: response.content_uri,
                    info: file.name,
                    body: file.webkitRelativePath,
                  })
                  .then((res) => updateAttachmentIdsList(res.event_id));
              } else {
                await client
                  .sendMessage(room.roomId, {
                    msgtype: MsgType.File,
                    url: response.content_uri,
                    info: file.name,
                    body: file.webkitRelativePath,
                  })
                  .then((res) => updateAttachmentIdsList(res.event_id));
              }
              setSending("");
            })
            .catch((err) => {
              console.error("Upload Error:", err, { file });

              // 💡: we convert the size using the decimal system but note that nginx uses the binary system
              const fileSize = file.size / 1000000;
              if (fileSize > maxFileUploadSize) {
                alert(
                  `${file.name} is too large. Please upload a file smaller than ${maxFileUploadSize}MB.`
                );
              }
              setSending("");
            })
      );
    },
    [client, room.roomId, updateAttachmentIdsList]
  );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data?.action === "takePhotoResponse") {
        const { fileBlobObj } = event?.data;
        const fileType = fileBlobObj.type;
        const fileName = `${room.roomId}_${Date.now()}`;
        const fileExtension = fileType.split("/")[1];

        const file = new File([fileBlobObj], `${fileName}.${fileExtension}`, {
          type: fileType,
        });

        sendFileData("photo", [file]);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [room.roomId, sendFileData]);

  // https://github.com/matrix-org/matrix-react-sdk/blob/e7a8dbd04cc068241d449387408100228bd44368/src/components/views/rooms/MessageComposer.tsx
  const sendMessageMutation = useMutation(
    (data) => {
      return client
        .sendTextMessage(room.roomId, data[MESSAGE_FIELD])
        .then((res) => {
          // Scroll to bottom after sending a message
          timelineRef.current.scrollTop = -1; // iOS needs a nudge to update its scroll offset else it thinks it's at 0
          timelineRef.current.scrollTop = 0;
        })
        .catch((err) => {
          console.error("MESSAGE WAS NOT SENT", err);
        });
    },
    {
      onSuccess: (data) => {},
    }
  );

  const sendMessage = (data, event) => {
    // when the user presses enter, the event.target is the textarea
    // when the user presses the send button, the event.target is the form
    const textareaElement =
      event.target.querySelector("textarea") || event.target;
    textareaElement.rows = 1;
    textareaElement.style.borderTopRightRadius = "0px";

    setValue(MESSAGE_FIELD, "");
    setFocus(MESSAGE_FIELD);

    sendMessageMutation.mutate({
      [MESSAGE_FIELD]: data[MESSAGE_FIELD],
    });
  };

  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      !checkChatRunningEnvironment(localStorageValues.IS_APP)
    ) {
      event.preventDefault();
      handleSubmit(sendMessage)(event);
    }
  };

  useEffect(() => {
    const typerIdsExceptMine = typingMembersIds.filter(
      (typer) => typer !== client.credentials.userId
    );

    const typerNames = typerIdsExceptMine?.map((userId) =>
      getUsername({ userId, client })
        .split(/\s*\|\|\s*/)[0]
        .trim()
    );
    setTypingMessage(getTypingMessage(typerNames));
  }, [typingMembersIds, client]);

  const handleRows = (e) => {
    e.target.rows = 1; // in case of backspace, we need to recalculate from 1 row
    const { scrollHeight } = e.target;
    const lineHeight = parseInt(window.getComputedStyle(e.target).lineHeight);
    const calculatedRows = Math.floor(scrollHeight / lineHeight);
    e.target.style.borderTopRightRadius = calculatedRows > 1 ? "4px" : "0px";

    calculatedRows <= MAX_COMPOSER_ROWS
      ? (e.target.rows = calculatedRows)
      : (e.target.rows = MAX_COMPOSER_ROWS);
  };

  return (
    <Box>
      {typingMessage && (
        <Box backgroundColor="inputBackground" pT="xs" pB="xxs" pH="s">
          <Text fontSize="xs" color="textSecondary" fontWeight="bold">
            {typingMessage}
          </Text>
        </Box>
      )}
      <Box
        d="flex"
        p="base"
        backgroundColor="backgroundPrimary"
        w="100%"
        bT
        alignItems="flex-end"
      >
        {sending === "file" ? (
          <Loader size="25px" mB="xxs" />
        ) : (
          <GhostIconButton
            iconSize="xl"
            name="Plus"
            color="inherit"
            w="auto"
            automationHook={getHook(automationHook, "file")}
            onClick={() => fileInputRef.current.click()}
            mR="base"
            mB="xxs"
          />
        )}
        <InvisibleInput
          ref={fileInputRef}
          type="file"
          accept=".pdf, .docx, image/*, video/*"
          multiple
          onChange={() => sendFileData("file")}
        />

        <Form onSubmit={handleSubmit(sendMessage)}>
          <Controller
            name={MESSAGE_FIELD}
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, onChange, ...field } }) => {
              formState.isSubmitting &&
                client.sendTyping(room.roomId, false, 100);

              return (
                <Input
                  as="textarea"
                  {...hook(automationHook, "message-input")}
                  w="100%"
                  placeholder="Type a message..."
                  rows={1}
                  // Don't highlight the input when required validation fails
                  // hasError={!!errors[MESSAGE_FIELD]}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  forwardedRef={ref}
                  onChange={(e) => {
                    onChange(e);
                    client.sendTyping(room.roomId, true, 3000);
                    handleRows(e);
                  }}
                  onKeyPress={handleKeyPress}
                  {...field}
                />
              );
            }}
          />
          <PrimaryIconButton
            type="submit"
            name="Send"
            shape="default"
            glued="left"
            automationHook={getHook(automationHook, "message-send")}
          />
        </Form>

        {sending === "photo" ? (
          <Loader size="25px" />
        ) : (
          <GhostIconButton
            iconSize="xl"
            name="Camera"
            color="inherit"
            w="auto"
            automationHook={getHook(automationHook, "image")}
            onClick={() => {
              window.parent.postMessage(
                {
                  action: "takePhoto",
                },
                "*"
              );
            }} // photoInputRef.current.click()}
            mL="base"
            mB="xxs"
          />
        )}
      </Box>
    </Box>
  );
};
MessageComposer.propTypes = propTypes;

export default MessageComposer;
