import React from "react";
import PropTypes from "prop-types";

import { Box, Text } from "../../../../components";

const propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
};

const HeaderText = ({ text, subText }) => {
  return (
    <Box d="flex" direction="column" gap="xxs" minW="0">
      <Text fontSize="m" fontWeight="bold" truncate>
        {text}
      </Text>
      {subText && (
        <Text color="textSecondary" truncate>
          {subText}
        </Text>
      )}
    </Box>
  );
};

HeaderText.propTypes = propTypes;
export default HeaderText;
