import PropTypes from "prop-types";

import { Heading1, PrimaryButton, Section, Text } from ".";

const propTypes = {
  error: PropTypes.any,
};

const ErrorFallbackComponent = ({ error }) => {
  let message = typeof error === "string" ? error : error.message;
  return (
    <Section d="flex" direction="column" pV="xxl" center>
      <Heading1>Something went wrong:</Heading1>
      <Text mB="l">{message}</Text>
      <PrimaryButton onClick={() => window.location.reload()}>
        Reload
      </PrimaryButton>
    </Section>
  );
};

ErrorFallbackComponent.propTypes = propTypes;

export default ErrorFallbackComponent;
