import * as React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { GhostButtonWithIcon, List } from "../../components";
import { drawerTypes } from "./helpers";

const propTypes = {
  isCompact: PropTypes.bool,
  // A function to be executed after any item is clicked
  onClick: PropTypes.func,
  setShownDrawer: PropTypes.func,
  automationHook: PropTypes.string,
};

const NewChatList = ({
  isCompact,
  onClick,
  setShownDrawer,
  automationHook,
  ...rest
}) => {
  const pV = isCompact ? "xs" : "base";

  return (
    <List {...rest}>
      <List.Row key="new-direct-message">
        <GhostButtonWithIcon
          pV={pV}
          icon="UserFill"
          onClick={() => {
            setShownDrawer(drawerTypes.DM);
            onClick?.();
          }}
          automationHook={getHook(automationHook, "new-direct-message")}
        >
          New Direct Message
        </GhostButtonWithIcon>
      </List.Row>
      <List.Row key="new-group-chat">
        <GhostButtonWithIcon
          pV={pV}
          icon="UsersDuo"
          onClick={() => {
            setShownDrawer(drawerTypes.GROUP);
            onClick?.();
          }}
          automationHook={getHook(automationHook, "new-group-chat")}
        >
          New Group Chat
        </GhostButtonWithIcon>
      </List.Row>
    </List>
  );
};

NewChatList.propTypes = propTypes;
export default NewChatList;
