import PropTypes from "prop-types";
import { getHook } from "@airportlabs/automation-hooks";

import { ListCard } from "./";
import { childrenPropType, matrixUserPropType } from "../propTypes";

const propTypes = {
  // Matrix User
  mUser: matrixUserPropType,
  name: PropTypes.string,
  subtext: PropTypes.string,
  children: childrenPropType,
  onClick: PropTypes.func,
  // Whether to mark the user as the logged in user
  isSelf: PropTypes.bool,
  automationHook: PropTypes.string,
};

const UserCard = ({
  name,
  children,
  subtext,
  onClick,
  isSelf = false,
  automationHook,
}) => {
  return (
    <ListCard
      isUser
      name={name + (isSelf ? " (You)" : "")}
      subtext={subtext}
      onClick={onClick}
      automationHook={getHook(automationHook, "user")}
    >
      {children}
    </ListCard>
  );
};
UserCard.propTypes = propTypes;
UserCard.Skeleton = ListCard.Skeleton;

export default UserCard;
