import { useEffect, useState } from "react";
import { getHook } from "@airportlabs/automation-hooks";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box } from "../../components";
import { relativeRoutes, routes } from "../../constants";
import { useMatrix } from "../../network/MatrixContext";
import { childrenPropType, matrixRoomPropType } from "../../propTypes";
import { isDMRoom } from "../../utils/matrixUtils";
import RoomHeader from "./headerLayout/RoomHeader";
import RoomMetaDrawer from "./headerLayout/RoomMetaDrawer";

const propTypes = {
  children: childrenPropType,
  room: matrixRoomPropType,
  onBackClick: PropTypes.func,
  automationHook: PropTypes.string,
};

// TODO: observing this issue with modal:
// https://github.com/reactjs/react-modal/issues/808

const HeaderLayout = ({ children, onBackClick = identity, automationHook }) => {
  const { room } = useMatrix({ roomId: useParams().roomId });
  const navigate = useNavigate();
  const { state } = useLocation();
  const isDM = isDMRoom({ room });
  const [isInfoDrawerOpened, setIsInfoDrawerOpened] = useState(
    state?.showDrawer || false
  );

  const openDrawer = () => {
    setIsInfoDrawerOpened(true);
    navigate(relativeRoutes.SETTINGS);
  };

  const onCloseDrawer = () => {
    setIsInfoDrawerOpened(false);
    // manually remove open class from parent
    // https://github.com/reactjs/react-modal/issues/888#issuecomment-1158061329
    document.body.classList.remove("ReactModal__Body--open");
    navigate(`${routes.ROOMS}/${room.roomId}`);
  };

  const handleBack = (e) => {
    e.preventDefault();
    onBackClick();
    return navigate(routes.ROOMS);
  };

  // Navigate back to room list page when
  // android phone back button is pressed (event?.data?.action === "backButtonEvent")
  const handleMessage = (event) => {
    if (event?.data?.action !== "backButtonEvent") {
      return;
    }
    if (isInfoDrawerOpened) {
      return;
    }
    handleBack(event);
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  });

  return (
    <Box d="flex" direction="column" h="100vh">
      <RoomHeader
        {...{ isDM, room, onBackClick, openDrawer }}
        automationHook={getHook(automationHook, "header")}
      />
      {children}
      <RoomMetaDrawer
        isOpen={isInfoDrawerOpened}
        room={room}
        onClose={onCloseDrawer}
        automationHook={getHook(automationHook, "info-drawer")}
      />
    </Box>
  );
};
HeaderLayout.propTypes = propTypes;

export default HeaderLayout;
