import identity from "lodash/identity";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { permissionLevels, routes } from "../constants";
import { useMatrix } from "./MatrixContext";

const createDMRoom = async ({ client, userId }) => {
  const options = {
    is_direct: true,
    invite: [userId],
    visibility: "private",
    preset: "trusted_private_chat",
    initial_state: [],
  };

  const result = await client.createRoom(options);

  // This is needed because for it seems that matrix-js-sdk doesn't do this
  // automatically for `createRoom` with `is_direct` set to `true`.
  // https://spec.matrix.org/v1.3/client-server-api/#client-behaviour-21
  const userIdToRoomIds = client.getAccountData("m.direct")?.getContent() || {};
  userIdToRoomIds[userId] = !userIdToRoomIds[userId]?.includes(result.room_id)
    ? [result.room_id]
    : userIdToRoomIds[userId];
  await client.setAccountData("m.direct", userIdToRoomIds);

  return result;
};

const createGroupRoom = async ({
  name,
  topic,
  userIds,
  joinRoom = "private",
  client,
}) => {
  // Read about all options in the Matrix specs
  // https://spec.matrix.org/v1.3/client-server-api/#post_matrixclientv3createroom
  const options = {
    name,
    topic,
    invite: userIds,
    visibility: joinRoom === "public" ? "public" : "private",
    room_alias_name: undefined,
    initial_state: [],
    power_level_content_override: {
      ...permissionLevels,
    },
    // https://spec.matrix.org/v1.3/client-server-api/#mroomcreate
    creation_content: { "m.federate": false },
  };
  const result = await client.createRoom(options);

  return result;
};

const useCreateRoom = ({
  isDM = false,
  onSuccess = identity,
  navigateToNewRoom = true,
} = {}) => {
  const { client } = useMatrix();
  const navigate = useNavigate();
  const { mutate, mutateAsync, ...createMutation } = useMutation({
    mutationFn: (options) => {
      const fn = isDM ? createDMRoom : createGroupRoom;
      return fn({ client, ...options });
    },
    onSuccess: (data) => {
      onSuccess(data.room_id);
      navigateToNewRoom && navigate(`${routes.ROOMS}/${data.room_id}`);
    },
    onError: (error, vars, context) => {
      console.error("Error creating room", error, vars, context);
    },
  });

  return {
    createRoom: mutate,
    createRoomAsync: mutateAsync,
    ...createMutation,
  };
};

export default useCreateRoom;
