import * as React from "react";
import { useQuery } from "react-query";

import { PAGINATION_LIMIT, PAGINATION_TRIGGER_PX } from "../../../constants";
import { useMatrix } from "../../../network/MatrixContext";
import { areAllEventsLoaded } from "../../../utils/matrixUtils";

const usePaginate = ({ timelineRef, onSuccess, room }) => {
  const { client } = useMatrix();
  const liveTimeline = React.useMemo(() => room.getLiveTimeline(), [room]);
  const [isPaginationOngoing, setIsOngoingPagination] = React.useState(false);

  const { isLoading: isPaginationLoading } = useQuery(
    ["paginate", room.roomId],
    async () => {
      await client.paginateEventTimeline(liveTimeline, {
        backwards: true,
        limit: PAGINATION_LIMIT,
      });
      return true;
    },
    {
      onSuccess: () => {
        setIsOngoingPagination(false);
        onSuccess();
      },
      enabled: isPaginationOngoing && !areAllEventsLoaded(liveTimeline),
    }
  );

  // Triggers pagination if:
  // - we are scrolled enough to the top;
  // - not enough events are loaded to fill the screen;
  const paginate = React.useCallback(() => {
    if (!timelineRef.current) {
      return;
    }

    const pixelsFromTop =
      timelineRef.current.scrollHeight -
      timelineRef.current.clientHeight +
      timelineRef.current.scrollTop;
    if (
      pixelsFromTop > PAGINATION_TRIGGER_PX ||
      areAllEventsLoaded(liveTimeline) ||
      isPaginationOngoing
    ) {
      return;
    }

    setIsOngoingPagination(true);
  }, [timelineRef, liveTimeline, isPaginationOngoing]);

  // A check whether we need to trigger another pagination needs to happen:
  // - when the page is first loaded
  // - any time a pagination just ended
  React.useEffect(() => {
    if (isPaginationOngoing || isPaginationLoading) {
      return;
    }

    paginate();
  }, [isPaginationOngoing, paginate, isPaginationLoading]);

  return { paginate, isPaginationOngoing };
};

export default usePaginate;
