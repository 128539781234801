import PropTypes from "prop-types";

import { Search } from ".";

const propTypes = {
  automationHook: PropTypes.string,
  searchTerm: PropTypes.string,
  placeholder: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

const ListSearch = ({
  automationHook,
  searchTerm,
  setSearchTerm,
  placeholder,
}) => {
  return (
    <Search
      placeholder="Search conversations"
      value={searchTerm}
      onSearch={setSearchTerm}
      searchOnChange
      automationHook={automationHook}
    />
  );
};
ListSearch.propTypes = propTypes;

export default ListSearch;
