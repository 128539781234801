import React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import styled, { css } from "styled-components";

import { helpers, mixins } from "../index";

const { backgroundMixin } = mixins;
const { getColor } = helpers;

export const modalStylePropTypes = {
  /** Specify a width for the modal window - if this is bigger than the current window width, width will be limited to 100% */
  w: PropTypes.string,
  /** Specify a fixed height for the modal window */
  height: PropTypes.string,
  /** Specify a padding for the modal window. If none is provided, it pads with base space */
  // p: PropTypes.oneOf(spaceTypes),
  /** Give a backgroundColor for the Modal Content */
  backgroundColor: PropTypes.string,
  /** When you don't want the modal to be centered, you can position it relative to the top or bottom of the screen;
   * you can combine this with the offset prop, that will give it a different margin   */
  verticalPosition: PropTypes.oneOf(["bottom", "top", "center"]),
  /** When positioning the modal vertically, you may wanna specify an offset from top/ bottom
   * which will add a margin top/bottom - so it can be any valid css string for margin
   * NOTE: this prop only works if verticalPosition is set to "top" or "bottom"
   */
  offset: PropTypes.string,
};

const ReactModalAdapter = ({
  className,
  modalClassName,
  overlayClassName,
  automationHook,
  ...rest
}) => {
  return (
    <ReactModal
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      data={{ hook: getHook(automationHook, "drawer") }}
      {...rest}
    />
  );
};

ReactModalAdapter.propTypes = {
  className: PropTypes.string,
  modalClassName: PropTypes.object,
  overlayClassName: PropTypes.string,
  /** Hook used for automation testing */
  automationHook: PropTypes.string,
};

/** Modal Styled is a wrapper around React Modal and sets styles for the overlay and the modal */
export const DrawerStyled = styled(ReactModalAdapter).attrs((props) => ({
  overlayClassName: "overlay",
  closeTimeoutMS: 0,
  modalClassName: {
    base: "drawer",
    afterOpen: "drawer--after-open",
    beforeClose: "drawer--before-close",
  },
  data: { hook: getHook(props.automationHook, "drawer") },
}))`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.modal};

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: ${(props) => getColor(props, "overlayColor", "modalOverlay")};
    align-items: center;
    max-height: 100%;
  }

  .drawer {
    width: ${(props) => props.w || "48rem"};
    max-width: 100%;
    max-height: calc(100% - 2 * ${(props) => props.theme.spaces.m});
    overflow: auto;
    outline: 0;
    border-top-left-radius: ${(props) => props.theme.borderRadius.m};
    border-top-right-radius: ${(props) => props.theme.borderRadius.m};
    ${backgroundMixin("backgroundPrimary")};
    position: relative;
    box-shadow: ${(props) => props.theme.boxShadow.modal};
    align-self: flex-end;
    display: flex;
    flex-direction: column;

    transform: translateY(101%);
    transition: all 300ms ease;
    &--after-open {
      transform: translateY(0);
    }
    &--before-close {
      transition: all 50ms ease;
      transform: translateY(101%);
    }
    &::before {
      content: "";
      top: ${(props) => props.theme.spaces.xs};
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      background: #c8c7cc;
      width: 40px;
      height: 4px;
      border-radius: 2px;
    }

    ${(props) =>
      props.height &&
      css`
        height: ${props.height};
        overflow: hidden;
      `};
  }
`;
