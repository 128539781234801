import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";

import isScrolled from "./isScrolled";

// This threshold indicates the number of events under which we consider that
// loading is not finished yet. We chose 10 because it seems that the default
// number of events that are loaded by the matrix client before pagination are
// 8.
const EVENTS_COUNT_THRESHOLD = 10;

// We want to preserve the scroll position when new events are added to the timeline to avoid glitches with the scroll moving.
const usePreserveScrollPosition = ({
  events,
  timelineRef,
  unreadRef,
  getUnreadPosition,
  scrollToUnread,
}) => {
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const beforeLastScrollPosition = usePrevious(lastScrollPosition);
  const previousEvents = usePrevious(events);

  // Saving the scroll position to kn
  useEffect(() => {
    const timeline = timelineRef.current;
    const onScroll = () => {
      setLastScrollPosition(timeline.scrollTop);
    };

    timeline.addEventListener("scroll", onScroll);
    return () => timeline.removeEventListener("scroll", onScroll);
  }, [timelineRef]);

  useEffect(() => {
    // We don't want to update the scroll position if events aren't loaded yet
    // or the scroll has just been updated. It seems that the default number of events that are loaded before pagination are 8, so we use 10 as a threshold.
    if (
      events.length < EVENTS_COUNT_THRESHOLD ||
      beforeLastScrollPosition !== lastScrollPosition
    ) {
      return;
    }

    // If events have just been loaded, we want to scroll to the Unread
    // indicator.
    if (previousEvents.length < EVENTS_COUNT_THRESHOLD) {
      timelineRef.current.scrollTop = getUnreadPosition();
      return;
    }

    // If a new message has been received, we want to either keep the current
    // scroll if scrolled, or go to the bottom if not scrolled.
    if (events.length - previousEvents.length === 1) {
      timelineRef.current.scrollTop = lastScrollPosition
        ? timelineRef.current.scrollTop
        : 0;
      return;
    }

    // If a a new batch of messages has been received from pagination, we want
    // to keep the scroll to the position it was in before the events arrived.
    if (events.length - previousEvents.length > 1) {
      timelineRef.current.scrollTop = lastScrollPosition;
      return;
    }
  }, [
    timelineRef,
    lastScrollPosition,
    getUnreadPosition,
    events,
    previousEvents,
    unreadRef,
    beforeLastScrollPosition,
  ]);
};

const useTimelineScroll = ({ room, events, timelineRef, unreadRef }) => {
  const [shouldShowJumpToButton, setShouldShowJumpToButton] = useState(() =>
    isScrolled(timelineRef.current)
  );

  const getUnreadPosition = useCallback(
    () =>
      unreadRef.current
        ? -1 *
          // The number of pixels from the top of the timeline until ...
          (timelineRef.current.scrollHeight -
            // ... the top of the new messages line
            unreadRef.current.offsetTop -
            // ... positioned at the top of the timeline ...
            timelineRef.current.clientHeight +
            // ... with the approximate size of the new messages line.
            24)
        : // If no new messages line is present, we want to jump to the bottom
          0,
    [timelineRef, unreadRef]
  );

  const scrollToUnread = useCallback(() => {
    // Using negative values because of row reverse
    timelineRef.current.scrollTop = getUnreadPosition();
  }, [getUnreadPosition, timelineRef]);

  const scrollToLatest = useCallback(() => {
    // Using negative values because of row reverse
    timelineRef.current.scrollTop = 0;
  }, [timelineRef]);

  useEffect(() => {
    const timeline = timelineRef.current;
    const scrollToUnreadIfScrolled = () => {
      setShouldShowJumpToButton(isScrolled(timeline));
    };

    timeline.addEventListener("scroll", scrollToUnreadIfScrolled);
    return () => {
      timeline.addEventListener("scroll", scrollToUnreadIfScrolled);
    };
  }, [timelineRef]);

  usePreserveScrollPosition({
    events,
    timelineRef,
    unreadRef,
    getUnreadPosition,
    scrollToUnread,
  });

  return { shouldShowJumpToButton, scrollToLatest, scrollToUnread };
};

export default useTimelineScroll;
