import { getHook, hook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { eventTypes, messageTypes, routes } from "../constants";
import { useMatrix } from "../network/MatrixContext";
import { childrenPropType, matrixRoomPropType } from "../propTypes";
import { getUserPrettyName } from "../utils/displayUtils";
import {
  getEventTime,
  getRoomAlias,
  getRoomUnreadMeta,
  getSupportedEvents,
  isEventSender,
  isEventTarget,
  isMembershipEvent,
  isUserInfoUpdateEvent,
} from "../utils/matrixUtils";
import { Box, ListCard, Text } from "./";

const propTypes = {
  room: matrixRoomPropType.isRequired,
  isDM: PropTypes.bool,
  automationHook: PropTypes.string,
};

const UnreadCount = ({ children, ...rest }) => {
  return (
    <Box
      d="flex"
      justifyContent="center"
      alignItems="center"
      w="20px"
      h="20px"
      backgroundColor="primary"
      borderRadius="50%"
    >
      <Text color="textInverted" fontSize="xs" {...rest}>
        {children}
      </Text>
    </Box>
  );
};
UnreadCount.propTypes = {
  children: childrenPropType,
  automationHook: PropTypes.string,
};

const RoomCard = ({ room, isDM, automationHook }) => {
  const { client } = useMatrix();
  const navigate = useNavigate();
  const name = getRoomAlias(room);
  const myUserID = client.getUserId();
  const latestEvent = getSupportedEvents({
    mEvents: room.getLiveTimeline().getEvents(),
    client,
  }).pop();
  const imSender = isEventSender(myUserID, latestEvent);
  const imTarget = isEventTarget(myUserID, latestEvent);
  let description = "...";
  if (latestEvent?.event.type === eventTypes.room.CREATE) {
    description = "New chat";
  } else if (latestEvent && isMembershipEvent({ mEvent: latestEvent })) {
    description = `${
      imTarget
        ? "You"
        : getUserPrettyName(latestEvent.event.content.displayname)
    } ${
      isUserInfoUpdateEvent(latestEvent)
        ? `updated ${imTarget ? "your" : "their"} user info`
        : { leave: "left", join: "joined" }[
            latestEvent.event.content.membership
          ] || latestEvent.event.content.membership
    }`;
  } else if (latestEvent?.event.type === eventTypes.room.MESSAGE) {
    switch (latestEvent.event.content.msgtype) {
      case messageTypes.TEXT:
        description = latestEvent.event.content.body;
        break;
      case messageTypes.IMAGE:
        description = "Image sent";
        break;
      case messageTypes.FILE:
        description = "File sent";
        break;
      case messageTypes.VIDEO:
        description = "Video sent";
        break;
      default:
        description = "Unsupported message sent";
        break;
    }
    description = isDM
      ? imSender
        ? `You: ${description}`
        : description
      : `${
          imSender ? "You" : getUserPrettyName(latestEvent.sender.name)
        }: ${description}`;
  } else if (latestEvent?.event.type === eventTypes.room.TOPIC) {
    description = `${
      imSender ? "You" : getUserPrettyName(latestEvent.sender.name)
    } changed the topic`;
  } else if (latestEvent?.event.type === eventTypes.room.POWER_LEVELS) {
    description = "There was a role change";
  }
  const unreadCount = getRoomUnreadMeta({ room }).total;

  return (
    <ListCard
      isUser={isDM}
      name={name}
      subtext={description}
      shouldHighlightName={!!unreadCount}
      onClick={() => navigate(`${routes.ROOMS}/${room.roomId}`)}
      automationHook={getHook(automationHook, "room")}
    >
      {!!latestEvent && (
        <Text
          {...hook(automationHook, "latest-activity-when")}
          fontSize="base"
          color="textSecondary"
        >
          {getEventTime({
            mEvent: latestEvent,
            /* https://moment.github.io/luxon/#/formatting?id=table-of-tokens */
            dateIfNotTodayFormat: "dd.LL.y",
          })}
        </Text>
      )}
      {!!unreadCount && (
        <UnreadCount {...hook(automationHook, "unread-count")}>
          {unreadCount}
        </UnreadCount>
      )}
    </ListCard>
  );
};
RoomCard.propTypes = propTypes;

export default RoomCard;
