import { useEffect, useRef, useState } from "react";
import { useMatrix } from "network/MatrixContext";

const useAttachments = ({ room }) => {
  const { client } = useMatrix();

  const roomStateIds = room?.currentState
    ?.getStateEvents("m.room.attachments")?.[0]
    ?.getContent()?.attachmentIds;

  const [attachmentIds, setAttachmentIds] = useState(() =>
    roomStateIds?.length ? roomStateIds : []
  );
  const attachmentsRef = useRef(attachmentIds);

  useEffect(() => {
    if (attachmentsRef.current.length < attachmentIds.length) {
      try {
        client
          .sendStateEvent(room.roomId, "m.room.attachments", {
            attachmentIds,
          })
          .then(() => (attachmentsRef.current = attachmentIds));
      } catch {
        console.error("Error sending state event");
      }
    }
  }, [room.roomId, attachmentIds, client]);

  function updateAttachmentIdsList(newAttachmentId) {
    setAttachmentIds((prevAttachmentIds) =>
      [...prevAttachmentIds, newAttachmentId].filter(Boolean)
    );
  }

  return {
    attachmentIds,
    updateAttachmentIdsList,
  };
};

export default useAttachments;
