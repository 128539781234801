"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var dataAttribute = "data-hook";

var getHook = function getHook(hook, value) {
  if (value && hook) {
    if (Array.isArray(value)) {
      return value.map(function (variant) {
        return "".concat(hook, "_").concat(variant);
      }).join(" ");
    }

    return "".concat(hook, "_").concat(value);
  }

  if (value) {
    if (Array.isArray(value)) {
      return value.join(" ");
    }

    return value;
  }

  return hook;
};

var hook = function hook(_hook, value) {
  return _hook || value ? _defineProperty({}, dataAttribute, getHook(_hook, value)) : {};
};

module.exports = {
  dataAttribute: dataAttribute,
  getHook: getHook,
  hook: hook
};