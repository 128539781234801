import React from "react";

import { matrixRoomPropType } from "../../../../propTypes";
import {
  getJobAndOrgText,
  getUserInfoFromDisplayName,
} from "../../../../utils/displayUtils";
import { getDMRoomMember, getRoomAlias } from "../../../../utils/matrixUtils";
import HeaderText from "./HeaderText";

const propTypes = {
  room: matrixRoomPropType.isRequired,
};

const DirectMessageHeaderText = ({ room }) => {
  const member = getDMRoomMember({ room });
  const memberInfo = getUserInfoFromDisplayName(member.displayName);
  return (
    <HeaderText
      text={getRoomAlias(room)}
      subText={getJobAndOrgText(memberInfo)}
    />
  );
};

DirectMessageHeaderText.propTypes = propTypes;
export default DirectMessageHeaderText;
