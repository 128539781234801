import { useMutation } from "react-query";

import { useMatrix } from "../../../../../network/MatrixContext";

const useLeaveMutation = () => {
  const { client } = useMatrix();

  const { mutate, ...leaveMutationRest } = useMutation({
    mutationFn: ({ roomId, afterLeavingCallback }) => {
      return client.leave(roomId, afterLeavingCallback);
    },
    onSuccess: (data) => {
      console.log("User left the room", data);
    },
    onError: (err) => {
      console.log("Error leaving the room =>", err);
    },
  });
  return {
    leaveMutation: mutate,
    ...leaveMutationRest,
  };
};

export default useLeaveMutation;
