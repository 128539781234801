import React from "react";
// import { generateVideoThumbnailViaUrl } from "@rajesh896/video-thumbnails-generator";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  Box,
  Heading4,
  Hr,
  LoadingSkeleton,
  TimelineText,
} from "../../../components";
import {
  eventTypes,
  // localStorageValues,
  messageTypes,
  routes,
} from "../../../constants";
import useColorIndex from "../../../hooks/useColorIndex";
import { useMatrix } from "../../../network/MatrixContext";
import {
  childrenPropType,
  matrixEventPropType,
  matrixRoomPropType,
} from "../../../propTypes";
import {
  // checkChatRunningEnvironment,
  getUserPrettyName,
} from "../../../utils/displayUtils";
import {
  getEventTime,
  getFileDownloadURL,
  getPowerRole,
  getRoomAlias,
  getUsername as getUsernameHelper,
  isDMRoom,
  isEventSender,
  isMembershipEvent,
} from "../../../utils/matrixUtils";
import MembershipEvent from "./event/MembershipEvent";
import * as Messages from "./event/Messages";

const MessageBubble = ({ children, mEvents, index }) => {
  const { client } = useMatrix();
  const me = client.getUserId();
  const message = mEvents[index]?.event;
  const isMine = isEventSender(me, mEvents[index]);
  const getUsername = (userId) => getUsernameHelper({ client, userId });
  const colorIndex = useColorIndex(getUsername(message.sender));
  // const isStart = !isEventSender(me, mEvents[index - 1]);
  // const isEnd = !isEventSender(me, mEvents[index + 1]);

  return (
    <Messages.Wrapper>
      <Messages.Bubble
        // isStart={isStart}
        // isEnd={isEnd}
        isMine={isMine}
      >
        {!isEventSender(message.sender, mEvents[index - 1]) && (
          <Messages.Name isMine={isMine} colorIndex={colorIndex}>
            {isMine ? "You" : getUsername(message.sender)}
          </Messages.Name>
        )}
        {children}
      </Messages.Bubble>
      <Messages.Time isMine={isMine}>
        {/* https://moment.github.io/luxon/#/formatting?id=table-of-tokens */}
        {getEventTime({ mEvent: mEvents[index], format: "T" })}
      </Messages.Time>
    </Messages.Wrapper>
  );
};
MessageBubble.propTypes = {
  children: childrenPropType,
  mEvents: PropTypes.arrayOf(matrixEventPropType),
  index: PropTypes.number,
};
const MessageBubbleSkeleton = styled(LoadingSkeleton.Rectangle)`
  ${(props) =>
    !props.isMine
      ? css`
          border-bottom-left-radius: 0;
        `
      : css`
          border-bottom-right-radius: 0;
          align-self: flex-end;
          color: white;
        `}
`;

const EventSkeleton = () => {
  const bubbleHeight = "50px";
  return (
    <Box d="flex" w="100%" direction="column" mV="base">
      <Box mB="s" d="flex" w="100%" justifyContent="center">
        <LoadingSkeleton.Line w="139px" isTagLike />
      </Box>

      <Box mB="xs" d="flex" w="100%" direction="column">
        <LoadingSkeleton.Line w="100px" mB="xs" />
        <LoadingSkeleton.Line w="200px" />
      </Box>
      <MessageBubbleSkeleton
        mB="xxs"
        bR="12px"
        w="220px"
        h={bubbleHeight}
        isMine
      />
      <LoadingSkeleton.Line w="30px" alignSelf="flex-end" />
    </Box>
  );
};

const CreateEventHeading = styled(Heading4).attrs({
  mB: "none",
})`
  word-break: break-all;
`;

const Event = React.memo(({ mEvent, mEvents, index, room }) => {
  const { client } = useMatrix();
  const myUserID = client.getUserId();
  const getUsername = (userId) => getUsernameHelper({ client, userId });
  const event = mEvent.event;
  const navigate = useNavigate();
  const getViewUrl = () =>
    `${routes.MESSAGE}/${event.room_id}/${event.content.url.slice(6)}`;
  // const isIos = checkChatRunningEnvironment(localStorageValues.IS_IOS);
  const roomSummaryHeroes = room.summaryHeroes;
  // const [videoThumbnail, setVideoThumbnail] = React.useState(null);

  if (event.type === eventTypes.room.CREATE) {
    return (
      <Box mT="base">
        {isDMRoom({ room }) ? (
          <CreateEventHeading>
            This is the very beginning of your private conversation with{" "}
            {getUsername(roomSummaryHeroes[0])}.
          </CreateEventHeading>
        ) : (
          <CreateEventHeading>
            {`${
              myUserID === event.content.creator
                ? "You"
                : getUsername(event.content.creator)
            } created this group on ${getEventTime({
              mEvent: mEvents[index],
              format: "LLLL d",
            })}. This is the very beginning of the ${getRoomAlias(
              room
            )} group.`}
          </CreateEventHeading>
        )}
        <Hr />
      </Box>
    );
  } else if (isMembershipEvent({ mEvent })) {
    const membershipMeta = mEvent.membershipMeta.map(({ text, members }) => {
      return {
        text,
        members: members.map(({ memberId, memberName }) =>
          memberId !== myUserID ? getUserPrettyName(memberName) : "You"
        ),
      };
    });
    return (
      <MembershipEvent mEvent={{ ...mEvent, membershipMeta }} key={index} />
    );
  } else if (event.type === eventTypes.room.TOPIC) {
    return (
      <Box d="flex" mV="xs">
        {event.content.topic ? (
          <TimelineText>
            {event.sender === myUserID ? "You" : getUsername(event.sender)} set
            the topic to "{event.content.topic}".
          </TimelineText>
        ) : (
          <TimelineText>
            {event.sender === myUserID ? "You" : getUsername(event.sender)} set
            the topic to be empty.
          </TimelineText>
        )}
      </Box>
    );
  } else if (event.type === eventTypes.room.POWER_LEVELS) {
    if (!event.unsigned.prev_content) {
      return;
    }

    const currentUsersState = event.content?.users;
    const prevUsersState = event.unsigned.prev_content?.users;

    const roleChangeText = Object.keys(currentUsersState).reduce(
      (acc, userId) => {
        if (prevUsersState?.[userId] !== currentUsersState?.[userId]) {
          acc = `${
            userId === myUserID ? "Your" : `${getUsername(userId)}'s`
          } role was changed to ${getPowerRole(currentUsersState[userId])}`;
        }
        return acc;
      },
      ""
    );

    return (
      <Box d="flex" mV="xs">
        <TimelineText>{roleChangeText}</TimelineText>
      </Box>
    );
  } else if (event.type === eventTypes.room.MESSAGE) {
    const fileDownloadURL = getFileDownloadURL(
      event.content.url,
      client.baseUrl
    );
    switch (event.content.msgtype) {
      case messageTypes.TEXT:
        return (
          <MessageBubble mEvents={mEvents} index={index}>
            <Messages.Text>{event.content.body}</Messages.Text>
          </MessageBubble>
        );
      case messageTypes.IMAGE:
        return (
          <MessageBubble mEvents={mEvents} index={index}>
            <Messages.Image>
              <img
                src={fileDownloadURL}
                alt={event.content.info}
                onClick={() => {
                  navigate(`${getViewUrl()}/image`, {
                    state: {
                      imageInfo: event.content.info,
                    },
                  });
                }}
              />
            </Messages.Image>
          </MessageBubble>
        );
      case messageTypes.FILE:
        return (
          <MessageBubble mEvents={mEvents} index={index}>
            <Messages.Document
              onClick={() => {
                window.parent.postMessage(
                  {
                    action: "shareFile",
                    payload: fileDownloadURL,
                  },
                  "*"
                );
              }}
            >
              {event.content.info}
            </Messages.Document>
          </MessageBubble>
        );
      /**
       * @todo investigate why Local Storage is empty on iOS at random times
       * The switch case below was left in place for future debugging purposes.
       * In random cases, the Local Storage is empty on iOS, which causes the
       * thumbnail to be an <img> tag instead of a <video> tag on iOS. This
       * causes the video to start auto playing on iOS, but *may* not be the only reason.
       *
       * As a quick fix, we're using a generic icon for any video thumbnail on all platforms
       * to prevent the auto play issue. Clicking on it will open the video player.
       */
      // case messageTypes.VIDEO:
      //   if (!isIos && !videoThumbnail) {
      //     generateVideoThumbnailViaUrl(fileDownloadURL, 1).then(
      //       (videoThumbnail) => {
      //         setVideoThumbnail(videoThumbnail);
      //       }
      //     );
      //   }
      //   const lsItems = [];
      //   const localStorage = window.localStorage;
      //   Object.entries(localStorage).forEach(([key, value]) => {
      //     lsItems.push(`${key}: ${value}`);
      //   });

      //   return (
      //     <MessageBubble mEvents={mEvents} index={index} isSending={isSending}>
      //       <Messages.Video
      //         onClick={(e) => {
      //           e.preventDefault();
      //           navigate(`${getViewUrl()}/video`);
      //         }}
      //       >
      //         {isIos ? (
      //           <video playsInline controls width="100%">
      //             <source src={`${fileDownloadURL}#t=0.001`} type="video/mp4" />
      //           </video>
      //         ) : (
      //           <img src={videoThumbnail} alt="Video loading..." width="100%" />
      //         )}
      //         {`isIos: ${isIos}, lsItems: ${lsItems}`}
      //       </Messages.Video>
      //     </MessageBubble>
      //   );
      case messageTypes.VIDEO:
        return (
          <MessageBubble mEvents={mEvents} index={index}>
            <Messages.VideoGeneric
              onClick={(e) => {
                e.preventDefault();
                navigate(`${getViewUrl()}/video`);
              }}
            >
              {event.content.info}
            </Messages.VideoGeneric>
          </MessageBubble>
        );
      default:
        return (
          <>
            <Messages.Seen by={13} isMine />
            <Messages.Separator>
              Some message type not supported
            </Messages.Separator>
          </>
        );
    }
  }
});
Event.propTypes = {
  children: childrenPropType,
  mEvent: matrixEventPropType,
  mEvents: PropTypes.arrayOf(matrixEventPropType),
  room: matrixRoomPropType,
  index: PropTypes.number,
};
Event.Skeleton = EventSkeleton;

export default Event;
