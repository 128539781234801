import * as React from "react";
import { Box } from "@airportlabs/smalt";
import { generateVideoThumbnailViaUrl } from "@rajesh896/video-thumbnails-generator";
import { FastAverageColor } from "fast-average-color";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { checkChatRunningEnvironment } from "utils/displayUtils";

import { localStorageValues } from "../constants";
import { useMatrix } from "../network/MatrixContext";
import { getFileDownloadURL } from "../utils/matrixUtils";
import HeaderLayoutMinimal from "./common/HeaderLayoutMinimal";

const ImageContainerBox = styled(Box)`
  background: ${(props) => props.bgColor || "grey"};
  height: calc(100% - 59px);
`;

// https://bobbyhadz.com/blog/javascript-download-image
const downloadImage = async (imageSrc, nameOfDownload = "image.jpg") => {
  try {
    const response = await fetch(imageSrc);
    const blobImage = await response.blob();

    const anchorElement = document.createElement("a");
    const href = URL.createObjectURL(blobImage);
    anchorElement.href = href;
    anchorElement.download = nameOfDownload;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  } catch (e) {
    console.log("Error downloading image:", e);
  }
};

const ViewMediaPage = () => {
  const { state } = useLocation();
  const { serverName, mediaId, mediaType } = useParams();

  const { client } = useMatrix();
  const fileDownloadURL = getFileDownloadURL(
    `mxc://${serverName}/${mediaId}`,
    client.baseUrl
  );

  const isIos = checkChatRunningEnvironment(localStorageValues.IS_IOS);
  //use thumbnail because the cordova app on Android doesn't create one automatically
  const [videoThumbnail, setVideoThumbnail] = React.useState("null");

  // change container background color according to the image dominant colors
  const [bgColor, setBgColor] = React.useState("grey");

  React.useEffect(() => {
    const fac = new FastAverageColor();

    const getBgColorByDominantColor = async () => {
      const color = await fac.getColorAsync(fileDownloadURL, {
        algorithm: "dominant",
      });
      setBgColor(color.hex);
    };
    getBgColorByDominantColor();
  }, [fileDownloadURL]);

  const MessageElement = () => {
    switch (mediaType) {
      case "image":
        return (
          <img
            src={fileDownloadURL}
            alt="Sorry a problem occurred"
            style={{ objectFit: "contain" }}
          />
        );
      case "video":
        if (!isIos && videoThumbnail === "null") {
          generateVideoThumbnailViaUrl(fileDownloadURL, 1).then(
            (videoThumbnail) => {
              setVideoThumbnail(videoThumbnail);
            }
          );
        }
        return isIos ? (
          <video width="100%" controls playsInline preload="auto">
            {/* skip the first millisecond of the video to get a preview*/}
            <source src={`${fileDownloadURL}#t=0.001`} type="video/mp4" />
          </video>
        ) : (
          <video width="100%" height="100%" controls poster={videoThumbnail}>
            <source src={fileDownloadURL} type="video/mp4" />
          </video>
        );
      default:
        return <>No content</>;
    }
  };

  return (
    <Box h="100vh">
      <HeaderLayoutMinimal
        canDownload={mediaType === "image"}
        onDownloadClick={() => {
          checkChatRunningEnvironment(localStorageValues.IS_APP)
            ? window.parent.postMessage(
                {
                  action: "downloadImage",
                  payload: fileDownloadURL,
                },
                "*"
              )
            : downloadImage(fileDownloadURL, state?.imageInfo);
        }}
      />
      <ImageContainerBox d="flex" justifyContent="center" bgColor={bgColor}>
        <MessageElement />
      </ImageContainerBox>
    </Box>
  );
};

export default ViewMediaPage;
