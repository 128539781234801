import forEach from "lodash/forEach";

export const addGlobalDebug = (callback) => {
  if ("development" !== process.env.NODE_ENV) {
    return;
  }

  const object = callback();

  forEach(object, (value, key) => {
    if (!value) {
      return;
    }

    window["$" + key] = value;
  });
};
