import React, { Fragment } from "react";
import { getHook, hook } from "@airportlabs/automation-hooks";
import every from "lodash/every";
import kebabCase from "lodash/kebabCase";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import transform from "lodash/transform";
import upperCase from "lodash/upperCase";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { List, RoomCard, Text } from "../../components";
import { useMatrix } from "../../network/MatrixContext";
import { isOrganisationRoom } from "../../utils/displayUtils";
import {
  getRoomUnreadMeta,
  getSupportedEvents,
  isDMRoom,
} from "../../utils/matrixUtils";
import { Heading } from "./DMCandidatesList";
import {
  checkSearchTermIsIncluded,
  getUnsuccessfulSearchMessage,
  searchType,
  sections,
} from "./helpers";
import NewChatList from "./NewChatList";

const propTypes = {
  searchTerm: PropTypes.string,
  setShownDrawer: PropTypes.func,
  automationHook: PropTypes.string,
};

const RoomLists = ({ searchTerm, setShownDrawer, automationHook }) => {
  const { roomStore, client } = useMatrix();
  const { roomId: roomIdParam } = useParams();

  const roomsByUnread = React.useMemo(
    () =>
      transform(
        roomStore.rooms,
        (result, room) => {
          if (
            // Organisation rooms are hidden for all chat users
            isOrganisationRoom({ room }) ||
            (searchTerm &&
              // using toLowerCase for easier search experience
              !checkSearchTermIsIncluded({
                searchTerm,
                room,
              }))
          ) {
            return;
          }
          const getUnreadMeta = (room) => {
            return roomIdParam !== room.roomId
              ? getRoomUnreadMeta({
                  room,
                })
              : {};
          };
          const sectionKey = getUnreadMeta(room).total
            ? sections.UNREAD
            : sections.CONVERSATIONS;
          result[sectionKey].push(room);
        },
        {
          [sections.UNREAD]: [],
          [sections.CONVERSATIONS]: [],
        }
      ),
    [roomIdParam, roomStore.rooms, searchTerm]
  );

  const noRoomWasFound = React.useMemo(
    () => every(roomsByUnread, (rooms) => !rooms.length),
    [roomsByUnread]
  );

  return (
    <>
      {noRoomWasFound ? (
        <>
          <Heading mB="xs">{upperCase(sections.CONVERSATIONS)}</Heading>
          <Text
            color="textSecondary"
            mB="s"
            {...hook(
              automationHook,
              searchTerm ? "no-chats-message" : "no-search-results-message"
            )}
          >
            {searchTerm
              ? getUnsuccessfulSearchMessage(
                  searchTerm,
                  searchType.CONVERSATIONS
                )
              : "You have no conversations. Start by creating a direct message with one of your colleagues or a group chat."}
          </Text>
          {!searchTerm && (
            <NewChatList
              withSeparator
              setShownDrawer={setShownDrawer}
              automationHook={automationHook}
            />
          )}
        </>
      ) : (
        map(roomsByUnread, (rooms, sectionKey) => {
          if (!rooms.length) {
            return <Fragment key={sectionKey}></Fragment>;
          }

          const roomsSortedByMostRecentEvent = sortBy(rooms, (r) => {
            const latestEvent = getSupportedEvents({
              mEvents: r.getLiveTimeline().getEvents(),
              client,
            }).pop();

            return latestEvent?.event.origin_server_ts;
          }).reverse();

          return (
            <Fragment key={sectionKey}>
              <Heading>{upperCase(sectionKey)}</Heading>
              <List
                positioning="left"
                mV="l"
                {...hook(
                  automationHook,
                  `section-list_${kebabCase(sectionKey)}`
                )}
              >
                {roomsSortedByMostRecentEvent.map((room) => {
                  // TODO: Event type management is similar to the one done in
                  // the Event component
                  return (
                    <List.Row
                      key={room.roomId}
                      alignItems="flex-start"
                      cursor="pointer"
                    >
                      <RoomCard
                        room={room}
                        isDM={isDMRoom({ room })}
                        automationHook={getHook(
                          automationHook,
                          `section-list_${kebabCase(sectionKey)}`
                        )}
                      />
                    </List.Row>
                  );
                })}
              </List>
            </Fragment>
          );
        })
      )}
    </>
  );
};

RoomLists.propTypes = propTypes;
export default RoomLists;
