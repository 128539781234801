import * as React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import {
  GhostIconButton,
  List,
  Popover,
  UserCard,
} from "../../../../../components";
import { memberRoles } from "../../../../../constants";
import { useMatrix } from "../../../../../network/MatrixContext";
import {
  matrixRoomPropType,
  roomMemberPropType,
} from "../../../../../propTypes";
import { getUsername } from "../../../../../utils/matrixUtils";
import useKickMutation from "../common/useKickMutation";
import useMembershipHelpers from "../common/useMembershipHelpers";
import ActionsList from "./membersList/ActionsList";
import RoleChangeModal from "./membersList/RoleChangeModal";

const propTypes = {
  room: matrixRoomPropType,
  members: PropTypes.arrayOf(roomMemberPropType),
  removeMember: PropTypes.func,
  automationHook: PropTypes.string,
};

const MembersList = ({ room, members, automationHook }) => {
  const { client } = useMatrix();
  const { isMe: checkIsMe } = useMembershipHelpers();
  const { kickMutation } = useKickMutation(room);
  const [roleChangeData, setRoleChangeData] = React.useState(null);

  return (
    <List pV="base" withSeparator>
      {members.map((member) => {
        const isMe = checkIsMe(member);
        return (
          <List.Row key={member.userId}>
            <UserCard
              name={getUsername({ userId: member.userId, client })}
              subtext={`${member.peopleRole}`}
              isSelf={isMe}
              automationHook={getHook(automationHook, `id-${member.userId}`)}
            >
              {/* Admin can only transfer his role to another room member, he can't change his own role directly
              because this means that the room will no more have an admin */}
              {!(member.peopleRole === memberRoles.ADMIN) && (
                <Popover
                  align="end"
                  w="250px"
                  triggerW="auto"
                  automationHook={getHook(
                    automationHook,
                    `member-${member.userId}_actions`
                  )}
                  renderTrigger={({ togglePopover }) => (
                    <GhostIconButton
                      name="MoreVertical"
                      iconSize="m"
                      onClick={togglePopover}
                    />
                  )}
                >
                  {({ closePopover }) => (
                    <ActionsList
                      {...{
                        room,
                        setRoleChangeData,
                        member,
                        isMe,
                        kickMutation,
                        closePopover,
                        automationHook,
                      }}
                    />
                  )}
                </Popover>
              )}
            </UserCard>
          </List.Row>
        );
      })}
      {roleChangeData && (
        <RoleChangeModal
          isOpen
          roleChangeData={roleChangeData}
          setRoleChangeData={setRoleChangeData}
        />
      )}
    </List>
  );
};
MembersList.propTypes = propTypes;

export default MembersList;
