import * as React from "react";
import { getEnv } from "@airportlabs/js-tools";
import { Loader, LoaderContainer } from "@airportlabs/smalt";
import pick from "lodash/pick";
import * as sdk from "matrix-js-sdk";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import { localStorageValues } from "../constants";
import { removeMatrixIdPrefixSuffix } from "../utils/chat-tools";
import { addGlobalDebug } from "../utils/debugUtils";
import useNotificationStore from "./matrixContext/useNotificationStore";
import useRoomStore from "./matrixContext/useRoomStore";
import useUserInfo from "./matrixContext/useUserInfo";

const propTypes = {
  children: PropTypes.node,
};

const startMatrixClient = async () => {
  // TODO: Maybe using IndexedDBStore for caching will increase performance but beware issues of cache clearing.
  // http://matrix-org.github.io/matrix-js-sdk/9.0.1/module-store_indexeddb.IndexedDBStore.html
  // const dbStore = new sdk.IndexedDBStore({
  //   indexedDB: global.indexedDB,
  //   localStorage: global.localStorage,
  //   dbName: "web-sync-store",
  // });
  // window.chatDBStore = dbStore;
  // await dbStore.startup();

  // http://matrix-org.github.io/matrix-js-sdk/9.0.1/module-client.MatrixClient.html
  try {
    const client = sdk.createClient({
      baseUrl: getEnv("REACT_APP_SERVER_URL"),
      accessToken: localStorage.getItem(localStorageValues.ACCESS_TOKEN),
      userId: localStorage.getItem(localStorageValues.USER_ID),
      // Some issues with cache clearing with the IndexedDBStore
      // store: dbStore,
      deviceId: getEnv("REACT_APP_DEVICE_ID"),
      timelineSupport: true,
      verificationMethods: ["m.sas.v1"],
    });

    await client.startClient({
      lazyLoadMembers: true,
    });

    window.parent.postMessage({ action: "clientReady" }, "*");

    return client;
  } catch (error) {
    window.parent.postMessage({ action: "loginError" }, "*");
  }
};

const MatrixContext = React.createContext({});

const useMatrix = ({ roomId } = {}) => {
  const matrixContext = React.useContext(MatrixContext);
  const room = React.useMemo(() => {
    return matrixContext.roomStore.rooms.find((room) => room.roomId === roomId);
  }, [roomId, matrixContext.roomStore.rooms]);

  const result = { ...matrixContext, room };

  // Expose globals (prefixed with "$" - window.$client, window.$room etc.) for
  // debugging purposes.
  addGlobalDebug(() =>
    pick(result, [
      "client",
      "userInfo",
      "roomStore",
      "notificationStore",
      "room",
      "isAuthenticated",
      "isSyncing",
    ])
  );

  return result;
};

const MatrixProvider = ({ children }) => {
  const [lastSyncDate, setSyncDate] = React.useState();
  const [isAuthenticated, setIsAuthenticated] = React.useReducer(
    () => true,
    !!localStorage.getItem(localStorageValues.ACCESS_TOKEN) &&
      !!localStorage.getItem(localStorageValues.USER_ID)
  );

  let { data: client } = useQuery(["matrixClient"], startMatrixClient, {
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    cacheTime: "Infinity",
    retry: false,
  });

  const { roomStore } = useRoomStore({
    client,
    lastSyncDate,
  });

  const { notificationStore } = useNotificationStore({
    client,
    lastSyncDate,
    roomStore,
  });

  const { userInfo, setUserInfo } = useUserInfo({
    client,
    roomStore,
  });

  React.useEffect(() => {
    if (!client) {
      return;
    }

    const sync = {
      NULL: () => {
        console.log("NULL state");
      },
      SYNCING: (prevState) => {
        console.log("SYNCING state");
      },
      PREPARED: (prevState) => {
        console.log("PREPARED state");
        if (prevState === null) {
          // this.roomList = new RoomList(this.client);
          // this.accountData = new AccountData(this.roomList);
          // this.roomsInput = new RoomsInput(this.client);
          // this.notifications = new Notifications(this.roomList);
          setSyncDate(new Date().getTime());
        }
      },
      RECONNECTING: () => {
        console.log("RECONNECTING state");
      },
      CATCHUP: () => {
        console.log("CATCHUP state");
      },
      ERROR: (prevState, error) => {
        console.log("ERROR state", error);
      },
      STOPPED: () => {
        console.log("STOPPED state");
      },
    };

    const onSync = (state, prevState, ...rest) => {
      return sync[state](prevState, ...rest);
    };

    const onLogout = () => {
      client.stopClient();
      client.clearStores();
      localStorage.clear();
      window.location.reload();
    };

    // https://matrix.org/docs/guides/usage-of-the-matrix-js-sdk#sync-and-listen
    // https://github.com/matrix-org/matrix-react-sdk/blob/f5f79158ed5a950626bc89439b8319e362a75de1/src/components/structures/MatrixChat.tsx#L1410
    client.on("sync", onSync);
    client.on("Session.logged_out", onLogout);

    const handleMessage = (event) => {
      // Logout action event is sent from parent app when doing logout for one
      // of its users. We wanna make sure that the chat user is also logged out
      if (event.data?.action === "logout") {
        onLogout();
      }

      if (event.data?.action === "checkUser") {
        // double check that the user who is currently accessing the chat is the
        // same as the one we think was logged in before
        // avoid manipulation of user id and info in localStorage
        if (
          event.data?.userId !==
            removeMatrixIdPrefixSuffix(
              localStorage.getItem(localStorageValues.USER_ID)
            ) ||
          event.data?.userInfo !==
            localStorage.getItem(localStorageValues.USER_INFO)
        ) {
          onLogout();
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      client.removeListener("sync", onSync);
      client.removeListener("Session.logged_out", onLogout);
      window.removeEventListener("message", handleMessage);
    };
  }, [client]);

  const contextValue = {
    client,
    userInfo,
    setUserInfo,
    roomStore,
    isSyncing:
      !lastSyncDate ||
      // Needed because HMR will switch to a null sync state and "lastSyncDate"
      // is previously set.
      !client?.getSyncState(),
    notificationStore,
    isAuthenticated,
    setIsAuthenticated,
  };

  return (
    <MatrixContext.Provider value={contextValue}>
      {/* Add all initialization checks here. */}
      {(!client || contextValue.isSyncing || !roomStore.__init) &&
      isAuthenticated ? (
        <LoaderContainer>
          <Loader />
          <div>Loading..</div>
        </LoaderContainer>
      ) : (
        children
      )}
    </MatrixContext.Provider>
  );
};
MatrixProvider.propTypes = propTypes;

export { useMatrix, MatrixContext, MatrixProvider };
