import React from "react";

import useMembers from "../../../../network/useMembers";
import { matrixRoomPropType } from "../../../../propTypes";
import { getRoomAlias, getRoomTopic } from "../../../../utils/matrixUtils";
import HeaderText from "./HeaderText";

const propTypes = {
  room: matrixRoomPropType.isRequired,
};

const GroupMessageHeaderText = ({ room }) => {
  const roomAlias = getRoomAlias(room);
  const topic = getRoomTopic(room);
  const { members } = useMembers({ room });
  return (
    <HeaderText
      text={roomAlias}
      subText={topic || `${members.length} Members`}
    />
  );
};

GroupMessageHeaderText.propTypes = propTypes;
export default GroupMessageHeaderText;
