import { Box } from "@airportlabs/smalt";
import styled, { css } from "styled-components";

const Section = styled(Box).attrs({ pH: "m" })`
  ${(props) =>
    props.hasSmoothScrolling &&
    css`
      scroll-behavior: smooth;
    `}

  ${(props) =>
    props.borderTop &&
    css`
      border-top: ${props.thickBorder ? "2px" : "1px"} solid
        ${props.theme.colors.border};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: ${props.thickBorder ? "2px" : "1px"} solid
        ${props.theme.colors.border};
    `}
`;

export default Section;
