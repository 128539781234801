import { localStorageValues } from "../constants";
import {
  decodeMatrixIdToEmail,
  displayNameStructure,
  displayNameStructureParts,
  orgNameSplitPattern,
} from "./chat-tools";

export const checkStringIsEmail = (string) =>
  string
    ? string.length >= 4 &&
      // valid email format
      /^[\w-'.]+@([\w-]+\.)+[\w-]{1,}$/.test(string)
    : false;

export const getOrgRoomFilterFn = ({ searchTerm, orgRoomMembers }) => {
  // Only the users from my organization if the search is not done by email or with the exact id searched
  return (user) =>
    !checkStringIsEmail(searchTerm) && orgRoomMembers
      ? orgRoomMembers.some((member) => member.userId === user.user_id)
      : true;
};

export const getRoomPrettyName = (roomName = "") =>
  roomName.split(orgNameSplitPattern)[0];

export const isOrganisationRoom = ({ room }) =>
  room.name.includes(`${orgNameSplitPattern}`);

export const getOrgRoomSuffix = ({ room }) => {
  const orgId = room.name.split(`${orgNameSplitPattern}`)[1];
  return orgId ? `${orgNameSplitPattern}${orgId}` : "";
};

export const getUserInfoFromDisplayName = (displayName) => {
  const localUserInfo = localStorage.getItem(localStorageValues.USER_INFO);

  const displayNameParts = (
    displayName ||
    (localUserInfo !== "undefined" && localUserInfo) ||
    ""
  ).split(displayNameStructure.separator);

  return displayNameStructure.parts.reduce(
    (acc, p, index) => ({ ...acc, [p]: displayNameParts[index] ?? "" }),
    {}
  );
};

export const getUserPrettyName = (displayName) => {
  // by default we want the name part from a given displayName
  let pretty = getUserInfoFromDisplayName(displayName).name;

  // but displayName might also be the USER_ID when the displayName is not set
  // during user creation, so we need to decode it
  // this would mean that we have only one word
  // this step just replaces the special characters and would not really affect
  // a normal user name
  if (pretty.split(" ").length === 1) {
    pretty = decodeMatrixIdToEmail(pretty);
  }

  return pretty;
};

const concatText = ({ acc, element, index, separator = " | " }) => {
  if (!element) {
    return acc;
  }
  return `${acc}${index !== 0 && acc !== "" ? separator : ""}${element}`;
};

export const getUserSearchInfo = (account) => {
  const userInfo = getUserInfoFromDisplayName(account.display_name);
  const userEmail = decodeMatrixIdToEmail(account.user_id);

  // displayName might also be the USER_ID when the displayName is not set during user creation
  const isNameEmail = account.user_id === userInfo.name;
  const name = isNameEmail ? userEmail : userInfo.name;
  const additionalInfo = [
    userInfo.job,
    isNameEmail ? undefined : userEmail,
  ].reduce((acc, element, index) => concatText({ acc, element, index }), "");

  return { name, additionalInfo };
};

export const getJobAndOrgText = (memberInfo) =>
  [displayNameStructureParts.JOB, displayNameStructureParts.ORG_NAME].reduce(
    (acc, el, index) => concatText({ acc, element: memberInfo[el], index }),
    ""
  );

export const includeOrgDisplayNameEnding = (string, orgId) =>
  `${string} ${displayNameStructure.separator} ${orgId}`;

export const checkChatRunningEnvironment = (environment) => {
  const isEnv = localStorage.getItem(environment);
  return isEnv && isEnv !== "undefined" ? JSON.parse(isEnv) : false;
};
