import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import {
  Avatar,
  Box,
  Drawer,
  DrawerMenuItem,
  Heading2,
  Section,
  Text,
} from "../../components";
import { useMatrix } from "../../network/MatrixContext";
import { decodeMatrixIdToEmail } from "../../utils/chat-tools";

const propTypes = {
  automationHook: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
const UserInfoDrawer = ({ automationHook, isOpen, onClose }) => {
  const { client, userInfo } = useMatrix();

  return (
    <Drawer.Wrapper isOpen={isOpen} onRequestClose={onClose}>
      <Drawer.Header
        onBack={onClose}
        backIcon={"ChevronDown"}
        automationHook={automationHook}
      >
        <Box gap="s" d="flex" alignItems="center" direction="column">
          <Avatar
            name={userInfo.name || client.getUserId().slice(1)}
            isUser={true}
          />
          <Heading2 fontSize="m">{userInfo.name}</Heading2>
        </Box>
        {/** Needed due to how alignment in "Drawer.Header" is done. */}
        <Box d="flex" justifyContent="flex-end" w="40px" h="40px" />
      </Drawer.Header>
      <Drawer.Content>
        <Section borderBottom pT="m" pB="base">
          <Box mB="m">
            <Text fontSize="s" color="textSecondary">
              Email Address
            </Text>
            <DrawerMenuItem
              automationHook={getHook(automationHook, "email")}
              iconName="MessageSquare"
              text={
                decodeMatrixIdToEmail(client.credentials.userId) ||
                "No email data."
              }
            />
          </Box>
          <Box>
            <Text fontSize="s" color="textSecondary">
              Job Title
            </Text>
            <DrawerMenuItem
              automationHook={getHook(automationHook, "job")}
              iconName="User"
              text={userInfo.job || "No job title data."}
              subtext={userInfo.orgName || "No organisation data."}
            />
          </Box>
        </Section>
      </Drawer.Content>
    </Drawer.Wrapper>
  );
};
UserInfoDrawer.propTypes = propTypes;

export default UserInfoDrawer;
