import { Loader } from "../components";
import { routes } from "../constants";
import { useMatrix } from "../network/MatrixContext";
import { Control } from "./cameraPage/Control";
import { HeaderContainer } from "./cameraPage/HeaderContainer";
import {
  FullScreenImagePreview,
  ImagePreview,
} from "./cameraPage/ImagePreview";
import HeaderLayoutMinimal from "./common/HeaderLayoutMinimal";
import { getHook } from "@airportlabs/automation-hooks";
import { Box, PrimaryIconButton } from "@airportlabs/smalt";
import React, { useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import { useNavigate, useParams } from "react-router-dom";

const convertUrlToFile = async (url, filename, mimeType) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

const CameraPage = (automationHook = "camera-page") => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const camera = useRef(null);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const navigate = useNavigate();

  const { client, room } = useMatrix({
    roomId: useParams().roomId,
  });

  const uploadAndSendImage = async () => {
    setIsImageLoading(true);
    const imageFile = await convertUrlToFile(
      imageUrl,
      `upload-${room.roomId}`,
      imageUrl.split(";")[0].split(":")[1]
    );

    const uploadResponse = await client.uploadContent(imageFile, {
      rawResponse: false,
      onlyContentUri: false,
    });
    const matrixUrl = uploadResponse.content_uri;
    try {
      // info, text and callback params seem to be mandatory in sendImageMessage, so we use empty values for them
      await client.sendImageMessage(room.roomId, matrixUrl, {}, "", () => {});
    } catch (e) {
      console.error(`sendImageError: ${e}`);
    }
    setIsImageLoading(false);
    navigate(`${routes.ROOMS}/${room.roomId}`);
  };

  return (
    <Box>
      <HeaderContainer>
        <HeaderLayoutMinimal
          canNavigate={!showImage}
          onBackClick={() => setShowImage(false)}
          automationHook={automationHook}
        />
      </HeaderContainer>

      {showImage ? (
        <Box>
          <FullScreenImagePreview image={imageUrl} />
          <Control>
            {/** Needed due to how alignment in "Control" is done. */}
            <div></div>
            {!isImageLoading ? (
              <PrimaryIconButton
                iconSize="xl"
                name="Check"
                color="inherit"
                w="fieldsHeight"
                onClick={uploadAndSendImage}
              />
            ) : (
              <Loader size="100px" />
            )}
            {/** Needed due to how alignment in "Control" is done. */}
            <div></div>
          </Control>
        </Box>
      ) : (
        <Box>
          <Camera
            ref={camera}
            aspectRatio="cover"
            numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
            videoSourceDeviceId={""}
            errorMessages={{
              noCameraAccessible:
                "No camera device accessible. Please connect your camera or try a different browser.",
              permissionDenied:
                "Permission denied. Please refresh and give camera permission.",
              switchCamera:
                "It is not possible to switch camera to different one because there is only one video device accessible.",
              canvas: "Canvas is not supported.",
            }}
          />
          <Control>
            <ImagePreview
              image={imageUrl}
              onClick={() => {
                setShowImage(!showImage);
              }}
              automationHook={getHook(automationHook, "preview")}
            />
            <PrimaryIconButton
              iconSize="xl"
              name="Camera"
              color="inherit"
              w="fieldsHeight"
              onClick={() => {
                if (camera.current) {
                  const photoUrl = camera.current.takePhoto();
                  setImageUrl(photoUrl);
                  setShowImage(true);
                }
              }}
              automationHook={getHook(automationHook, "take-photo")}
            />
            <PrimaryIconButton
              iconSize="xl"
              name="RotateCw"
              color="inherit"
              w="fieldsHeight"
              disabled={numberOfCameras <= 1}
              onClick={() => camera.current && camera.current.switchCamera()}
              automationHook={getHook(automationHook, "switch-camera")}
            />
          </Control>
        </Box>
      )}
    </Box>
  );
};

export default CameraPage;
