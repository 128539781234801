import { encodeStringSpecialCharactersToUnicode } from "../../utils/chat-tools";
import { checkStringIsEmail } from "../../utils/displayUtils";

export const searchType = {
  ACCOUNTS: "accounts",
  CONVERSATIONS: "conversation",
};

const searchMessageBeginningByType = {
  [searchType.ACCOUNTS]: "We couldn't find any accounts matching",
  [searchType.CONVERSATIONS]: "No ongoing conversations found matching",
};

// IMPORTANT: Values are used to generate both section title and automation
// hooks so don't change unless a QA engineer has been notified.
export const sections = {
  UNREAD: "unread_messages",
  CONVERSATIONS: "conversations",
};

export const drawerTypes = {
  NONE: "none",
  GROUP: "group",
  DM: "dm",
  INFO: "info",
};

export const getUnsuccessfulSearchMessage = (searchTerm, searchType) =>
  `${searchMessageBeginningByType[searchType]}${
    checkStringIsEmail(searchTerm)
      ? ` this email address "${searchTerm}". Please make sure that you are inputting the correct email when doing an email search.`
      : ` "${searchTerm}".`
  }`;

export const checkSearchTermIsIncluded = ({ searchTerm, room }) => {
  if (
    room.summaryHeroes &&
    room.summaryHeroes.length &&
    checkStringIsEmail(searchTerm)
  ) {
    // Exact search by email
    return (
      room.summaryHeroes[0].split(":")[0].replace("@", "") ===
      encodeStringSpecialCharactersToUnicode(searchTerm)
    );
  } else if (room.name) {
    // Search by keyword
    const searchTermWords = searchTerm.replace(/\s+/g, " ").trim().split(" ");
    return searchTermWords.some((word) =>
      room.name.toLowerCase()?.includes(word.toLowerCase())
    );
  } else {
    return false;
  }
};
