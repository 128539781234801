import * as React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import { Navigate, useParams } from "react-router-dom";

import { Box } from "../components";
import { useMatrix } from "../network/MatrixContext";
import { matrixRoomPropType } from "../propTypes";
import HeaderLayout from "./roomPage/HeaderLayout";
import MessageComposer from "./roomPage/MessageComposer";
import Timeline from "./roomPage/Timeline";

// import useScrollToBottom from "./timeline/useScrollToBottom";

const propTypes = {
  room: matrixRoomPropType,
};
Box.displayName = "Box";

const RoomPage = React.memo(() => {
  const timelineRef = React.useRef(null);
  // const { scrollToBottom, getBottomDivProps } = useScrollToBottom({
  //   events,
  //   isTimelineScrolled,
  // });

  const { roomId } = useParams();
  const { notificationStore, room } = useMatrix({
    roomId,
  });

  return room ? (
    <HeaderLayout
      automationHook="room-page"
      onBackClick={() => notificationStore.deleteRoomNotifications({ room })}
    >
      <Timeline timelineRef={timelineRef} />
      <MessageComposer
        automationHook={getHook("room-page", "message-compose")}
        timelineRef={timelineRef}
      />
    </HeaderLayout>
  ) : (
    <Navigate to="/404" replace />
  );
});
RoomPage.propTypes = propTypes;

export default RoomPage;
