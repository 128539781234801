import styled, { css } from "styled-components";

import { Box } from "./";
import { sizes } from "./Avatar";

const animationMixin = css`
  &::after {
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${(props) => props.theme.colors.transparent},
      ${(props) => props.theme.colors.backgroundSecondary},
      ${(props) => props.theme.colors.transparent}
    );
    animation: shimmer 4s infinite;
    content: "";
  }

  @keyframes shimmer {
    50% {
      transform: translateX(100%);
    }
    51% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const Rectangle = styled(Box)`
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
  height: ${(props) => props.h || sizes[props.size].size};
  width: ${(props) => props.w || sizes[props.size].size};
  border-radius: ${(props) => props.bR || sizes[props.size].borderRadius};
  position: relative;
  overflow: hidden;

  ${animationMixin}
`;

export const Line = styled(Box)`
  height: ${(props) => props.h || "15px"};
  position: relative;
  overflow: hidden;

  ${(props) => !props.even && animationMixin}
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
  border-radius: ${(props) => (props.isTagLike ? "8px" : "2px")};

  &::after {
    background-image: linear-gradient(
      90deg,
      ${(props) => props.theme.colors.transparent} 0,
      ${(props) => props.theme.colors.transparent} 30%,
      ${(props) => props.theme.colors.backgroundSecondary},
      ${(props) => props.theme.colors.transparent} 70%,
      ${(props) => props.theme.colors.transparent}
    );

    animation: shimmer 3s infinite;

    ${(props) =>
      props.delay &&
      css`
        animation-delay: ${props.delay};
      `}
  }
`;
