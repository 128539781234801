import React from "react";
import { helpers, Icon, Text as SmaltText } from "@airportlabs/smalt";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { Box } from "../../../../components";
import { childrenPropType } from "../../../../propTypes";

export const Name = styled.div`
  color: ${(props) =>
    props.isMine
      ? props.theme.colors.palette.light_4
      : props.theme.colors.avatars[props.colorIndex]};
  font-size: ${(props) => props.theme.fontSizes.s};
  font-weight: ${(props) => props.theme.fontWeights.headings};
  line-height: 1;
  margin-bottom: ${(props) => props.theme.spaces.xxs};
`;
Name.displayName = "Name";

export const Text = styled.div`
  word-break: break-word;
  font-size: ${(props) => props.theme.fontSizes.base};
  line-height: 1.5;
  /** will get some extra styles for alignment when inside is-mine bubble */
`;

export const Time = styled.div`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) =>
    props.isMine &&
    css`
      align-self: flex-end;
    `}
`;

export const Image = styled.div`
  position: relative;
  border-top-right-radius: ${(props) => props.theme.borderRadius.small};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.small};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
  margin: -4px -4px 0 -4px;
  min-height: 50px;

  img {
    width: 100%;
    height: auto;
  }

  ${Name} + & {
    margin-top: 0;
  }
`;

/** Video *********************** */
const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.75;
  background-color: rgba(0, 0, 0, 0.1);
  ${Name} + & {
    margin-top: 0;
  }
`;

export const Video = ({ children, ...rest }) => (
  <Image {...rest}>
    {children}
    <VideoOverlay>
      <Icon name="arrowRightCircle" size="xl" />
    </VideoOverlay>
  </Image>
);
Video.propTypes = {
  children: childrenPropType,
};

// GenericVideoThumbnail & VideoGeneric are temporary components to be used
// until we have a proper way to handle video thumbnails that don't autoplay on
// iOS
const GenericVideoThumbnail = styled.div`
  width: ${(props) => (props.isDrawer ? "70px" : "80px")};
  height: ${(props) => (props.isDrawer ? "45px" : "50px")};
  background-color: ${(props) => props.theme.colors.text};
  align-self: center;
  margin-bottom: ${(props) => (props.isDrawer ? "0px" : "10px")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  /* sprocket holes on the sides of the thumbnail */
  &::before,
  &::after {
    content: "";
    background-image: repeating-linear-gradient(
      0deg,
      transparent,
      transparent 2px,
      ${(props) => props.theme.colors.backgroundSecondary} 2px,
      ${(props) => props.theme.colors.backgroundSecondary} 4px
    );
    position: absolute;
    height: 70%;
    width: 3%;
    top: 15%;
  }
  &::before {
    left: 5%;
  }
  &::after {
    right: 5%;
  }
`;

const PlayButton = styled.div`
  border: 1px solid ${(props) => props.theme.colors.backgroundSecondary};
  border-radius: 50%;
  position: relative;
  width: 18px;
  height: 18px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

export const VideoGeneric = ({ children, isDrawer = false, ...rest }) => {
  return (
    <Box d="flex" direction="column" maxW="140px" {...rest}>
      <GenericVideoThumbnail isDrawer={isDrawer}>
        <PlayButton />
      </GenericVideoThumbnail>
      {!isDrawer && <SmaltText fontSize="xs">{children}</SmaltText>}
    </Box>
  );
};

VideoGeneric.propTypes = {
  children: childrenPropType,
  isDrawer: PropTypes.bool,
};

export const DocumentWrapper = styled(Box).attrs(() => ({
  d: "flex",
  p: "xs",
  alignItems: "center",
}))`
  background-color: ${(props) =>
    helpers.lightenSafe(0.2, props?.theme?.colors.primary)};
  border-radius: ${(props) => props.theme.borderRadius.small};

  ${(props) => {
    props.isMine &&
      css`
        background-color: ${helpers.darkenSafe(
          0.05,
          props?.theme?.colors.apps?.chat?.mineBackground
        )};
      `;
  }};
  ${Name} + & {
    margin-top: 0;
  }
`;

export const DocumentName = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  in-left: ${(props) => props.theme.spaces.xxs};
`;

export const Document = ({ type, isMine, children, onClick }) => {
  return (
    <DocumentWrapper type={type} isMine={isMine} onClick={onClick}>
      <Icon
        size="large"
        color={
          isMine
            ? `apps.chat.documents.${type}Inverted`
            : `apps.chat.documents.${type}`
        }
        name="NewWindow"
      />
      <DocumentName>{children}</DocumentName>
    </DocumentWrapper>
  );
};
Document.propTypes = {
  type: PropTypes.oneOf(["docx", "pdf", "text", "xls", "generic"]),
  isMine: PropTypes.bool,
  children: childrenPropType,
  onClick: PropTypes.func,
};

export const Bubble = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: ${(props) => props.theme.spaces.s}
    ${(props) => props.theme.spaces.base} ${(props) => props.theme.spaces.base}
    ${(props) => props.theme.spaces.base};
  box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.05);
  font-size: ${(props) => props.theme.fontSizes.base};
  line-height: ${(props) => props.theme.lineHeights.messages};
  max-width: 80%;
  overflow: hidden;
  word-break: break-word;

  ${(props) =>
    !props.isMine
      ? css`
          border-bottom-left-radius: 0;
        `
      : css`
          background-color: ${props.theme.colors.primary};
          border-bottom-right-radius: 0;
          align-self: flex-end;
          color: white;
        `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spaces.xs};

  ${Bubble} {
    margin-bottom: ${(props) => props.theme.spaces.xxs};
  }
`;

/** Seen ********************************************************* */

const SeenText = styled.div`
  text-align: 'right'};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  color: ${(props) => props.theme.colors.textSecondary};
  align-self: ${(props) => (props.isMine ? "flex-end" : "flex-start")};
  padding: ${(props) => props.theme.spaces.xxs} ${(props) =>
  props.theme.spaces.xs};
  letter-spacing: -0.3px;
  strong {
    color: ${(props) => props.theme.colors.textSecondary};
  }

`;

export const Seen = ({ by, isMine }) => (
  <SeenText isMine={isMine}>
    {by ? (
      <>
        Seen by <strong>{by}</strong>
      </>
    ) : (
      "Seen"
    )}
  </SeenText>
);
Seen.propTypes = {
  by: PropTypes.string,
  isMine: PropTypes.bool,
};

/** Deleted ********************************************************* */

const DeletedText = styled.div`
  opacity: 0.7;
  font-size: ${(props) => props.theme.fontSizes.small};
  display: flex;
  align-items: center;

  span {
    font-style: italic;
    margin-left: ${(props) => props.theme.spaces.xxs};
  }
`;

export const Deleted = () => (
  <DeletedText>
    <Icon name="deleted" size="xxsmall" />
    <span>This message was deleted</span>
  </DeletedText>
);

export const Separator = styled.div`
  width: 100%;
  text-align: center;
  padding: ${(props) => props.theme.spaces.xxs};
  color: ${(props) => props.theme.colors.textSecondary};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  margin-top: ${(props) => props.theme.spaces.base};
`;
