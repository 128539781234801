import { useCallback, useEffect } from "react";
import { getHook } from "@airportlabs/automation-hooks";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import { GhostIconButton } from "../../components";
import { relativeRoutes, routes } from "../../constants";

const BackButton = ({
  roomId,
  onClick = identity,
  canNavigate,
  automationHook,
}) => {
  const navigate = useNavigate();
  const { isAttachmentDrawer } = useParams();

  // we navigate back to room's page, or the attachments within the drawer which are on different routes
  const onBackClick = useCallback(() => {
    onClick();
    const backRoute = `${routes.ROOMS}/${roomId}`;
    canNavigate &&
      navigate(
        isAttachmentDrawer
          ? `${backRoute}/${relativeRoutes.SETTINGS}/${relativeRoutes.ATTACHMENTS}`
          : backRoute,
        {
          state: {
            showDrawer: isAttachmentDrawer ? true : false,
          },
        }
      );
  }, [canNavigate, navigate, onClick, roomId, isAttachmentDrawer]);

  // Navigate back when android phone back button is pressed
  const handleMessage = useCallback(
    (event) => {
      if (event.data.action === "backButtonEvent") {
        onBackClick();
      }
    },
    [onBackClick]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  });

  return (
    <GhostIconButton
      iconSize="l"
      name="ChevronLeft"
      color="inherit"
      w="auto"
      mR="base"
      automationHook={getHook(automationHook, "back")}
      onClick={onBackClick}
    />
  );
};
BackButton.propTypes = {
  roomId: PropTypes.string,
  onClick: PropTypes.func,
  canNavigate: PropTypes.bool,
  automationHook: PropTypes.string,
};

export default BackButton;
