import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DrawerMenuItem, Section } from "../../../../components";
import useMembers from "../../../../network/useMembers";
import useRoomPermissions from "../../../../network/useRoomPermissions";
import { matrixRoomPropType } from "../../../../propTypes";
import useParentPropagation from "./common/useParentPropagation";
import MembersList from "./members/MembersList";

const propTypes = {
  title: PropTypes.string.isRequired,
  room: matrixRoomPropType,
  routeToAdd: PropTypes.string,
  automationHook: PropTypes.string,
};

const Members = ({ room, title, routeToAdd, automationHook }) => {
  useParentPropagation({ title });
  const { members } = useMembers({ room });
  const { iCan } = useRoomPermissions({ room });

  return (
    <>
      {iCan?.invite && (
        <Section borderBottom pV="base">
          <DrawerMenuItem
            automationHook={getHook(automationHook, "add")}
            iconName="PlusSquare"
            iconSize="l"
            fontSize="m"
            text="Add Members"
            as={Link}
            to={routeToAdd}
          />
        </Section>
      )}
      <Section>
        <MembersList
          room={room}
          members={members}
          automationHook={automationHook}
        />
      </Section>
    </>
  );
};
Members.propTypes = propTypes;

export default Members;
