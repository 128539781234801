import * as React from "react";
import { useEffect } from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { relativeRoutes } from "../../../constants";
import { matrixRoomPropType } from "../../../propTypes";
import { getRoomAlias, isDMRoom } from "../../../utils/matrixUtils";
import AddMembers from "./roomMetaDrawer/AddMembers";
import Appearance from "./roomMetaDrawer/Appearance";
import Attachments from "./roomMetaDrawer/Attachments";
import DrawerInner from "./roomMetaDrawer/DrawerInner";
import Members from "./roomMetaDrawer/Members";
import Root from "./roomMetaDrawer/Root";

const propTypes = {
  room: matrixRoomPropType,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  automationHook: PropTypes.string,
};

const RoomMetaDrawer = ({ room, onClose, isOpen, automationHook }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDM = isDMRoom({ room });
  const navigateBackOneSegment = () => {
    navigate(location.pathname.split("/").slice(0, -1).join("/"));
  };

  // This function is used to navigate inside RoomMetaDrawer when
  // android phone back button is pressed (event?.data?.action === "backButtonEvent")
  const handleMessage = (event) => {
    if (event?.data?.action !== "backButtonEvent") {
      return;
    }
    event.preventDefault();
    navigateBackOneSegment();
    return;
  };
  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.addEventListener("message", handleMessage);
  });

  return (
    <Routes>
      <Route
        path={relativeRoutes.SETTINGS}
        element={
          <DrawerInner
            room={room}
            isOpen={isOpen}
            onClose={onClose}
            onBack={navigateBackOneSegment}
            automationHook={automationHook}
          />
        }
      >
        <Route
          index
          element={
            <Root
              title={isDM ? getRoomAlias(room) : "Group Info"}
              {...(!isDM && {
                button: {
                  automationHook: getHook(automationHook, "save-group-details"),
                },
              })}
              room={room}
              automationHook={getHook(automationHook)}
            />
          }
        />
        {!isDM && (
          <Route
            path={relativeRoutes.MEMBERS}
            element={
              <Members
                title="Members"
                room={room}
                automationHook={getHook(automationHook, "members")}
                routeToAdd={relativeRoutes.MEMBERS_ADD}
              />
            }
          />
        )}
        <Route
          path={`${relativeRoutes.MEMBERS}/${relativeRoutes.MEMBERS_ADD}`}
          element={
            <AddMembers
              title="Add Members"
              button={{
                callback: navigateBackOneSegment,
                automationHook: getHook(automationHook, "save-members"),
              }}
              room={room}
              automationHook={getHook(automationHook, "add-members")}
            />
          }
        />
        <Route
          path={relativeRoutes.ATTACHMENTS}
          element={<Attachments title="Attachments" room={room} />}
        />
        <Route
          path={relativeRoutes.APPEARANCE}
          element={<Appearance title="Appearance" />}
        />
      </Route>
    </Routes>
  );
};
RoomMetaDrawer.propTypes = propTypes;

export default RoomMetaDrawer;
