import * as React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import { Outlet, useLocation } from "react-router-dom";

import {
  Box,
  ButtonWithLoader,
  Drawer,
  Heading2,
  Text,
} from "../../../../components";
import { relativeRoutes, routes } from "../../../../constants";
import { matrixRoomPropType } from "../../../../propTypes";
import RoomAvatar from "../roomHeader/RoomAvatar";

const propTypes = {
  automationHook: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  room: matrixRoomPropType,
  isOpen: PropTypes.bool,
};

const DrawerInner = ({ automationHook, onBack, onClose, room, isOpen }) => {
  const [title, setTitle] = React.useState("");
  const [button, setButton] = React.useState();
  const location = useLocation();
  const isAtMainMenu =
    location.pathname ===
    `${routes.ROOMS}/${room.roomId}/${relativeRoutes.SETTINGS}`;

  return (
    <Drawer.Wrapper
      isOpen={isOpen}
      onRequestClose={onClose}
      backIcon={isAtMainMenu ? "ChevronDown" : "ChevronLeft"}
      automationHook={automationHook}
    >
      <Drawer.Header
        pB="m"
        onBack={isAtMainMenu ? onClose : onBack}
        backIcon={isAtMainMenu ? "ChevronDown" : "ChevronLeft"}
        automationHook={automationHook}
      >
        <Box gap="s" d="flex" alignItems="center" direction="column">
          <RoomAvatar room={room} />
          <Heading2 fontSize="m">{title}</Heading2>
        </Box>
        {button ? (
          <Box
            // Using height because it needs to have the same height as the
            // Loader
            h="40px"
            w="50px"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            {button.render ? (
              button.render()
            ) : (
              <ButtonWithLoader
                onClick={button.callback}
                disabled={button.isDisabled}
                showLoader={button.isLoading}
                buttonStyle="ghost"
                automationHook={
                  button.automationHook ||
                  getHook(automationHook, "button-right")
                }
              >
                <Text fontSize="m">{button.label || "Save"}</Text>
              </ButtonWithLoader>
            )}
          </Box>
        ) : (
          <Box d="flex" justifyContent="flex-end" w="50px" h="40px" />
        )}
      </Drawer.Header>

      <Drawer.Content bB pB="none" pL="none" pR="none">
        <Outlet context={{ setTitle, setButton }} />
      </Drawer.Content>
    </Drawer.Wrapper>
  );
};
DrawerInner.propTypes = propTypes;

export default DrawerInner;
