import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

// To be used in a Route Outlet component.
// Saves title and button in the parent Route state using OutletContext.
const useParentPropagation = ({ title, button }) => {
  const { setButton, setTitle } = useOutletContext();

  useEffect(() => {
    setTitle(title);
    setButton(button);
  }, [button, setButton, setTitle, title]);
};

export default useParentPropagation;
