import PropTypes from "prop-types";
import invariant from "invariant";
import styled, { css, useTheme } from "styled-components";
import { hook } from "@airportlabs/automation-hooks";

import useColorIndex from "../hooks/useColorIndex";
import { Icon, Text, helpers, mixins } from "./";

export const sizes = {
  xsmall: {
    size: "24px",
    borderRadius: "6px",
  },
  small: {
    size: "32px",
    borderRadius: "10px",
  },
  medium: {
    size: "42px",
    borderRadius: "12px",
  },
  large: {
    size: "72px",
    borderRadius: "20px",
  },
};

const propTypes = {
  // You can either provide the avatar image url or...
  imageSource: PropTypes.string,
  // The name of user the avatar will be generated for.
  name: PropTypes.string.isRequired,
  // Wether this a user or a group avatar
  isUser: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizes)),
  automationHook: PropTypes.string,
};

const EmptyAvatar = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: ${(props) => sizes[props.size].size};
  height: ${(props) => sizes[props.size].size};
  border-radius: ${(props) => sizes[props.size].borderRadius};
  overflow: hidden;
  background-color: ${(props) => helpers.lightenSafe(0.3, props.color)};
  color: ${(props) => props.color};

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${(props) => mixins.marginMixin(props, false)};
`;

const Image = styled.img`
  width: ${(props) => sizes[props.size].size};
  height: ${(props) => sizes[props.size].size};
  border-radius: ${(props) => sizes[props.size].borderRadius};
  object-fit: cover;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${(props) => mixins.marginMixin(props, false)}
`;

const Avatar = ({
  size = "medium",
  name,
  imageSource,
  isUser,
  automationHook,
  ...rest
}) => {
  invariant(
    name || imageSource,
    "You must provide either `name` or `imageSource` to `Avatar`."
  );
  const theme = useTheme();

  const colorIndex = useColorIndex(name);

  if (imageSource) {
    return (
      <Image
        size={size}
        src={imageSource}
        alt={`${name}'s avatar`}
        {...hook(automationHook, "avatar-image")}
        {...rest}
      />
    );
  }

  return (
    <EmptyAvatar
      size={size}
      color={theme.colors.avatars[colorIndex]}
      {...hook(automationHook, "avatar-no-image")}
      {...rest}
    >
      {!isUser ? (
        <Icon name="UsersDuo" size="m" />
      ) : (
        <Text fontSize="m" fontWeight="semibold">
          {name
            ?.replace(/\W/g, "")
            .split(" ")
            .map((w) => w[0])
            .join("")
            .toUpperCase()}
        </Text>
      )}
    </EmptyAvatar>
  );
};
Avatar.propTypes = propTypes;

export default Avatar;
