import { useTheme } from "styled-components";

const useColorIndex = (name) => {
  const theme = useTheme();

  let sum = 0;
  for (const index in name) {
    sum += name.charCodeAt(index);
  }
  return sum % theme.colors.avatars.length;
};

export default useColorIndex;
