import React from "react";
import PropTypes from "prop-types";

import { Box, HeaderWrapper } from "../../../components";
import { matrixRoomPropType } from "../../../propTypes";
import BackButton from "./roomHeader/BackButton";
import DirectMessageHeaderText from "./roomHeader/DirectMessageHeaderText";
import GroupMessageHeaderText from "./roomHeader/GroupMessageHeaderText";
import RoomAvatar from "./roomHeader/RoomAvatar";

const propTypes = {
  isDM: PropTypes.bool,
  onBackClick: PropTypes.func,
  openDrawer: PropTypes.func,
  room: matrixRoomPropType.isRequired,
  automationHook: PropTypes.string,
};

const RoomHeader = ({
  isDM,
  room,
  onBackClick,
  openDrawer,
  automationHook,
}) => {
  const Component = isDM ? DirectMessageHeaderText : GroupMessageHeaderText;
  return (
    <HeaderWrapper alignItems="center" gap="m" w="100%">
      <Box d="flex" direction="row" alignItems="center" overflow="hidden">
        <BackButton onClick={onBackClick} automationHook={automationHook} />
        <Component room={room} />
      </Box>
      <RoomAvatar
        room={room}
        onClick={openDrawer}
        automationHook={automationHook}
      />
    </HeaderWrapper>
  );
};

RoomHeader.propTypes = propTypes;
export default RoomHeader;
