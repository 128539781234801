import * as React from "react";
import PropTypes from "prop-types";

import { MembersSelector } from "../../../../components";
import useInviteMembers from "../../../../network/useInviteMembers";
import useMembers from "../../../../network/useMembers";
import { matrixRoomPropType } from "../../../../propTypes";
import useParentPropagation from "./common/useParentPropagation";

const propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.object,
  room: matrixRoomPropType,
  automationHook: PropTypes.string,
};

const AddMembers = ({ room, title, button, automationHook }) => {
  const { members } = useMembers({ room });
  const [newMembers, setNewMembers] = React.useState([]);

  const { inviteMembersAsync, ...inviteMutation } = useInviteMembers({
    room,
    onSuccess: ({ data }) => {
      button.callback();
    },
  });

  useParentPropagation({
    title,
    button: React.useMemo(
      () => ({
        ...button,
        isLoading: inviteMutation.isLoading,
        isDisabled: newMembers.length === 0,
        callback: async () => {
          try {
            await inviteMembersAsync({
              userIds: newMembers.map((account) => account.user_id),
            });
          } catch (e) {
            console.error(e);
          }
        },
      }),
      [button, inviteMembersAsync, inviteMutation.isLoading, newMembers]
    ),
  });

  return (
    <MembersSelector
      members={newMembers}
      setSelectedMembers={setNewMembers}
      filterSearchFn={(user) => !members.find((m) => m.userId === user.user_id)}
      automationHook={automationHook}
    />
  );
};
AddMembers.propTypes = propTypes;

export default AddMembers;
