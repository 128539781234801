import { Text } from "@airportlabs/smalt";
import styled from "styled-components";

const TimelineText = styled(Text).attrs((props) => ({
  color: props.color || "textSecondary",
}))`
  width: 100%;
  word-break: break-all;
`;

export default TimelineText;
