import { Section } from ".";
import { childrenPropType } from "../propTypes";

const HeaderWrapper = ({ children, ...other }) => {
  return (
    <Section
      d="flex"
      direction="row"
      justifyContent="space-between"
      borderBottom
      pV="base"
      {...other}
    >
      {children}
    </Section>
  );
};
HeaderWrapper.propTypes = { children: childrenPropType };

export default HeaderWrapper;
