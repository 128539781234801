import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Box, Heading1, Heading2, PrimaryButton } from "../components";
import { routes } from "../constants";
import HeaderLayout from "./roomListPage/HeaderLayout";
import { drawerTypes } from "./roomListPage/helpers";
import UserInfoDrawer from "./roomListPage/UserInfoDrawer";

const propTypes = {
  automationHook: PropTypes.string,
};

const PageNotFound = ({ automationHook = "page-not-found" }) => {
  const [shownDrawer, setShownDrawer] = useState(drawerTypes.NONE);

  const navigate = useNavigate();
  // Navigate on Room List page or close user info drawer when
  // android phone back button is pressed (event?.data?.action === "backButtonEvent")
  const handleMessage = (event) => {
    if (event?.data?.action !== "backButtonEvent") {
      return;
    }
    if (shownDrawer !== drawerTypes.NONE) {
      setShownDrawer(drawerTypes.NONE);
      return;
    }
    navigate(routes.ROOMS);
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  });

  return (
    <HeaderLayout
      automationHook={automationHook}
      setShownDrawer={setShownDrawer}
      drawerTypes={drawerTypes}
    >
      <Box
        d="flex"
        direction="column"
        alignItems="center"
        justifyContent="center"
        pH="m"
        h="100%"
      >
        <Heading1 mB="xxs">404</Heading1>
        <Heading2 color="textSecondary" textAlign="center" mB="s">
          We're sorry, we can't find the page you're looking for.
        </Heading2>
        <PrimaryButton
          onClick={() => navigate(routes.ROOMS)}
          automationHook={automationHook}
        >
          Go to the Room List page
        </PrimaryButton>
      </Box>
      <UserInfoDrawer
        automationHook={automationHook}
        isOpen={shownDrawer === drawerTypes.INFO}
        onClose={() => setShownDrawer(drawerTypes.NONE)}
      />
    </HeaderLayout>
  );
};

PageNotFound.propTypes = propTypes;
export default PageNotFound;
