// Based on https://github.com/cinnyapp/cinny/blob/dev/src/client/state/RoomTimeline.js
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { routes } from "../../../constants";
import { useMatrix } from "../../../network/MatrixContext";
import {
  getSupportedEvents,
  isSupportedEvent,
  iterateLinkedTimelines,
} from "../../../utils/matrixUtils";
import usePaginate from "./usePaginate";
import useTimelineScroll from "./useTimelineScroll";

const useSupportedEventsState = (defaultState = []) => {
  const { client } = useMatrix();
  const [events, updateEvents] = React.useState(defaultState);

  const updateSupportedEvents = React.useCallback(
    (events) => {
      const supportedEvents = getSupportedEvents({ mEvents: events, client });
      updateEvents(supportedEvents);
    },
    [client]
  );

  return [events, updateSupportedEvents];
};

const useTimeline = ({ room, timelineRef }) => {
  const { client } = useMatrix();
  const liveTimeline = React.useMemo(() => room.getLiveTimeline(), [room]);
  const [events, updateEvents] = useSupportedEventsState([]);
  const unreadRef = React.useRef();
  const [typingMembersIds, updateTypingMembersIds] = React.useState([]);
  const navigate = useNavigate();

  const { scrollToLatest, scrollToUnread, shouldShowJumpToButton } =
    useTimelineScroll({
      room,
      events,
      timelineRef,
      unreadRef,
    });

  // We want the events to update when the room changes
  React.useEffect(() => {
    updateEvents([...liveTimeline.getEvents()]);
  }, [liveTimeline, updateEvents]);

  const { paginate, isPaginationOngoing } = usePaginate({
    room,
    timelineRef,
    onSuccess: () => {
      iterateLinkedTimelines(liveTimeline, (iteratedTimeline) => {
        updateEvents([...iteratedTimeline.getEvents()]);
      });
    },
  });

  // Setup of Timeline Events
  React.useEffect(() => {
    // https://github.com/cinnyapp/cinny/blob/dev/src/client/state/RoomTimeline.js#L314-L341
    const onRoomTimeline = (
      mEvent,
      eventRoom,
      toStartOfTimeline,
      removed,
      data
    ) => {
      if (
        eventRoom.roomId !== room.roomId ||
        // isPaginationOngoing ||
        // We only process live events
        !data.liveEvent ||
        !isSupportedEvent({ mEvent, client })
      ) {
        return;
      }

      //If user leave the room as a result of kick or room delete action
      //we want to redirect him to room list page with a leave reason that will be displayed
      if (
        mEvent.getStateKey() === client.getUserId() &&
        mEvent.getContent().membership === "leave"
      ) {
        navigate(routes.ROOMS, {
          state: {
            leaveNotificationMessage: mEvent.getContent().reason,
          },
        });
        return;
      }

      updateEvents([...events, mEvent]);
    };
    client.on("Room.timeline", onRoomTimeline);

    // https://github.com/cinnyapp/cinny/blob/dev/src/client/state/RoomTimeline.js#L366-L373
    const onRoomMemberTyping = (mEvent, member) => {
      if (member.roomId !== room.roomId) {
        return;
      }

      updateTypingMembersIds((typingMembersIds) =>
        member.typing
          ? [...typingMembersIds, member.userId]
          : typingMembersIds.filter((usedId) => usedId !== member.userId)
      );
    };
    client.on("RoomMember.typing", onRoomMemberTyping);

    // https://github.com/cinnyapp/cinny/blob/dev/src/client/state/RoomTimeline.js#L374-L388
    // const onRoomReceipt = (mEvent, eventRoom) => {
    //   if (eventRoom.roomId !== room.roomId) {
    //     return;
    //   }
    //   // ...
    // };
    // client.on("Room.receipt", onRoomReceipt);

    return () => {
      client.removeListener("Room.timeline", onRoomTimeline);
      client.removeListener("RoomMember.typing", onRoomMemberTyping);
      // client.removeListener("Room.receipt", onRoomReceipt);
    };
  }, [
    client,
    events,
    isPaginationOngoing,
    room.roomId,
    updateEvents,
    navigate,
  ]);

  return {
    unreadRef,
    scrollToUnread,
    scrollToLatest,
    shouldShowJumpToButton,
    paginate,
    events,
    isPaginationOngoing,
    liveTimeline,
    typingMembersIds,
  };
};

export default useTimeline;
