import PropTypes from "prop-types";

import { GhostButton, Icon, Text } from "./";
import { childrenPropType } from "../propTypes";

const propTypes = {
  // Icon name
  icon: PropTypes.string,
  isCompact: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: childrenPropType,
  automationHook: PropTypes.string,
};

const GhostButtonWithIcon = ({
  onClick,
  icon,
  children,
  automationHook,
  ...rest
}) => {
  return (
    <GhostButton
      w="100%"
      alignItems="center"
      justifyContent="flex-start"
      onClick={onClick}
      automationHook={automationHook}
      {...rest}
    >
      <Icon name={icon} size="l" />
      <Text mL="xs" fontSize="m">
        {children}
      </Text>
    </GhostButton>
  );
};
GhostButtonWithIcon.propTypes = propTypes;

export default GhostButtonWithIcon;
