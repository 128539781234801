import { useMutation } from "react-query";

import { useMatrix } from "../../../../../network/MatrixContext";

const useSetPowerLevel = () => {
  const { client } = useMatrix();

  const { mutate, mutateAsync, ...setPowerLevelMutationRest } = useMutation({
    mutationFn: ({ roomId, userId, powerLevel, powerLevelEvent }) => {
      return client.setPowerLevel(roomId, userId, powerLevel, powerLevelEvent);
    },
    onSuccess: (data) => {
      console.log("Success role change for the user", data);
    },
    onError: (err) => {
      console.log("Error on role change for the user", err);
    },
  });
  return {
    setPowerLevelMutation: mutate,
    setPowerLevelMutationAsync: mutateAsync,
    ...setPowerLevelMutationRest,
  };
};

export default useSetPowerLevel;
