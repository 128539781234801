import { Fragment, memo, useEffect } from "react";
import { hook } from "@airportlabs/automation-hooks";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import {
  Box,
  ChatBadge,
  Section,
  Tag,
  UnreadIndicator,
} from "../../components";
import { eventTypes } from "../../constants";
import { useMatrix } from "../../network/MatrixContext";
import { matrixRoomPropType } from "../../propTypes";
import Event from "./timeline/Event";
import JumpToButton from "./timeline/JumpToButton";
import useEventsGroupedByDay from "./timeline/useEventsGroupedByDay";
import useTimeline from "./timeline/useTimeline";
import useUnreadIndicator from "./timeline/useUnreadIndicator";

const propTypes = {
  room: matrixRoomPropType,
  timelineRef: PropTypes.shape({ current: PropTypes.any }),
};

const Timeline = memo(({ timelineRef }) => {
  const { roomId } = useParams();
  const { room, notificationStore } = useMatrix({ roomId });

  const {
    unreadRef,
    scrollToLatest,
    shouldShowJumpToButton,
    paginate,
    events,
    isPaginationOngoing,
  } = useTimeline({
    room,
    timelineRef,
  });

  const { shouldShowUnreadIndicatorFor, shouldShowUnreadIndicator } =
    useUnreadIndicator({
      room,
      timelineRef,
    });

  const { deleteRoomNotifications } = notificationStore;
  useEffect(() => {
    // We want to clear clear notifications if doesn't need to see the new
    // indicator. If we wouldn't do this, when the user scrolls up, the new
    // indicator would be shown for seen events.
    if (!shouldShowUnreadIndicator) {
      deleteRoomNotifications({ room });
    }

    // If the user changes the room and comes back we want him to only see
    // notifications for new events.
  }, [room, deleteRoomNotifications, shouldShowUnreadIndicator, timelineRef]);

  const eventsByDay = useEventsGroupedByDay(events);

  // https://github.com/matrix-org/matrix-react-sdk/blob/0bc2f1c101d0202c465acd76c2b2d9d9c9af3092/src/components/structures/MessagePanel.tsx
  return (
    <Section
      backgroundColor="backgroundSecondary"
      w="100%"
      d="flex"
      direction="column-reverse"
      overflowY="auto"
      flex="1 1 auto"
      onScroll={paginate}
      ref={timelineRef}
    >
      <Box position="relative">
        {isPaginationOngoing && <Event.Skeleton />}
        {map(eventsByDay, (dayEvents, day) => {
          const dayAsDate = new Date(day);
          const timestamp = dayAsDate.getTime();

          const shouldShowYear =
            dayAsDate.getFullYear() !== new Date().getFullYear();

          const formattedDay = dayAsDate.toLocaleDateString("en-US", {
            month: "long",
            day: "2-digit",
            weekday: "long",
            year: shouldShowYear ? "numeric" : undefined,
          });

          return (
            <Fragment key={timestamp}>
              {dayEvents.map((event, index) => {
                return (
                  <Fragment key={event?.event?.event_id}>
                    {shouldShowUnreadIndicatorFor(dayEvents[index]) && (
                      <UnreadIndicator ref={unreadRef} />
                    )}
                    {!index && event.event.type !== eventTypes.room.CREATE && (
                      <ChatBadge>{formattedDay}</ChatBadge>
                    )}
                    <Event
                      room={room}
                      mEvent={event}
                      mEvents={dayEvents}
                      index={index}
                    />
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}

        <JumpToButton
          isVisible={shouldShowJumpToButton}
          onClick={scrollToLatest}
          {...hook("jump-to-latest-message")}
        >
          <Tag.Icon name="MessageSquare" />
          <Tag.Text>Jump to latest</Tag.Text>
        </JumpToButton>
      </Box>
    </Section>
  );
});
Timeline.propTypes = propTypes;

export default Timeline;
