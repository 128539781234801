import React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { Avatar } from "../../../../components";
import { matrixRoomPropType } from "../../../../propTypes";
import { getRoomAlias, isDMRoom } from "../../../../utils/matrixUtils";

const propTypes = {
  room: matrixRoomPropType.isRequired,
  automationHook: PropTypes.string,
};

const RoomAvatar = ({ room, automationHook, ...rest }) => {
  const isDM = isDMRoom({ room });
  // TODO: when when we implement avatar image (url) if it's a DM we should also
  // get the user and get the user profile

  return (
    <Avatar
      name={getRoomAlias(room)}
      isUser={isDM}
      automationHook={getHook(automationHook, "header")}
      {...rest}
    />
  );
};

RoomAvatar.propTypes = propTypes;
export default RoomAvatar;
