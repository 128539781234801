import { useCallback } from "react";

import { useMatrix } from "../../../../../network/MatrixContext";

const useMembershipHelpers = () => {
  const { client } = useMatrix();

  const isMe = useCallback(
    (member) => (member.userId || member.user_id) === client.getUserId(),
    // eslint-disable-next-line
    [client.getUserId]
  );

  return { isMe };
};

export default useMembershipHelpers;
