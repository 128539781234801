import * as React from "react";
import { hook } from "@airportlabs/automation-hooks";
import GroupFormInputs, { fields } from "components/GroupFormInputs";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { MembersSelector, Section, Text } from "../../components";
import useCreateRoom from "../../network/useCreateRoom";
import { accountPropType } from "../../propTypes";

const propTypes = {
  // The step number in the creation process. Will determine which view to show.
  step: PropTypes.number,
};

const GroupDetails = ({
  automationHook,
  members,
  depressSubmit,
  isSubmitPressed,
  setIsSubmitting,
}) => {
  const { handleSubmit, control } = useForm();

  const { createRoom, ...createRoomMutation } = useCreateRoom();

  React.useEffect(() => {
    setIsSubmitting(createRoomMutation.isLoading);
  }, [createRoomMutation.isLoading, setIsSubmitting]);

  // The submit button is in the parent so we need to submit with useEffect
  React.useEffect(() => {
    if (!isSubmitPressed || createRoomMutation.isLoading) {
      return;
    }

    const createGroup = (data) => {
      createRoom({
        name: data[fields.NAME],
        topic: data[fields.TOPIC],
        userIds: members.map((m) => m.user_id),
      });
    };

    handleSubmit(createGroup)();
    depressSubmit();
  }, [
    createRoom,
    createRoomMutation.isLoading,
    depressSubmit,
    handleSubmit,
    isSubmitPressed,
    members,
  ]);

  return (
    <form>
      <Section>
        <Text
          color="text"
          mT="s"
          mB="base"
          {...hook(automationHook, "no-chats-message")}
        >
          In order to create a group, you need to name the group e.g: Flight
          Team EZY.
        </Text>
        <GroupFormInputs {...{ control, automationHook }} />
      </Section>
    </form>
  );
};
GroupDetails.propTypes = {
  automationHook: PropTypes.string,
  members: PropTypes.arrayOf(accountPropType),
  // Function that needs to be executed after the handleSubmit so that the
  // Create button can be pressed again if handleSubmit resulted in errors
  depressSubmit: PropTypes.func,
  // Whether the Create button has been pressed
  isSubmitPressed: PropTypes.bool,
  // Function that sets wether the creation is in flight
  setIsSubmitting: PropTypes.func,
};

const CreateGroup = ({ step, ...rest }) => {
  const [members, setMembers] = React.useState([]);

  return step === 1 ? (
    <MembersSelector
      members={members}
      setSelectedMembers={setMembers}
      {...rest}
    />
  ) : (
    <GroupDetails members={members} {...rest} />
  );
};

CreateGroup.propTypes = propTypes;

export default CreateGroup;
