import { useMemo } from "react";
import GroupFormInputs, { fields } from "components/GroupFormInputs";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import useRoomPermissions from "../../../../../network/useRoomPermissions";
import useUpdateRoom from "../../../../../network/useUpdateRoom";
import { matrixRoomPropType } from "../../../../../propTypes";
import {
  getOrgRoomSuffix,
  getRoomPrettyName,
} from "../../../../../utils/displayUtils";
import { getRoomTopic } from "../../../../../utils/matrixUtils";
import useParentPropagation from "../common/useParentPropagation";

const propTypes = {
  // "button" and "title" are set in the DrawerInner component
  button: PropTypes.shape({
    callback: PropTypes.func,
    label: PropTypes.string,
    automationHook: PropTypes.string,
  }),
  title: PropTypes.string,
  room: matrixRoomPropType,
  automationHook: PropTypes.string,
};

const GroupDetailsForm = ({ title, button, room, automationHook }) => {
  const {
    iCan: { renameRoom: canChangeName, changeTopic: canChangeTopic },
  } = useRoomPermissions({ room });
  const canEditGroupDetails = canChangeName || canChangeTopic;

  const orgRoomSuffix = getOrgRoomSuffix({ room });

  const { updateRoomAsync, ...updateMutation } = useUpdateRoom();
  const { handleSubmit, control, formState, reset } = useForm({
    defaultValues: {
      // If we're dealing with an org room we need to display the name properly
      [fields.NAME]: getRoomPrettyName(room.name),
      [fields.TOPIC]: getRoomTopic(room) || "",
    },
    mode: "onChange",
  });

  //Calculate this here to be in sync with formState
  const isButtonDisabled = !formState.isDirty || !isEmpty(formState.errors);

  const groupDetailsButton = useMemo(
    () => ({
      button: {
        ...button,
        isLoading: updateMutation.isLoading,
        isDisabled: isButtonDisabled,
        callback: () => {
          handleSubmit(async (data) => {
            try {
              await updateRoomAsync({
                roomId: room.roomId,
                name: `${data[fields.NAME]}${orgRoomSuffix}`,
                topic: data[fields.TOPIC],
              });
              //After successful submit formState.isDirty will be set to false and
              //input fields will keep their values, this way the edit group flow is reset
              reset(
                {
                  [fields.NAME]: data[fields.NAME],
                  [fields.TOPIC]: data[fields.TOPIC],
                },
                { isDirty: false }
              );
            } catch (e) {
              console.error(e);
            }
          })();
        },
      },
    }),
    [
      button,
      isButtonDisabled,
      updateRoomAsync,
      orgRoomSuffix,
      room.roomId,
      handleSubmit,
      updateMutation.isLoading,
      reset,
    ]
  );

  useParentPropagation({
    title,
    ...(canEditGroupDetails ? groupDetailsButton : {}),
  });

  return (
    <form>
      <GroupFormInputs
        {...{
          canChangeName,
          canChangeTopic,
          control,
          automationHook,
        }}
      />
    </form>
  );
};

GroupDetailsForm.propTypes = propTypes;
export default GroupDetailsForm;
