// Parts inspired from on cinny/src/client/state/Notifications.js
import * as React from "react";
import invariant from "invariant";
import transform from "lodash/transform";

import { eventTypes } from "../../constants";
import { getRoomUnreadMeta } from "../../utils/matrixUtils";

const isNotificationEvent = (mEvent) => {
  return (
    // Only include notifications for supported event types
    Object.values(eventTypes.room)
      .filter((e) => e !== "m.room.member")
      .includes(mEvent.getType()) &&
    // Ignore redactions
    !mEvent.isRedacted()
  );
};

// Policies is what we should call different notification settings
// const getNotificationsPolicy = (roomId, client) => {
//   const pushRule = client.getAccountData("push_rules")?.getContent()
//     ?.global?.override;

//   // Here code to check if room is muted or has any other notification settings
// };

const actions = {
  UPDATE_ROOM_NOTIFICATIONS: "update-room-notifications",
  UPDATE_ROOMS_NOTIFICATIONS: "update-rooms-notifications",
  SUBTRACT_FROM_ROOM_NOTIFICATIONS: "subtract-from-room-notifications",
  DELETE_ROOM_NOTIFICATIONS: "delete-room-notifications",
};

const setEventListeners = (client, dispatch) => {
  const onRoomTimeline = (mEvent, room) => {
    if (!isNotificationEvent(mEvent)) {
      return;
    }

    const timelineEvents = room.getLiveTimeline().getEvents();
    const lastEvent = timelineEvents[timelineEvents.length - 1];

    if (
      mEvent.getSender() === client.getUserId() ||
      lastEvent.getId() !== mEvent.getId()
    ) {
      return;
    }

    dispatch({ type: actions.UPDATE_ROOM_NOTIFICATIONS, room, client });
  };
  client.on("Room.timeline", onRoomTimeline);

  const onAccountData = (mEvent, oldMEvent) => {
    // Here we handle changes to the notifications settings - global or for a specific room
  };
  client.on("accountData", onAccountData);

  const onRoomReceipt = (mEvent, room) => {
    dispatch({ type: actions.UPDATE_ROOM_NOTIFICATIONS, room, client });
  };
  client.on("Room.receipt", onRoomReceipt);

  const onRoomMyMembership = (room, membership) => {
    if (membership === "leave") {
      dispatch({ type: actions.DELETE_ROOM_NOTIFICATIONS, room });
    }

    if (membership === "invite") {
      // Maybe do something here?
    }
  };
  client.on("Room.myMembership", onRoomMyMembership);

  return () => {
    client.removeListener("Room.timeline", onRoomTimeline);
    client.removeListener("accountData", onAccountData);
    client.removeListener("Room.receipt", onRoomReceipt);
    client.removeListener("Room.myMembership", onRoomMyMembership);
  };
};

const reducer = (store, action) => {
  switch (action.type) {
    case actions.UPDATE_ROOM_NOTIFICATIONS:
      return {
        ...store,
        [action.room.roomId]: {
          // total: getRoomUnreadMeta({
          //   room: action.room,
          // }).total,
          total: action.room.getUnreadNotificationCount("total"),
        },
      };
    case actions.UPDATE_ROOMS_NOTIFICATIONS:
      return {
        ...store,
        ...transform(
          action.rooms,
          (result, room) => {
            result[room.roomId] = {
              ...store[room.roomId],
              total: room.getUnreadNotificationCount("total"),
              // total: getRoomUnreadMeta({ room }).total,
            };
          },
          {}
        ),
        __init: true,
      };
    case actions.DELETE_ROOM_NOTIFICATIONS:
      return store[action.room.roomId]?.total
        ? {
            ...store,
            [action.room.roomId]: {
              total: 0,
            },
          }
        : store;
    default:
      return store;
  }
};

const initialState = { __init: false };

const useNotificationStore = ({ client, roomStore }) => {
  const [notificationState, dispatch] = React.useReducer(reducer, initialState);

  const deleteRoomNotifications = React.useCallback(
    async ({ room, roomId } = {}) => {
      invariant(
        room || roomId,
        "You must provide either `room` or `roomId` to `deleteRoomNotifications`."
      );

      room = room || client.getRoom(roomId);
      dispatch({ type: actions.DELETE_ROOM_NOTIFICATIONS, room });

      const events = room.getLiveTimeline().getEvents();
      const latestEvent = events.findLast((event) => !event.isSending());
      if (!latestEvent) {
        return;
      }

      await client.sendReadReceipt(latestEvent);
    },
    [client]
  );

  /**
   * Returns the first unread `MatrixEvent` found for a specific room.
   */
  const getRoomFirstUnreadEvent = React.useCallback(
    ({ room, roomId }) => {
      invariant(
        room || roomId,
        "You must provide either `room` or `roomId` to `getRoomFirstUnreadEvent`."
      );
      room = room || client.getRoom(roomId);
      const { firstUnread } = getRoomUnreadMeta({ room });

      return firstUnread;
    },
    [client]
  );

  const updateRoomNotifications = React.useCallback(
    ({ room, roomId } = {}) => {
      invariant(
        room || roomId,
        "You must provide either `room` or `roomId` to `getRoomTotalNotifications`."
      );
      room = room || client.getRoom(roomId);

      dispatch({ type: actions.UPDATE_ROOM_NOTIFICATIONS, room, client });
    },
    [client]
  );

  const getRoomTotalNotifications = React.useCallback(
    ({ room, roomId } = {}) => {
      invariant(
        room || roomId,
        "You must provide either `room` or `roomId` to `getRoomTotalNotifications`."
      );
      room = room || client.getRoom(roomId);

      return notificationState[room.roomId]?.total;
    },
    [client, notificationState]
  );

  const notificationStore = React.useMemo(() => {
    return {
      ...notificationState,
      // Makes sure to validate input to the store methods
      // Add any other public store methods go here...
      getRoomFirstUnreadEvent,
      deleteRoomNotifications,
      updateRoomNotifications,
      getRoomTotalNotifications,
    };
  }, [
    deleteRoomNotifications,
    getRoomFirstUnreadEvent,
    getRoomTotalNotifications,
    notificationState,
    updateRoomNotifications,
  ]);

  React.useEffect(() => {
    if (!client) {
      return;
    }

    const removeEventListeners = setEventListeners(client, dispatch);
    return removeEventListeners;
  }, [client]);

  React.useEffect(() => {
    if (!client || roomStore.__init) {
      return;
    }

    dispatch({
      type: actions.UPDATE_ROOMS_NOTIFICATIONS,
      rooms: [...roomStore.rooms, ...roomStore.directs],
      client,
    });
  }, [client, roomStore]);

  return { notificationStore };
};

export default useNotificationStore;
