import { useState } from "react";
import identity from "lodash/identity";
import { useQuery } from "react-query";
import { useDebounce } from "react-use";

import { encodeStringSpecialCharactersToUnicode } from "../utils/chat-tools";
import {
  checkStringIsEmail,
  includeOrgDisplayNameEnding,
} from "../utils/displayUtils";
import { useMatrix } from "./MatrixContext";

const useSearchUserDirectory = ({ searchTerm, filterFn = identity }) => {
  const { client, userInfo } = useMatrix();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const searchTermIsEmail = checkStringIsEmail(searchTerm);

  useDebounce(
    () => {
      setDebouncedSearchTerm(
        searchTermIsEmail
          ? encodeStringSpecialCharactersToUnicode(searchTerm)
          : includeOrgDisplayNameEnding(searchTerm, userInfo.orgId)
      );
    },
    500,
    [searchTerm]
  );

  let queryResult = useQuery(
    ["searchAccounts", debouncedSearchTerm],
    async () =>
      await client.searchUserDirectory({
        term: debouncedSearchTerm,
        limit: 100,
      }),
    {
      onSuccess: (res) => {
        // console.log({ searchRes: res });
      },
      enabled: !!debouncedSearchTerm,
    }
  );

  const accounts = queryResult.data?.results.filter((user) => {
    //this will ensure that search is done with full email ex. razvan.lutai@airportlabs.com
    //not razvan.lutai@airportlabs.c
    let noFilterByEmail = true;
    if (searchTermIsEmail) {
      const userEmail = user.user_id.split(":")[0].replace("@", "");
      noFilterByEmail = userEmail === debouncedSearchTerm;
    }
    return (
      user.user_id !== client.getUserId() && noFilterByEmail && filterFn(user)
    );
  });

  return {
    ...queryResult,
    debouncedSearchTerm,
    // https://github.com/TanStack/query/issues/3975#:~:text=commented-,9%20days%20ago,-Just%20want%20to
    isDisabledLoading:
      queryResult.isLoading && queryResult.fetchStatus !== "idle",
    accounts,
  };
};

export default useSearchUserDirectory;
