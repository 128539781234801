import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { Box, DrawerMenuItem, Text } from "../../../../../components";
import { matrixRoomPropType } from "../../../../../propTypes";
import { decodeMatrixIdToEmail } from "../../../../../utils/chat-tools";
import { getUserInfoFromDisplayName } from "../../../../../utils/displayUtils";
import { getDMRoomMember } from "../../../../../utils/matrixUtils";
import useParentPropagation from "../common/useParentPropagation";

const propTypes = {
  title: PropTypes.string,
  room: matrixRoomPropType,
  automationHook: PropTypes.string,
};

const DMDetails = ({ title, room, automationHook }) => {
  useParentPropagation({ title });
  const member = getDMRoomMember({ room });
  const memberInfo = getUserInfoFromDisplayName(member.displayName);

  return (
    <>
      <Box mB="m">
        <Text fontSize="s" color="textSecondary">
          Email Address
        </Text>
        <DrawerMenuItem
          automationHook={getHook(automationHook, "email")}
          iconName="MessageSquare"
          text={decodeMatrixIdToEmail(member.userId) || "No email data."}
        />
      </Box>
      <Box>
        <Text fontSize="s" color="textSecondary">
          Job Title
        </Text>
        <DrawerMenuItem
          automationHook={getHook(automationHook, "job")}
          iconName="User"
          text={memberInfo.job || "No job title data."}
          subtext={memberInfo.orgName || "No organisation data."}
        />
      </Box>
    </>
  );
};
DMDetails.propTypes = propTypes;

export default DMDetails;
