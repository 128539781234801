import { useState } from "react";
import set from "lodash/set";
import { useMatrix } from "network/MatrixContext";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  ButtonsContainer,
  ButtonWithLoader,
  Checkbox,
  Heading3,
  Modal,
  Notification,
  RadioButton,
  SecondaryButton,
} from "../../../../../../components";
import { memberRoles } from "../../../../../../constants";
import { getPowerLevelByRole } from "../../../../../../utils/matrixUtils";
import useSetPowerLevel from "../../common/useSetPowerLevel";

const propTypes = {
  roleChangeData: PropTypes.shape({
    member: PropTypes.object,
    room: PropTypes.object,
    myPower: PropTypes.number,
    isMe: PropTypes.bool,
  }),
  setRoleChangeData: PropTypes.func,
  isOpen: PropTypes.bool,
};

const RoleChangeModal = ({ roleChangeData, setRoleChangeData, isOpen }) => {
  const { member, room, myPower, isMe } = roleChangeData;
  const { client } = useMatrix();
  const { control, handleSubmit, formState, watch } = useForm({
    defaultValues: { role: member.peopleRole },
  });
  const [error, setError] = useState("");
  const isAdminRoleSelected = memberRoles.ADMIN === watch("role");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const { setPowerLevelMutationAsync } = useSetPowerLevel();

  async function onSubmit(data) {
    setError("");
    try {
      const powerLevelEvent = room.currentState.getStateEvents(
        "m.room.power_levels"
      )[0];
      await setPowerLevelMutationAsync({
        roomId: room.roomId,
        userId: member.userId,
        powerLevel: getPowerLevelByRole(data.role),
        powerLevelEvent: powerLevelEvent,
      });
      // Since the room is deleted by the admin through the action of kicking out all users,
      // the room is designed to accommodate only one admin at a time, due to the fact that
      // users who share the same power level are restricted from executing actions such as "kick" on one another.
      // To ensure that the room has only one admin a transfer mechanism was implemented.
      if (data.role === memberRoles.ADMIN) {
        // If second setPowerLevelMutation is called with the same powerLevelEvent, an error will occur
        // "You don't have permission to remove ops level equal to your own".
        // In order to fix that we need to clone powerLevelEvent an set the power level of member.userId to 100.
        // TODO: further investigation is needed to see way this solution works and if it is not possible to clone powerLevelEvent with matrix sdk
        const newPowerLevelEvent = set(
          { ...powerLevelEvent },
          ["event", "content", "users", `${member.userId}`],
          100
        );
        Object.setPrototypeOf(newPowerLevelEvent, powerLevelEvent);
        await setPowerLevelMutationAsync({
          roomId: room.roomId,
          userId: client.getUserId(),
          // Downgrade old admin to Moderator role
          powerLevel: getPowerLevelByRole(memberRoles.MODERATOR),
          powerLevelEvent: newPowerLevelEvent,
        });
      }
      setRoleChangeData(null);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setRoleChangeData(null)}
      verticalPosition="center"
      w="280px"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Box pT="xl" pR="m">
          <Heading3>Change role for {isMe ? "You" : member?.name}</Heading3>
          <Controller
            name="role"
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <Box d="flex" gap="s" direction="column">
                {Object.values(memberRoles)
                  .filter((role) => role !== memberRoles.OWNER)
                  .map((role) => {
                    return (
                      <RadioButton
                        value={role}
                        variant="pill"
                        label={role}
                        name="role"
                        key={role}
                        disabled={
                          isMe
                            ? getPowerLevelByRole(role) > myPower
                            : member.powerLevel >= myPower ||
                              getPowerLevelByRole(role) > myPower
                        }
                        checked={value === role}
                        onChange={(e) => {
                          // Disable Save button when user selects the admin role and wait for confirmation
                          setIsSaveButtonDisabled(
                            e.target.value === memberRoles.ADMIN
                          );
                          onChange(e);
                        }}
                        {...field}
                      />
                    );
                  })}
              </Box>
            )}
          />
          {error && (
            <Notification
              status="error"
              title="Error"
              message="Could not change role."
              variant="inline"
              withCloseButton={false}
              withBottomButtons={false}
              mT="l"
            />
          )}
          {!isMe && (isAdminRoleSelected || member.powerLevel >= myPower) && (
            <Notification
              status="warning"
              message={
                isAdminRoleSelected
                  ? "The admin role can only be transferred, you will be downgraded to a room moderator."
                  : "You can't change the role of a member whose power level is equal or higher than yours."
              }
              variant="inline"
              withCloseButton={false}
              withBottomButtons={false}
              mT="l"
            >
              {isAdminRoleSelected && (
                <Checkbox
                  name="Transfer Role"
                  label="Transfer role"
                  value="yes"
                  isHighlighted
                  onChange={(e) => setIsSaveButtonDisabled(!e.target.checked)}
                />
              )}
            </Notification>
          )}
        </Modal.Box>
        <Modal.Footer>
          <ButtonsContainer justifyContent="center">
            <SecondaryButton onClick={() => setRoleChangeData(null)}>
              Cancel
            </SecondaryButton>
            <ButtonWithLoader
              buttonStyle="tertiary"
              type="submit"
              disabled={
                !formState.isDirty ||
                formState.isSubmitting ||
                isSaveButtonDisabled
              }
              showLoader={formState.isSubmitting}
            >
              Save
            </ButtonWithLoader>
          </ButtonsContainer>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

RoleChangeModal.propTypes = propTypes;

export default RoleChangeModal;
