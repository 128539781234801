import React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { Box, FieldWrapper, Input } from "../components";
import { topicCharacterLimit } from "../constants";

const propTypes = {
  canChangeName: PropTypes.bool,
  canChangeTopic: PropTypes.bool,
  control: PropTypes.object,
  automationHook: PropTypes.string,
};

export const fields = {
  NAME: "group-name",
  TOPIC: "group-topic",
};

const GroupFormInputs = ({
  canChangeName = true,
  canChangeTopic = true,
  control,
  automationHook,
}) => (
  <Box d="flex" direction="column" gap="base">
    <Controller
      name={fields.NAME}
      control={control}
      //TODO: maybe add also here maxLength check
      rules={{ required: { value: true, message: "This field is required" } }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <FieldWrapper
          label="Group Name"
          {...(canChangeName && {
            labelProps: {
              showAsterisk: true,
            },
          })}
          labelAbove={true}
          error={error && error.message}
          labelFor="name"
          automationHook={getHook(automationHook, "name")}
        >
          <Input
            id="name"
            hasError={!!error}
            placeholder="What should we call this group?"
            disabled={!canChangeName}
            automationHook={getHook(automationHook, "name-field")}
            forwardRef={ref}
            {...field}
          />
        </FieldWrapper>
      )}
    />
    <Controller
      name={fields.TOPIC}
      control={control}
      rules={{
        //This function will ensure that value of the topic field doesn't exceed limit
        validate: (value) => {
          if (value && value.length > topicCharacterLimit) {
            return `The topic is ${
              value.length - topicCharacterLimit
            } characters longer than allowed`;
          }
          return true;
        },
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <FieldWrapper
          label="Topic (Optional)"
          labelAbove={true}
          error={error && error.message}
          labelFor="topic"
          automationHook={getHook(automationHook, "topic")}
        >
          <Input
            as="textarea"
            id="topic"
            rows="2"
            hasError={!!error}
            placeholder="Write a description for this group so that colleagues know what to expect"
            disabled={!canChangeTopic}
            automationHook={getHook(automationHook, "topic-field")}
            forwardRef={ref}
            {...field}
          />
        </FieldWrapper>
      )}
    />
  </Box>
);

GroupFormInputs.propTypes = propTypes;
export default GroupFormInputs;
