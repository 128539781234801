import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { Avatar, Box, GhostIconButton, HeaderWrapper } from "../../components";
import { useMatrix } from "../../network/MatrixContext";
import { childrenPropType } from "../../propTypes";

const propTypes = {
  children: childrenPropType,
  automationHook: PropTypes.string,
  setShownDrawer: PropTypes.func,
  drawerTypes: PropTypes.object,
};

const HeaderLayout = ({
  children,
  automationHook,
  setShownDrawer,
  drawerTypes,
}) => {
  const { client, userInfo } = useMatrix();

  return (
    <Box d="flex" direction="column" h="100vh">
      <HeaderWrapper>
        <Box d="flex" flex-direction="row" alignItems="center">
          <GhostIconButton
            name="Menu"
            iconSize="l"
            w="24px"
            onClick={() => {
              window.parent.postMessage(
                {
                  action: "openBurgerMenu",
                },
                "*"
              );
            }}
            mR="base"
          />
          <h1>Community Chat</h1>
        </Box>
        <Avatar
          name={userInfo.name || client.getUserId().slice(1)}
          isUser
          size="medium"
          automationHook={getHook(automationHook, "header")}
          onClick={() => setShownDrawer(drawerTypes.INFO)}
        />
      </HeaderWrapper>
      {children}
    </Box>
  );
};

HeaderLayout.propTypes = propTypes;
export default HeaderLayout;
