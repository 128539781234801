import * as React from "react";

import {
  getPowerRole,
  getUsernameOfRoomMember,
  sortMemberByPowerLevel,
  sortMembersByAtoZ,
} from "../utils/matrixUtils";
import { useMatrix } from "./MatrixContext";

const useMembers = ({ room }) => {
  const { client } = useMatrix();
  const [members, setMembers] = React.useState([]);

  React.useEffect(() => {
    if (!room) {
      return;
    }
    let isLoadingMembers = false;
    const updateMembers = (event) => {
      if (isLoadingMembers || (event && event?.getRoomId() !== room.roomId)) {
        return;
      }
      const roomMembers = room
        .getMembersWithMembership("join")
        .map((member) => ({
          userId: member.userId,
          name: getUsernameOfRoomMember(member),
          username: member.userId.slice(1, member.userId.indexOf(":")),
          peopleRole: getPowerRole(member.powerLevel),
          powerLevel: member.powerLevel,
        }))
        .sort(sortMembersByAtoZ)
        .sort(sortMemberByPowerLevel);
      setMembers(roomMembers);
    };
    updateMembers();

    isLoadingMembers = true;
    room.loadMembersIfNeeded().then(() => {
      isLoadingMembers = false;
      updateMembers();
    });

    client.on("RoomMember.membership", updateMembers);
    client.on("RoomMember.powerLevel", updateMembers);

    return () => {
      client.removeListener("RoomMember.membership", updateMembers);
      client.removeListener("RoomMember.powerLevel", updateMembers);
    };
  }, [client, room]);

  return { members };
};

export default useMembers;
