import identity from "lodash/identity";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Box, GhostIconButton, HeaderWrapper, Text } from "../../components";
import { useMatrix } from "../../network/MatrixContext";
import { childrenPropType, matrixRoomPropType } from "../../propTypes";
import { getRoomAlias, getRoomTopic } from "../../utils/matrixUtils";
import BackButton from "./BackButton";

const propTypes = {
  children: childrenPropType,
  room: matrixRoomPropType,
  onBackClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  canNavigate: PropTypes.bool,
  canDownload: PropTypes.bool,
  automationHook: PropTypes.string,
};

const HeaderLayoutMinimal = ({
  children,
  onBackClick = identity,
  onDownloadClick = identity,
  canNavigate = true,
  canDownload = false,
  automationHook,
}) => {
  const { room } = useMatrix({ roomId: useParams().roomId });
  const topic = getRoomTopic(room);

  return (
    <Box d="flex" direction="column">
      <HeaderWrapper>
        <Box
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
        >
          <Box d="flex" direction="row" alignItems="center">
            <BackButton
              roomId={room.roomId}
              onClick={onBackClick}
              canNavigate={canNavigate}
              automationHook={automationHook}
            />
            <Box d="flex" mL="xs" direction="column">
              <Text fontSize="m" fontWeight="bold">
                {getRoomAlias(room)}
              </Text>
              {topic && (
                <Text mT="none" fontSize="base" color="textSecondary">
                  {topic}
                </Text>
              )}
            </Box>
          </Box>
          {canDownload && (
            <GhostIconButton
              name="download"
              onClick={onDownloadClick}
              iconSize="l"
              tooltipText="Download"
            />
          )}
        </Box>
      </HeaderWrapper>
      {children}
    </Box>
  );
};
HeaderLayoutMinimal.propTypes = propTypes;

export default HeaderLayoutMinimal;
