import { hook } from "@airportlabs/automation-hooks";
import { mixins } from "@airportlabs/smalt";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { Box, Icon, Loader, Text } from ".";

const propTypes = {
  automationHook: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  disabled: PropTypes.bool,
  // Wether to show a number in the right side of the menu item
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // Wether to show a chevron on the right side
  withChevron: PropTypes.bool,
  // Wether to show a pointer on hover
  withPointer: PropTypes.bool,
  children: PropTypes.node,
  iconSize: PropTypes.string,
  fontSize: PropTypes.string,
  loading: PropTypes.bool,
};

//TODO check if this can bee replaced with GhostButton
const MenuItem = styled.div`
  ${mixins.colorMixin("text")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spaces.xs + " 0"};
  text-decoration: none;
  line-height: 1.6;
  ${(props) =>
    props.$disabled &&
    css`
      color: ${props.theme.colors.textSecondary};
    `};
  ${(props) =>
    props.$withPointer &&
    !props.$disabled &&
    css`
      cursor: pointer;
    `}
`;

const DrawerMenuItem = ({
  automationHook,
  iconName,
  text,
  subtext,
  children,
  count,
  withChevron,
  withPointer,
  iconSize = "m",
  fontSize = "base",
  disabled = false,
  loading = false,
  ...rest
}) => {
  return (
    <MenuItem
      {...hook(automationHook)}
      $withPointer={withPointer}
      $disabled={disabled}
      {...rest}
    >
      <Box d="flex" alignItems="center">
        <Icon name={iconName} size={iconSize} />
        <Box mL="base">
          <Text fontSize={fontSize}>{text}</Text>
          {subtext && (
            <Text color="textSecondary" fontSize="s">
              {subtext}
            </Text>
          )}
        </Box>
      </Box>
      <Box d="flex" alignItems="center">
        {loading ? (
          <Loader size="20px" />
        ) : (
          count && (
            <Text fontSize="base" mR={withChevron || children ? "s" : "none"}>
              {count}
            </Text>
          )
        )}
        {withChevron && <Icon name="ChevronRight" size="m" />}
        {children}
      </Box>
    </MenuItem>
  );
};
DrawerMenuItem.propTypes = propTypes;

export default DrawerMenuItem;
