import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { getEnv } from "@airportlabs/js-tools";
import {
  Box,
  Heading1,
  Loader,
  LoaderContainer,
  PrimaryButton,
} from "@airportlabs/smalt";
import endsWith from "lodash/endsWith";
import { createClient } from "matrix-js-sdk";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { localStorageValues, routes } from "../constants";
import { useMatrix } from "../network/MatrixContext";
import useCreateRoom from "../network/useCreateRoom";
import { getOrgRoomFullName, getOrgRoomNameEnding } from "../utils/chat-tools";
import { getUserInfoFromDisplayName } from "../utils/displayUtils";
import setPushers from "./loginPage/setPushers";

const client = createClient({
  baseUrl: getEnv("REACT_APP_SERVER_URL"),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserInfo, roomStore } = useMatrix();
  // handle adding a new room
  const isCreatingOrgRoom = useRef(false);
  const { createRoomAsync, ...createRoomMutation } = useCreateRoom({
    navigateToNewRoom: false,
  });
  // begin with the assumption that we do not know if we need to display a form
  const isFormLoginRef = useRef("unclear");
  const [, triggerRerender] = useReducer((s) => !s);
  // helpers to prevent multiple processing of jwt response
  const isProcessingResponseChatMatrixLoginJwt = useRef(false);
  const isWaitingForResponseChatMatrixLoginJwt = useRef(false);
  const [retryRequestJWT, setRetryRequestJWT] = useState(0);
  const [
    responseChatMatrixLoginJwtTokenError,
    setResponseChatMatrixLoginJwtTokenError,
  ] = useState();
  const redirectToRoomId = useRef();

  const {
    register,
    handleSubmit: handleSubmitOriginal,
    // watch,
    formState: { errors },
  } = useForm();

  const handleSubmit = (onSubmit, isLoading) => {
    if (isLoading) {
      return;
    }
    return handleSubmitOriginal(onSubmit);
  };

  // TODO: The user pass form could be separated in its own component
  const loginUserPassMutation = useMutation(
    ({ username, password }) => {
      return client.login("m.login.password", {
        identifier: { type: "m.id.user", user: username },
        password,
        device_id: getEnv("REACT_APP_DEVICE_ID"),
      });
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(
          localStorageValues.ACCESS_TOKEN,
          data.access_token
        );
        localStorage.setItem(localStorageValues.USER_ID, data.user_id);
        setIsAuthenticated(true);
        navigate(routes.ROOMS, { replace: true });
      },
    }
  );
  const loginUserPass = (data) =>
    loginUserPassMutation.mutate({
      username: data.username,
      password: data.password,
    });

  // Method used by both the JWT Form and the message triggered login
  const loginJwtMutation = useMutation(
    (data) => {
      const jwtToken = data.jwt;
      console.log("CALLING client.login w/ token", jwtToken);

      return client.login("org.matrix.login.jwt", {
        token: jwtToken,
        device_id: getEnv("REACT_APP_DEVICE_ID"),
        /** A display name to assign to the newly-created device. Ignored if
         * device_id corresponds to a known device. */
        initial_device_display_name: getEnv("REACT_APP_DEVICE_ID"),
      });
    },
    {
      async onSuccess(data) {
        console.log("********* jwt login mutation on success ************");
        // set data used later by context
        localStorage.setItem(
          localStorageValues.ACCESS_TOKEN,
          data.access_token
        );
        localStorage.setItem(localStorageValues.USER_ID, data.user_id);

        const { userId } = client.credentials;
        const uInfo = getUserInfoFromDisplayName(); // uses displayname from local storage
        console.log(">>> getUserInfoFromDisplayName: ", uInfo);

        /** Because we don't currently have a way of storing user meta, we use
         * matrix user displayname to store all extra data. The app integrating
         * with chat might allow user to update their info. Check if matrix
         * user's displayName is the same as the one we have in local storage
         * for user info and update the user displayname if necessary
         */
        const profileInfo = await client.getProfileInfo(userId, "displayname");
        console.log(">>> the profileInfo we have on client", profileInfo);
        const localUserInfo = localStorage.getItem(
          localStorageValues.USER_INFO
        );
        if (
          localUserInfo &&
          localUserInfo !== "undefined" &&
          profileInfo.displayname !== localUserInfo
        ) {
          console.log("---- we need to update client display name");
          client.setDisplayName(localUserInfo);
        }

        /** Set the pushers for notifications */
        await setPushers(client, userId);

        /** Add user to the organisation room: This is needed because users only
         *  appear in chat Search when a user is in public room or in a private
         *  room that you are also in.
         *  In order to allow CA users to find people from the same organisation
         *  after they login for the first time, we add all * users form an
         *  organisation to the same room, which is then used to filter any
         *  results and limit the shown users to just the ones for your
         *  organisation.
         */
        if (uInfo.orgId) {
          const rooms = await client.publicRooms();
          const orgRoom = rooms.chunk.find((room) =>
            endsWith(room.name, getOrgRoomNameEnding(uInfo.orgId))
          );
          const clientRooms = await client.getJoinedRooms();
          let orgRoomId = orgRoom?.room_id;

          console.log({
            rooms,
            orgRoom,
            clientRooms,
            orgRoomId: orgRoomId,
          });

          if (
            !orgRoomId &&
            !createRoomMutation.isLoading &&
            !isCreatingOrgRoom.current
          ) {
            // orgRoom does not exists and we're not in the middle of creating
            // one. we can create it
            console.log(
              `>>> create ORG room createRoomMutation.isLoading:  ${createRoomMutation.isLoading}`
            );
            isCreatingOrgRoom.current = true;
            try {
              const result = await createRoomAsync({
                name: getOrgRoomFullName(uInfo.orgName, uInfo.orgId),
                topic: `Welcome to ${uInfo.orgName} chat group!`,
                joinRoom: "public",
                client,
              });
              console.log(">>> done creating it, got result", result);
              orgRoomId = result.room_id;
            } catch (e) {
              console.error(e);
            }
          }

          // at this point we have the orgRoomId if was created successfully or
          // if it already existed
          // we add the user in that room if they aren't already in it
          if (
            orgRoomId &&
            !clientRooms?.joined_rooms?.some((roomId) => roomId === orgRoomId)
          ) {
            console.log(" add user to room ++++++++++++++++++++++");
            await client.joinRoom(orgRoomId);
            await roomStore.addRoom({ roomId: orgRoomId });
            console.log(">>> new roomStore ", roomStore);
          }

          setUserInfo((s) => ({
            ...s,
            ...uInfo,
            ...(orgRoomId ? { orgRoomId } : {}),
          }));

          // finally
          setIsAuthenticated(true);
          const route = redirectToRoomId.current
            ? `${routes.ROOMS}/${redirectToRoomId.current}`
            : routes.ROOMS;
          console.log("------------------ navigate to route *** ", route);
          navigate(route, { replace: true });
        }
      },
      onError(error, vars, context) {
        console.error(
          "Error logging in with JWT mutation",
          error,
          vars,
          context
        );

        setResponseChatMatrixLoginJwtTokenError({
          code: error.errcode,
          reason: error.message,
        });

        // enable listening for new messages
        isProcessingResponseChatMatrixLoginJwt.current = false;
        isWaitingForResponseChatMatrixLoginJwt.current = false;

        window.parent.postMessage({ action: "loginError" }, "*");
      },
    }
  );
  const login = loginJwtMutation.mutate;
  const loginJwt = useCallback((data) => login({ jwt: data.jwt }), [login]);

  // Attempt an automatic login with JWT by requesting a token from the app that
  // the chat is integrated in (a parent app).
  // The parent app should implement a listener for this event and send back a
  // response message with action set to `responseChatMatrixLoginJwtToken`.
  // CommunityApp was integrated with chat using this method.
  // See community-app/meteor/imports/features/Chat/ChatMatrixWrapper.js
  useEffect(() => {
    if (
      !isWaitingForResponseChatMatrixLoginJwt.current &&
      !isCreatingOrgRoom.current
    ) {
      window.parent.postMessage(
        {
          action: "requestChatMatrixLoginJwtToken",
        },
        "*"
      );
      isWaitingForResponseChatMatrixLoginJwt.current = true;
    }

    console.log("ui: SEND requestChatMatrixLoginJwtToken ***");

    // we don't wanna be waiting forever, so switch to showing the login forms
    // if parent app does not respond
    // TODO: instead of doing this maybe we can send a message that sets the
    // preferred login method of an app
    const loginFormTimer = setTimeout(() => {
      if (isFormLoginRef.current === "unclear") {
        isFormLoginRef.current = "yes";
        triggerRerender();
      }
    }, 15000);

    return () => clearTimeout(loginFormTimer);
  }, [retryRequestJWT]);

  // Interpret messages from the parent app
  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event?.data?.action === "responseChatMatrixLoginJwtToken" &&
        isProcessingResponseChatMatrixLoginJwt.current === false
      ) {
        // parent app wants to send a JWT, do not show forms
        isFormLoginRef.current = "no";
        // prevent the processing of subsequent messages for the time being
        isProcessingResponseChatMatrixLoginJwt.current = true;
        console.log(
          "ui: Interpreting message responseChatMatrixLoginJwtToken, got event.data",
          event.data
        );
        if (event.data.error) {
          setResponseChatMatrixLoginJwtTokenError(event.data.error);
          return;
        }

        localStorage.setItem(localStorageValues.USER_INFO, event.data.userInfo);
        /** Chat expects the parent app to provide an appId
         * The appId is used for creating notification pushers */
        localStorage.setItem(localStorageValues.APP_ID, event.data.appId);
        localStorage.setItem(
          localStorageValues.IS_IOS,
          event.data.platform?.isIos
        );
        localStorage.setItem(
          localStorageValues.IS_APP,
          event.data.platform?.isIos || event.data.platform?.isAndroid
        );
        // if we have a redirect room id, save it on our ref
        redirectToRoomId.current = event.data.redirectToRoomId;

        // finally proceed to login with the received token
        loginJwt({ jwt: event.data.jwtToken });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [loginJwt]);

  const isLoginHappening =
    loginUserPassMutation.isLoading || loginUserPassMutation.isLoading;

  const formUserPass = (
    <form onSubmit={handleSubmit(loginUserPass, isLoginHappening)}>
      <input
        type="text"
        placeholder="username"
        {...register("username", { required: true })}
        style={errors.username ? { border: "1px solid red" } : {}}
      />
      <br />
      <input
        type="password"
        placeholder="password"
        style={errors.password ? { border: "1px solid red" } : {}}
        {...register("password", { required: true })}
      />
      <br />
      {loginUserPassMutation.isError && (
        <div>{loginUserPassMutation.error.message}</div>
      )}
      <button disabled={isLoginHappening} type="submit">
        Login
      </button>
    </form>
  );

  const formJwt = (
    <form onSubmit={handleSubmit(loginJwt, isLoginHappening)}>
      {loginJwtMutation.isError && <div>{loginJwtMutation.error.message}</div>}
      {responseChatMatrixLoginJwtTokenError && (
        <div>{responseChatMatrixLoginJwtTokenError.reason}</div>
      )}
      <button disabled={isLoginHappening} type="submit">
        Login with JWT
      </button>
    </form>
  );

  return isFormLoginRef.current === "yes" ? (
    <>
      {formUserPass}
      {formJwt}
    </>
  ) : !responseChatMatrixLoginJwtTokenError?.error ? (
    <LoaderContainer>
      <Loader />
      <div>Loading..</div>
    </LoaderContainer>
  ) : (
    <Box d="flex" center direction="column" gap="s" pV="l">
      <Heading1>There was an issue logging you in.</Heading1>
      <PrimaryButton
        onClick={() => {
          setRetryRequestJWT((s) => (s + 1) % 5);
          setResponseChatMatrixLoginJwtTokenError();
        }}
      >
        Try Again
      </PrimaryButton>
    </Box>
  );
};

export default LoginPage;
