import React from "react";
import { ErrorBoundary, ThemeProvider } from "@airportlabs/smalt";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import ErrorFallbackComponent from "./components/ErrorFallbackComponent";
import { MatrixProvider } from "./network/MatrixContext";
import Routes from "./routes";

// import { ReactQueryDevtools } from "react-query-devtools";

const queryClient = new QueryClient({
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
});

function App() {
  return (
    <ThemeProvider baseThemeName="chat">
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <MatrixProvider>
            <BrowserRouter>
              <Routes />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </BrowserRouter>
          </MatrixProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
