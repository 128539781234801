import styled from "styled-components";
import { Box } from "@airportlabs/smalt";

export const HeaderContainer = styled(Box)`
  position: fixed;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column-reverse;

  color: white;
`;
