import React, { useState } from "react";
import { getHook, hook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { Drawer, ListSearch, Section, Text } from "../../components";
import DMCandidatesList from "./DMCandidatesList";

const propTypes = {
  automationHook: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const CreateDMDrawer = ({
  automationHook: baseAutomationHook,
  isOpen,
  onClose,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const onCloseDrawer = () => {
    onClose();
    setSearchTerm("");
  };

  const automationHook = getHook(baseAutomationHook, "create-dm");

  return (
    <Drawer.Wrapper isOpen={isOpen} onRequestClose={onCloseDrawer}>
      <Drawer.Header
        onBack={onCloseDrawer}
        backIcon={"ChevronDown"}
        automationHook={automationHook}
      >
        <Text fontSize="m">Start Direct Message</Text>
        {/** Needed due to how alignment in "Drawer.Header" is done. */}
        <div></div>
      </Drawer.Header>
      <Section backgroundColor="backgroundPrimary" borderBottom pV="base">
        <ListSearch
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          automationHook={getHook(automationHook, "accounts-search")}
        />
      </Section>
      <Drawer.Content>
        <Section>
          {searchTerm ? (
            <DMCandidatesList
              automationHook={automationHook}
              searchTerm={searchTerm}
              withHeading={false}
            />
          ) : (
            <Text
              color="textSecondary"
              mT="s"
              {...hook(automationHook, "no-active-search")}
            >
              You have to search for accounts you want to start a conversation
              with.
            </Text>
          )}
        </Section>
      </Drawer.Content>
    </Drawer.Wrapper>
  );
};

CreateDMDrawer.propTypes = propTypes;

export default CreateDMDrawer;
