import React, { useCallback, useEffect, useState } from "react";
import { getHook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  Box,
  GhostIconButton,
  Heading1,
  ListSearch,
  Modal,
  Popover,
  Section,
} from "../components";
import CreateDMDrawer from "./roomListPage/CreateDMDrawer";
import CreateGroupDrawer from "./roomListPage/CreateGroupDrawer";
import DMCandidatesList from "./roomListPage/DMCandidatesList";
import HeaderLayout from "./roomListPage/HeaderLayout";
import { drawerTypes } from "./roomListPage/helpers";
import NewChatList from "./roomListPage/NewChatList";
import RoomLists from "./roomListPage/RoomLists";
import UserInfoDrawer from "./roomListPage/UserInfoDrawer";

const LeaveModalHeading = styled(Heading1)`
  word-break: break-all;
`;

const propTypes = {
  automationHook: PropTypes.string,
};

const RoomListPage = ({ automationHook = "room-list-page" }) => {
  const [searchTerm, setSearchTerm] = useState();
  const [shownDrawer, setShownDrawer] = useState(drawerTypes.NONE);

  // TODO: an alternative implementation would be to save an array of rooms
  // that the user has left and for which the associated "leave" message was not read,
  // and show a notification for those + mark those messages as read when notification is clicked
  const { state } = useLocation();
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(
    state?.leaveNotificationMessage ? true : false
  );
  const closeModal = useCallback(() => {
    setIsLeaveModalOpen(false);
  }, []);

  // Navigate back to CA from room list page when
  // android phone back button is pressed (event?.data?.action === "backButtonEvent")
  const handleMessage = (event) => {
    if (event?.data?.action !== "backButtonEvent") {
      return;
    }
    if (shownDrawer !== drawerTypes.NONE) {
      setShownDrawer(drawerTypes.NONE);
      return;
    }
    window.parent.postMessage(
      {
        action: "navigateToHomePage",
      },
      "*"
    );
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  });

  return (
    <HeaderLayout
      automationHook={automationHook}
      setShownDrawer={setShownDrawer}
      drawerTypes={drawerTypes}
    >
      <Section overflowY="auto">
        <Box d="flex" flex-direction="row" mT="s" mB="base">
          <ListSearch
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            automationHook={getHook(automationHook, "rooms-search")}
          />
          <Popover
            align="end"
            w="310px"
            triggerW="auto"
            automationHook={getHook(automationHook, "new-chat")}
            renderTrigger={({ togglePopover, isOpen }) => (
              <GhostIconButton
                name="PlusSquare"
                iconSize="xl"
                mL="s"
                onClick={togglePopover}
              />
            )}
          >
            {({ closePopover }) => (
              <NewChatList
                isCompact
                setShownDrawer={setShownDrawer}
                onClick={closePopover}
                automationHook={automationHook}
              />
            )}
          </Popover>
        </Box>
        <RoomLists {...{ searchTerm, setShownDrawer, automationHook }} />
        {searchTerm && (
          <DMCandidatesList
            automationHook={automationHook}
            searchTerm={searchTerm}
          />
        )}
      </Section>
      <CreateGroupDrawer
        automationHook={automationHook}
        isOpen={shownDrawer === drawerTypes.GROUP}
        onClose={() => setShownDrawer(drawerTypes.NONE)}
      />
      <CreateDMDrawer
        automationHook={automationHook}
        isOpen={shownDrawer === drawerTypes.DM}
        onClose={() => setShownDrawer(drawerTypes.NONE)}
      />
      <UserInfoDrawer
        automationHook={automationHook}
        isOpen={shownDrawer === drawerTypes.INFO}
        onClose={() => setShownDrawer(drawerTypes.NONE)}
      />
      <Modal
        isOpen={isLeaveModalOpen}
        onRequestClose={closeModal}
        verticalPosition="center"
        w="max-content"
        automationHook={getHook(automationHook, `leave-modal`)}
      >
        <Modal.Box>
          <LeaveModalHeading>
            {state?.leaveNotificationMessage}
          </LeaveModalHeading>
        </Modal.Box>
      </Modal>
    </HeaderLayout>
  );
};

RoomListPage.propTypes = propTypes;
export default RoomListPage;
