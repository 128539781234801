import styled from "styled-components";
import { mixins } from "@airportlabs/smalt";

import { Box } from "./";
import { childrenPropType } from "../propTypes";

const defaultProps = {
  withMargin: true,
};

const Badge = styled.div`
  ${mixins.colorMixin("textSecondary")};
  ${mixins.backgroundMixin("backgroundPrimary")};
  flex: 0 0 auto;
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  text-transform: uppercase;
  padding: 0 ${(props) => props.theme.spaces.xs};
  border-radius: ${(props) => props.theme.borderRadius.m};
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
`;
Badge.displayName = "ChatBadge.Badge";

const ChatBadge = ({ children, ...rest }) => (
  <Box
    d="flex"
    direction="column"
    justifyContent="center"
    w="100%"
    position="relative"
    mV="s"
    {...rest}
  >
    <Badge>{children}</Badge>
  </Box>
);
ChatBadge.propTypes = {
  children: childrenPropType,
};
ChatBadge.defaultProps = defaultProps;
ChatBadge.displayName = "ChatBadge";

export default ChatBadge;
