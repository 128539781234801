import { hook } from "@airportlabs/automation-hooks";
import PropTypes from "prop-types";

import { childrenPropType } from "../propTypes";
import { Avatar, Box, LoadingSkeleton, Text } from "./";

const propTypes = {
  // What will be prominently displayed near the avatar
  name: PropTypes.string.isRequired,
  // What will be rendered under the name
  subtext: PropTypes.string,
  // Whether we should show an avatar for a user or not
  isUser: PropTypes.bool,
  // Whether name should be bold or not
  shouldHighlightName: PropTypes.bool,
  // What is rendered on the right side of the card in flex column container
  children: childrenPropType,
  onClick: PropTypes.func,
  automationHook: PropTypes.string,
};

const ListCardSkeleton = ({ children }) => {
  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      <Box d="flex" direction="row" justifyContent="flex-start">
        <LoadingSkeleton.Rectangle size="medium" />
        <Box
          d="flex"
          direction="column"
          mL="base"
          justifyContent="space-between"
        >
          <LoadingSkeleton.Line mB="s" w="100px" />
          <LoadingSkeleton.Line w="150px" />
        </Box>
      </Box>
      {!!children && (
        <Box
          d="flex"
          direction="column"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
ListCardSkeleton.propTypes = {
  children: childrenPropType,
};

const ListCard = ({
  onClick,
  isUser,
  name,
  subtext,
  shouldHighlightName,
  children,
  automationHook,
}) => {
  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      gap="m"
      {...hook(automationHook, "card")}
    >
      <Box
        d="flex"
        direction="row"
        justifyContent="flex-start"
        w="100%"
        onClick={onClick}
        overflow="hidden"
      >
        <Avatar
          isUser={isUser}
          name={name}
          size="medium"
          automationHook={automationHook}
        />
        <Box
          d="flex"
          direction="column"
          mL="base"
          justifyContent="space-between"
          minW="0"
        >
          <Text
            fontSize="h2"
            fontWeight={shouldHighlightName ? "bold" : "normal"}
            {...hook(automationHook, "name")}
            truncate
          >
            {name}
          </Text>
          <Text
            fontSize="base"
            color="textSecondary"
            truncate
            {...hook(automationHook, "subtext")}
          >
            {subtext}
          </Text>
        </Box>
      </Box>
      {!!children && (
        <Box
          d="flex"
          direction="column"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
ListCard.propTypes = propTypes;
ListCard.Skeleton = ListCardSkeleton;

export default ListCard;
