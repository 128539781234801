import React from "react";
import { getHook } from "@airportlabs/automation-hooks";
import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";

import { childrenPropType } from "../propTypes";
import { Box, GhostIconButton, Section } from "./";
import { DrawerStyled } from "./drawer/DrawerStyled";

const Content = ({ children, ...rest }) => (
  <Box backgroundColor="backgroundPrimary" h="100%" overflow="auto" {...rest}>
    {children}
  </Box>
);
Content.propTypes = { children: childrenPropType };

const Header = ({
  children,
  automationHook,
  backIcon = "ChevronDown",
  onBack,
}) => (
  <Section
    d="flex"
    alignItems="center"
    justifyContent="center"
    pT="m"
    borderBottom
    backgroundColor="backgroundPrimary"
  >
    <Box d="flex" alignItems="center" justifyContent="space-between" w="100%">
      <GhostIconButton
        iconSize="l"
        name={backIcon}
        color="inherit"
        w="fieldsHeight"
        onClick={onBack}
        automationHook={getHook(automationHook, "back")}
      />
      {children}
    </Box>
  </Section>
);
Header.propTypes = {
  children: childrenPropType,
  automationHook: PropTypes.string,
  onBack: PropTypes.func,
  backIcon: PropTypes.string,
};

export const defaultWrapperProps = {
  shouldCloseOnOverlayClick: true,
  withCloseButton: false,
  verticalPosition: "top",
  offset: "60px",
  width: "100%",
  height: "90%",
};

const Wrapper = ({
  children,
  isOpen,
  onRequestClose,
  height,
  automationHook,
  ...rest
}) => (
  <DrawerStyled
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    shouldCloseOnOverlayClick
    ariaHideApp={false}
    height={height}
    variant="alignBottom"
    automationHook={automationHook}
    {...rest}
  >
    {isFunction(children) ? children({ closeModal: onRequestClose }) : children}
  </DrawerStyled>
);
Wrapper.defaultProps = defaultWrapperProps;
Wrapper.propTypes = {
  children: childrenPropType,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  height: PropTypes.string,
  automationHook: PropTypes.string,
};

const Drawer = {
  Wrapper,
  Header,
  Content,
};

export default Drawer;
